// @flow
import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
  FiArchive, FiCalendar, FiSettings, FiZap,
} from 'react-icons/fi';
import { AiOutlineLogin } from 'react-icons/ai';
import { KillTeam } from '../../assets/icons';
import { NavStyles, NavUl, NavLi } from './NavStyles';
import { ROUTES } from '../../constants';
import H from '../../styles/Headings';
import SignOutButton from '../Account/SignOut';
import HamburgerMenu from './HamburgerMenu';

export const NavigationNonAuth = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavStyles>
      <div className="hidden-nav">
        <Link exact to={ROUTES.LANDING}>
          <KillTeam className="logo-icon" />
        </Link>
      </div>
      <nav>
        <HamburgerMenu className="hamburger" desktopHidden isOpen={isOpen} setIsOpen={setIsOpen}>
          <ul>
            <li>
              <Link to={ROUTES.SIGN_IN} onClick={() => setIsOpen(false)}>
                <H as="h6">Login</H>
              </Link>
            </li>
          </ul>
        </HamburgerMenu>
        <NavUl>
          <NavLi mobileHidden>
            <Link exact to={ROUTES.LANDING}>
              <KillTeam className="logo-icon" />
            </Link>
          </NavLi>
          <NavLi end="true">
            <Link to={ROUTES.SIGN_IN}>
              <AiOutlineLogin className="menu-icon" />
              <H as="h6">Login</H>
            </Link>
          </NavLi>
        </NavUl>
      </nav>
    </NavStyles>
  );
};

export const NavigationAuth = ({ isAdmin }: { isAdmin?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavStyles>
      <div className="hidden-nav">
        <Link exact to={ROUTES.LANDING}>
          <KillTeam className="logo-icon" />
        </Link>
      </div>
      <nav>
        <HamburgerMenu className="hamburger" desktopHidden isOpen={isOpen} setIsOpen={setIsOpen}>
          <ul>
            <li>
              <Link to={ROUTES.ENCYCLOPEDIA} onClick={() => setIsOpen(false)}>
                <H as="h6">Encyclopedia</H>
              </Link>
            </li>
            <li>
              <Link to={ROUTES.MY_TEAMS} onClick={() => setIsOpen(false)}>
                <H as="h6">My Teams</H>
              </Link>
            </li>
            <li>
              <Link to={ROUTES.ACCOUNT} onClick={() => setIsOpen(false)}>
                <H as="h6">Account</H>
              </Link>
            </li>
            {isAdmin && (
              <li>
                <Link to={ROUTES.ADMIN} onClick={() => setIsOpen(false)}>
                  <H as="h6">*Admin*</H>
                </Link>
              </li>
            )}
            <NavLi end="true" onClick={() => setIsOpen(false)}>
              <SignOutButton />
            </NavLi>
          </ul>
        </HamburgerMenu>
        <NavUl>
          <NavLi mobileHidden>
            <Link exact to={ROUTES.LANDING}>
              <KillTeam className="logo-icon" />
            </Link>
          </NavLi>
          <NavLi>
            <Link to={ROUTES.ENCYCLOPEDIA}>
              <FiArchive className="menu-icon" />
              <H as="h6">Encyclopedia</H>
            </Link>
          </NavLi>
          <NavLi>
            <Link to={ROUTES.MY_TEAMS}>
              <FiCalendar className="menu-icon" />
              <H as="h6">My Teams</H>
            </Link>
          </NavLi>
          <NavLi>
            <Link to={ROUTES.ACCOUNT}>
              <FiSettings className="menu-icon" />
              <H as="h6">Account</H>
            </Link>
          </NavLi>
          {isAdmin && (
            <NavLi>
              <Link to={ROUTES.ADMIN}>
                <FiZap className="menu-icon" />
                <H as="h6">*Admin*</H>
              </Link>
            </NavLi>
          )}
          <NavLi end="true">
            <SignOutButton />
          </NavLi>
        </NavUl>
      </nav>
    </NavStyles>
  );
};

NavigationAuth.defaultProps = {
  isAdmin: false,
};
