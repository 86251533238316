// @flow
import React from 'react';
import H from '../../../styles/Headings';
import SpecialistForm from './SpecialistForm';
import UnitAbilities from './UnitAbilities';
import WargearOptions from './WargearOptions';
import UnitNameField from './UnitNameField';
import Wargear from './Wargear';
import UnitStats from './UnitStats';
import EditUnitStyles from './EditUnitStyles';
import type { UnitType, SpecialistListType, WargearListType } from '../../../types';
import { getSpecialist, getWargear } from './EditUnit.utils';

const EditUnit = ({
  unit, onUpdateUnit, specialists, wargear,
}: {
  unit: UnitType,
  specialists: SpecialistListType,
  wargear: WargearListType,
  onUpdateUnit: Function,
}) => {
  const updateUnit = (e, context) => {
    switch (context) {
    case 'name':
      if (e.target.value !== unit.name) onUpdateUnit({ name: e.target.value });
      break;
    case 'specialists': {
      const selectedSpecialist = e.target[e.target.selectedIndex].value;
      if (selectedSpecialist) {
        const specialistName = e.target[e.target.selectedIndex].value;
        const newSpecialist = getSpecialist(specialistName, specialists);
        const specialist = newSpecialist && newSpecialist;

        if (specialist) onUpdateUnit({ specialist });
      } else {
        onUpdateUnit({ specialist: null });
      }
      break;
    }
    case 'primaryWeapons': {
      const wargearName = e.target.value;
      const newWargear = getWargear(wargearName, wargear);
      const activeWeapon = newWargear && newWargear;

      onUpdateUnit({ activeWeapon });
      break;
    }
    case 'options': {
      const activeOptions = unit.activeOptions || [];
      const [event, selectedOption] = e;
      const checkedOption = getWargear(selectedOption, wargear);
      let filteredOptions = null;

      if (event.target.checked) {
        filteredOptions = [...activeOptions, checkedOption];
      } else {
        filteredOptions = activeOptions.filter((option) => option.name !== selectedOption);
      }

      onUpdateUnit({ activeOptions: filteredOptions });
      break; }
    default:
    }
  };
  console.log(unit);
  return (
    <EditUnitStyles>
      <H as="h3" pb>Edit Unit</H>
      <UnitNameField label="Name" name={unit.name} updateUnit={updateUnit} />
      <UnitStats unit={unit} />
      {unit.specialists && (
        <SpecialistForm
          unit={unit}
          updateUnit={updateUnit}
        />
      )}
      {unit.primaryWeapons && (
        <Wargear
          wargear={wargear}
          activeWeapon={unit.activeWeapon}
          primaryWeapons={unit.primaryWeapons}
          updateUnit={updateUnit}
        />
      )}
      {unit.activeWeapon && <p>{unit.activeWeapon.name}</p>}
      {/* TODO: If no primary, display header */}
      {unit.secondaryWeapons && <p>{unit.secondaryWeapons.join(', ')}</p>}
      {unit.options
        && (
          <WargearOptions
            wargear={wargear}
            activeOptions={unit.activeOptions}
            options={unit.options}
            updateUnit={updateUnit}
          />
        )}
      {unit.abilities && <UnitAbilities abilities={unit.abilities} />}
    </EditUnitStyles>
  );
};

export default EditUnit;
