// @flow
import React from 'react';
import type { WargearType } from '../../../types';
import WargearStyles from './SimpleProfileStyles';

export const ProfileRow = ({ item }: { item: WargearType }) => {
  let itemRange = '';
  if (item.range && typeof item.range === 'number') {
    itemRange = `${item.range}"`;
  } else if (item.range && typeof item.range === 'string') {
    itemRange = item.range;
  }
  return (
    <tr>
      <td className="left">{item.name}</td>
      <td className="small">{itemRange}</td>
      <td className="left limited">{item.type}</td>
      <td className="small">{item.s}</td>
      <td className="small">{item.ap}</td>
      <td className="small">{item.d}</td>
      {item.abilities ? <td className="left italic">{item.abilities}</td> : <td>-</td>}
    </tr>
  );
};

export const DualProfileRow = ({ item }: { item: WargearType }) => (
  <tbody>
    <tr>
      <td className="left">
        {item.name}
      </td>
      <td colSpan="6" className="left italic">
        {item.abilities && item.abilities}
      </td>
    </tr>
    {Object.keys(item.profile).map((key) => (
      <ProfileRow
        key={key}
        item={{ ...item.profile[key], name: key }}
      />
))}
  </tbody>
);

const Wargear = ({ profile }: { profile: WargearType }) => (
  <WargearStyles>
    <thead>
      <tr>
        <th>Weapon</th>
        <th>Range</th>
        <th>Type</th>
        <th>S</th>
        <th>AP</th>
        <th>D</th>
        <th>Abilities</th>
      </tr>
    </thead>
    <tbody>
      <ProfileRow key={profile.name} item={profile} />
    </tbody>
  </WargearStyles>
);

export default Wargear;
