// @flow
import React, { useState } from 'react';
import AddSpecialistForm from './AddSpecialistForm';
import SpecialistList from './SpecialistList';
import { H, Grid } from '../../../styles';
import type { SpecialistListType } from '../../../types';

const Faction = ({ firebase, send, specialists }: {
  firebase: any,
   send: Function,
   specialists: SpecialistListType
}) => {
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);

  const onSubmit = (specialist, formType) => {
    if (formType === 'Add') {
      firebase.addByNamespace('specialists', specialist, (data) => {
        send('ADD_SPECIALIST', { data });
      });
    } else if (formType === 'Edit') {
      firebase.updateByNamespace(`specialists/${specialist.id}`, specialist)
        .then((data) => {
          send('UPDATE_SPECIALIST', { data });
        });
    }
  };

  const onEditSpecialist = (sid) => {
    setSelectedSpecialist(sid);
  };

  return (
    <div>
      <H as="h3">Specialists</H>
      <Grid columns="1fr 200px">
        <AddSpecialistForm
          onSubmit={onSubmit}
          selectedSpecialist={selectedSpecialist
        && specialists[selectedSpecialist]}
        />
        <SpecialistList specialists={specialists} onClick={onEditSpecialist} />
      </Grid>
    </div>
  );
};

export default Faction;
