// @flow
import * as React from 'react';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { H } from '../../../../styles';
import ListMenuStyles from './ListMenu.styles';
import type { TeamListCollectionType } from '../../../../types';

const ListMenu = ({ lists, onListEdit, onListAdd }: {
   lists: TeamListCollectionType, onListEdit: Function, onListAdd: Function
  }) => {
  const ref = React.createRef();
  const [isLeft, setIsLeft] = React.useState(false);

  const moveLeft = (pixels = 100) => {
    const currentLeftMargin = window.getComputedStyle(ref.current).marginLeft.slice(0, -2);
    ref.current.style.marginLeft = `-${currentLeftMargin - pixels}px`;
  };

  const moveRight = (pixels = 100) => {
    const currentMargin = window.getComputedStyle(ref.current).marginLeft.slice(0, -2);
    const newMargin = +currentMargin - pixels;
    ref.current.style.marginLeft = `${newMargin}px`;
  };

  React.useLayoutEffect(() => {
    if (ref.current) {
      if (window.getComputedStyle(ref.current).marginLeft === '0px') {
        setIsLeft(true);
      } else {
        setIsLeft(false);
      }
    }
  }, [ref.current]);
  // console.log('css class: ', isLeft);
  return (
    <ListMenuStyles>
      <header>
        <H as="h5">Lists</H>
        <button type="button" onClick={onListAdd}>
          <AiOutlineFileAdd className="icon" />
        </button>
      </header>
      <div className="wrapper">
        {lists && (
        <button type="button" className={`btn btn-left${isLeft ? ' btn-hidden' : ''}`} onClick={() => moveLeft()}>
          <IoIosArrowBack className="icon" />
        </button>
      )}
        {lists && (
        <button type="button" className="btn btn-right" onClick={() => moveRight()}>
          <IoIosArrowForward className="icon" />
        </button>
        )}
        <div className="wrapper--inner" ref={ref}>
          {lists && Object.keys(lists).map((key) => (
            <button
              type="button"
              key={key}
              className="item"
              onClick={() => onListEdit(key)}
            >
              {lists[key].name}
            </button>
))}
        </div>
      </div>
    </ListMenuStyles>
  );
};

export default React.memo(ListMenu);
