// @flow
import React from 'react';
import {
  getListCost, getRosterListSpecialists, getListSpecialistsLimit, createUnitProps,
} from './ListEdit.utils';
import { getListUnits } from './Errors/Errors.utils';
import ListEditStyles from './ListEdit.styles';
import { H, ListUl } from '../../../../styles';
import SpecialistMenu from '../SpecialistMenu';
import Unit from '../../common/Unit';
import ListEditHeader from './ListEditHeader';
import type {
  UnitListType, TeamListType, TeamType,
} from '../../../../types';
import Errors from './Errors';

const TeamListEdit = ({
  list, units, team, selectUnit, onChange, deleteList, copyList,
}: {
  list: TeamListType,
  units: UnitListType,
  team: TeamType,
  selectUnit: Function,
  onChange: Function,
  deleteList: Function,
  copyList: Function,
}) => {
  const listUnits = getListUnits(units, list);
  const listCost = getListCost(listUnits);
  const rosterSpecialists = getRosterListSpecialists(list, units, true);
  const specialistsLimitTotal = getListSpecialistsLimit(list.leaderLimit, list.specialistLimit);

  return (
    <ListEditStyles>
      <H as="h4">Edit List</H>
      <ListEditHeader
        list={list}
        listUnits={listUnits}
        team={team}
        onChange={onChange}
        deleteList={deleteList}
        copyList={copyList}
      />
      <Errors list={list} listCost={listCost} units={units} />
      <SpecialistMenu specialists={rosterSpecialists} specialistLimit={specialistsLimitTotal} />
      <section className="roster-content">
        {units && (
        <ListUl>
          {Object.keys(units).map((key) => {
            const unit = units[key];
            return (
              <Unit
                key={key}
                unit={unit}
                unitOnclick={() => selectUnit(unit)}
                unitProps={createUnitProps(unit, list, units)}
              />
            );
          })}
        </ListUl>
        )}
        {!units && <span>No units in roster</span>}
      </section>
    </ListEditStyles>
  );
};

export default TeamListEdit;
