// @flow
import React, { useState, useEffect } from 'react';
import SelectBox, { SelectBoxProfile } from '../common/SelectBox';
import { H } from '../../../styles';
import type { WargearType, WargearListType } from '../../../types';

const MultipleProfileForm = ({
  formType, formValues, setFormValues, wargear,
}: {
  formType: string,
  formValues: any,
    setFormValues: Function,
    wargear: WargearType
  }) => {
  const [amountOfProfiles, setAmountOfProfiles] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [profileMarkup, setProfileMarkup] = useState('');
  const filteredWargear: Array<WargearType> = wargear ? Object.keys(wargear)
    .map((key: string): WargearType => wargear[key])
    .filter((wg) => !wg.profile) : [];

  const onSelectedProfile = (e, i) => {
    const { value } = e.target[e.target.selectedIndex];
    const selectedWargear: WargearType = filteredWargear ? filteredWargear.find((wg: WargearType) => wg.name === value) : {};
    const newProfiles: Array<WargearType> = [...profiles];
    newProfiles[i] = selectedWargear;

    setProfiles(newProfiles);

    const profile = newProfiles.reduce((newObj: WargearListType, obj: WargearType) => ({
      ...newObj,
      [obj.name]: obj,
    }), {});

    const updatedWargear = {
      ...formValues,
      name: newProfiles.map((p) => p.name).join(', '),
      profile,
    };
    setFormValues(updatedWargear);
  };

  useEffect(() => {
    let profileCount = amountOfProfiles;
    let currentProfiles = profiles;

    if (formValues && formValues.profile) {
      const formValuesProfiles = Object.keys(formValues.profile).length;
      profileCount = formType === 'Add' ? profileCount : formValuesProfiles;
      currentProfiles = Object.keys(formValues.profile).map((key) => formValues.profile[key]);
    }

    if (profileCount) {
      const profilesArray = [...Array(profileCount).keys()];
      const markup = profilesArray.map((i) => (
        <SelectBoxProfile
          key={i.toString()}
          name={`Profile ${i}`}
          data={filteredWargear}
          selectedData={currentProfiles.length ? currentProfiles[i] : null}
          onSelect={(e) => onSelectedProfile(e, i)}
        />
      ));
      setProfileMarkup(markup);
    }

    if (profileCount !== amountOfProfiles) {
      setAmountOfProfiles(profileCount);
    }
  }, [amountOfProfiles, profiles, formValues]);


  return (
    <section>
      <label htmlFor="true">
        <span>Name*</span>
        <input
          name="name"
          value={formValues ? formValues.name : ''}
          onChange={(e) => setFormValues({ ...formValues, name: e.currentTarget.value })}
          type="text"
          placeholder="Shotgun"
        />
      </label>
      <label htmlFor="true">
        <span>Cost</span>
        <input
          name="cost"
          value={formValues ? formValues.cost : ''}
          onChange={(e) => setFormValues({ ...formValues, cost: e.currentTarget.value })}
          type="text"
          placeholder="0"
        />
      </label>
      <hr />
      <H as="h5">Profiles</H>
      <SelectBox
        name="Amount of profiles"
        data={[1, 2, 3, 4, 5]}
        selectedData={amountOfProfiles.toString()}
        onSelect={(e) => setAmountOfProfiles(Number(e.target[e.target.selectedIndex].value))}
      />
      {profileMarkup}
    </section>
  );
};

export default MultipleProfileForm;
