import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --base080: #EEEEEE;
    --base090: #d6d5d3;
    --base: #AAAAAA;
    --base110: #555555;
    --base120: #252525;
    --base130: #1a1406;
    --black: #000000;
    --white: #ffffff;

    --error090: #FFE5E6;
    --error: #E51B22;
    --error110: #991216;

    --highlight090: #85E0FF;
    --highlight: #00BEFF;
    --highlight110: #007299;

    --success090: #E5FFF2;
    --success: #24874E;
    --success110: #24874E;

    --interactive090: #FFEF6E;
    --interactive: #FFE71F;
    --interactive110: #F0CD0E;

    --brand090: #f3dfcd;
    --accent: #C6A224;
    --red: #c65124;
    --dark-red: #581845;
    --green: #99C624;
    --green2: #48C624;
    --green3: #24C651;
    --green4: #24C6A2;

    --maxWidth: 1200px;
  }

  html {
    box-sizing: border-box;
    border-top: none;
    /* background: var(--base090); */
    color: var(--base120);
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    min-height: calc(100vh - 40px);
    font-size: 1rem;
    background: var(--white)
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  h1 {
    font-style: italic;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Oxanium';
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 0;
  }
  a {
    color: var(--base080);
  }
  p, ul, ol {
    /* line-height: 1.77777777777777776777777777; */
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  ul {
    list-style-type: square;
  }
  button {
    background: transparent;
    border: none;
  }
  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--dark-red);
  }
  body::-webkit-scrollbar-track {
    background: darkgrey;
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border: 1px solid var(--dark-red);
  }

  .disable-scroll {
    height: 100%;
    overflow: hidden;
  }
`;
export default GlobalStyle;
