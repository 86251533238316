// @flow
import React from 'react';
import { AiOutlineLogout as LogOut } from 'react-icons/ai';
import styled from 'styled-components';
import { withFirebase } from '../../Firebase';
import H from '../../../styles/Headings';

const SignOutButtonStyles = styled.a`
.logout-icon  {
  width: 30px;
}

@media (max-width: 550px) {
  .logout-icon {
    display:none;
  }
}
`;

type Props = {|
  firebase: Object,
|}

const SignOutButton = ({ firebase }: Props) => (
  <SignOutButtonStyles role="button" onClick={firebase && firebase.doSignOut} aria-label="Sign out">
    <LogOut color="#fff" className="logout-icon" />
    <H as="h6">Logout</H>
  </SignOutButtonStyles>
);

export default withFirebase(SignOutButton);
