
import styled from 'styled-components';

export const ListUl = styled.ul`
width: 100%;
margin: 0;
padding: 0;
list-style-type: none;
background: var(--white);
`;

export const ListLi = styled.li`
display: flex;
flex-wrap: wrap;
border-bottom: 1px solid var(--base080);

&.disabled {
  background: var(--base090);
  color: var(--base);

  & .list-info > h5, & .list-info > p {
    color: var(--base);
  }

  & .list-info > p:last-child {
    /* color: var(--red); */
  }
}

&.border-left {
  border-left: 6px solid var(--base080);
  &.is-leader {
    border-left-color: var(--red);
  } 
  &.is-specialist {
    border-left-color: var(--base130);
  } 
}

&.is-active {
  background: var(--success090);
}

.list-name {
  flex: 1;
}

.list-edit {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.list-edit-btn {
  cursor: pointer;
  height: 100%;
  border: none;

  &:hover {
    background: var(--base080); 
  }
}

.list-info {
  font-size: 0.8rem;
  padding: 0.1rem;
}

.edit-list-btn {
  cursor: pointer;
  width: 100%;
  padding: 0.5rem  0.3rem 0.3rem;
  text-align: left;
  font-size: 1.4rem;
  font-family: 'Oxanium';
  color: var(--base130);
}

.list-info > h5 {
  font-size: 1.3rem;
  font-weight: bold;
}

.list-info > p {
  padding: 0.2rem 0.1rem 0.1rem;
  color: var(--base110);
}
.list-info > p:last-child {
  padding: 0 0.1rem 0.1rem;
}

.list-info > p > span {
  text-transform: capitalize;
}

.icon, .faction-icon {
  display: none;
  margin: 0 0.3rem;
}

.strong {
  font-weight: bold;
}

.highlight {
  color: var(--red);
}

.list-edit .faction-icon {
  width: 30px;
  height: 30px;
  color: red;
}

@media screen and (min-width: 550px) {
  &.border-left {
    border-left-width: 10px;
  }

  .edit-list-btn {
    display: flex;
    transition: all 0.3s ease-in-out;

    &:hover .faction-icon {
      display: none;
    }

    &:hover .icon {
      display: inline;
    }

    &:hover  {
      background: var(--base080);
    }
  }

  & .icon {
    align-self: center;
    transition: all 0.3s ease-in-out;
    width: 40px;
    height: 40px;
  }

  & .faction-icon {
    display: inline;
    align-self: center;
    width: 40px;
    height: 40px;
    color: red;
  }
}
`;
