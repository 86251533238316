// @flow
import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import FooterStyles from './FooterStyles';
import { CStudio } from '../../assets/icons';

const Footer = () => (
  <FooterStyles>
    <div className="footer-bottom">
      <Link to={ROUTES.ABOUT}>Privacy Policy</Link>
      <Link to={ROUTES.ABOUT}>About Kill Team Companion</Link>
    </div>
    <div>
      <a href="https://craven-studio.com" target="self" title="C Studio">
        <CStudio className="logo" color="#f2581a" />
      </a>
    </div>
  </FooterStyles>
);

export default Footer;
