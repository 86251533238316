// @flow
import { isLeader, isSpecialist } from '../../EditTeam/EditTeam.utils';
import { totalUnitCost } from '../../../../utils';
import type {
  UnitListType, UnitType, TeamListType, UnitPropsType,
} from '../../../../types';
import { SPECIALISTS } from '../../../../constants';
import { getListUnits } from './Errors/Errors.utils';

export const getListSpecialistsLimit = (leaderLimit: number, specialistLimit: number) => {
  if (leaderLimit === -1 && specialistLimit) return -1;
  if (leaderLimit === -1 && specialistLimit !== -1) return specialistLimit;
  if (specialistLimit === -1 && leaderLimit !== -1) return leaderLimit;

  return leaderLimit + specialistLimit;
};

export const getListCost = (listUnits: Array<UnitType>): number => listUnits
  .reduce((total, unit) => {
    const { totalCost } = unit;
    return totalCost ? totalCost + total : totalUnitCost(unit) + total;
  }, 0);

export const getRosterListSpecialists = (list: TeamListType,
  rosterUnits: UnitListType, withLeader: boolean = false) => Object
  .keys(rosterUnits).reduce((specialists, key) => {
    if (withLeader && rosterUnits[key].specialist && list.units
      && list.units.find((unitId) => unitId === key)) {
      return [...specialists,
        rosterUnits[key].specialist.name];
    }

    if (rosterUnits[key].specialist && list.units
      && list.units.find((unitId) => unitId === key
      && rosterUnits[key].specialist.name !== SPECIALISTS.LEADER)) {
      return [...specialists,
        rosterUnits[key].specialist.name];
    }

    return specialists;
  }, []);

export const isUnitSpecialismTaken = (unit: UnitType,
  list: TeamListType, rosterUnits: UnitListType) => {
  if (!unit.specialist) return false;
  if (unit.specialist && unit.specialist.name === SPECIALISTS.LEADER) return false;

  const teamListSpecialists = getRosterListSpecialists(list, rosterUnits);
  const specialism = unit.specialist && unit.specialist.name;

  return !!teamListSpecialists.find((spec) => spec === specialism);
};

export const isUnitSelected = (unit: UnitType, list: TeamListType) => (list.units
  ? !!list.units.find((id) => id === unit.id) : false);

export const isLeaderMaxed = (list: TeamListType, rosterUnits: UnitListType) => {
  const { leaderLimit } = list;
  const totalLeaderCount = list.units ? list.units.reduce((leaderCount, unitId) => {
    const leader = Object.keys(rosterUnits).find((key) => key === unitId
    && rosterUnits[key].specialist && rosterUnits[key].specialist.name === SPECIALISTS.LEADER);
    if (leader) {
      return leaderCount + 1;
    }
    return leaderCount;
  }, 0) : 0;

  return leaderLimit === -1 ? false : (totalLeaderCount >= leaderLimit);
};

export const isUnitDisabled = (
  isSelected: boolean,
  unit: UnitType,
) => (!isSelected && unit.specialist
  && unit.specialist.name === SPECIALISTS.LEADER);

export const getUnitLimit = (unit: UnitType, listUnits: Array<UnitType>): string => {
  const unitLimit = unit.stats.max === -1 ? '∞' : unit.stats.max;
  if (!listUnits) return `0 of ${unitLimit}`;
  const unitTypeCount = listUnits.reduce((total, listUnit) => {
    let count = total;
    if (listUnit.type === unit.type) {
      count += 1;
    }
    return count;
  }, 0);

  return `${unitTypeCount} of ${unitLimit}`;
};

export const createUnitProps = (unit: UnitType,
  list: TeamListType,
  rosterUnits: UnitListType): UnitPropsType => {
  const listUnits = getListUnits(rosterUnits, list);
  const isSelected = isUnitSelected(unit, list);
  const unitLimit = getUnitLimit(unit, listUnits);

  return {
    isLeader: isLeader(unit),
    isSpecialist: isSpecialist(unit),
    isSelected,
    isDisabled: false,
    unitLimit,
  };
};
