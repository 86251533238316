// @flow
import React, { useState } from 'react';
import { getLocalState, deleteLocalState } from './teamHandlers';
import { AddTeamForm } from './AddTeam';
import ListTeams from './ListTeams';
import EditTeam from './EditTeam';
import useKtMachine from './machine';
import Spinner from '../Spinner';
import EditMenu from '../EditMenu';
import TeamOptions from './TeamOptions';
import { APP, ACTIONS, STATES } from '../../constants';
import BackButton from './BackButton';
import { Content, H } from '../../styles';
import type { TeamType } from '../../types';

const TeamManager = () => {
  const savedState = getLocalState(APP.STORAGE_KEY);
  const [current, send] = useKtMachine({ factions: savedState });
  const { factions, teams, team } = current.context;
  const [teamOptions, setTeamOptions] = useState();
  deleteLocalState(APP.STORAGE_KEY);
  const selectTeam = (t: TeamType) => {
    send(ACTIONS.SELECT, { ...t });
  };

  if (current.matches(STATES.GETTING_LOCAL_FACTIONS)) {
    return (
      <Content title="My Teams">
        <Spinner label="Loading Factions from local storage..." />
      </Content>
    );
  } if (current.matches(STATES.GETTING_LOCAL_FACTIONS)
  || current.matches(STATES.GETTING_FACTIONS)) {
    return (
      <Content title="My Teams">
        <Spinner label="Loading Factions from database..." />
      </Content>
    );
  } if (current.matches(STATES.GETTING_TEAMS)) {
    return (
      <Content title="My Teams">
        <Spinner label="Loading Teams from database..." />
      </Content>
    );
  } if (current.matches(STATES.GETTING_ABILITIES)) {
    return (
      <Content title="My Teams">
        <Spinner label="Loading Abilities from database..." />
      </Content>
    );
  } if (current.matches(STATES.GETTING_SPECIALISTS)) {
    return (
      <Content title="My Teams">
        <Spinner label="Loading Specialists from database..." />
      </Content>
    );
  } if (current.matches(STATES.GETTING_WARGEAR)) {
    return (
      <Content title="My Teams">
        <Spinner label="Loading Wargear from database..." />
      </Content>
    );
  } if (current.matches(STATES.SUCCESS)) {
    return (
      <Content title="My Teams">
        <AddTeamForm factions={factions} teams={teams} send={send} />
        <ListTeams
          teams={teams}
          onOptionsClick={(tid) => {
            send(ACTIONS.OPEN_TEAM_OPTIONS_MENU);
            setTeamOptions(teams[tid]);
          }}
          selectTeam={selectTeam}
        />
        {current.matches({ [STATES.SUCCESS]: STATES.TEAM_OPTIONS_MENU }) && (
        <EditMenu onClick={() => send(ACTIONS.CLOSE_TEAM_OPTIONS_MENU)}>
          {teamOptions && <TeamOptions team={teamOptions} send={send} />}
        </EditMenu>
      )}
      </Content>
    );
  } if (current.matches(STATES.SELECTED)) {
    return team
    && (
      <Content>
        <H as="h3" red bold bottomBorder pb>
          <BackButton onClick={() => send(ACTIONS.BACK_TO_DEFAULT)} />
          Roster
        </H>
        <EditTeam service={team} />
      </Content>
    );
  }

  return (
    <Content title="My Teams">
      Initializing...
    </Content>
  );
};

export default TeamManager;
