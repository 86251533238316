// @flow
import React from 'react';
import H from '../../../styles/Headings';
import type { AbilitiesType } from '../../../types';
import AbilitiesStyles from './AbilitiesStyles';

const Abilities = ({ abilities }: { abilities: Array<AbilitiesType> }) => {
  const abilitiesMarkup = abilities.map((ability) => (
    <li key={ability.id}>
      <H as="h6">{ability.name}</H>
      <p>{ability.descr}</p>
    </li>
  ));
  return (
    <AbilitiesStyles>
      <H as="h5">Abilities</H>
      <ul>
        {abilitiesMarkup}
      </ul>
    </AbilitiesStyles>
  );
};

export default Abilities;
