// @flow
import React from 'react';
import type { UnitType } from '../../../types';
import UnitStatsStyles, { ArticleStyle } from './UnitStatsStyles';

const UnitStats = ({ unit }: { unit: UnitType}) => {
  const {
    totalCost, type, stats: {
      m, ws, bs, s, t, w, a, ld, sv, max,
    },
  } = unit;
  return (
    <ArticleStyle>
      <UnitStatsStyles>
        <thead>
          <tr>
            <th>Type</th>
            <th>M</th>
            <th>WS</th>
            <th>BS</th>
            <th>S</th>
            <th>T</th>
            <th>W</th>
            <th>A</th>
            <th>Ld</th>
            <th>Sv</th>
            <th>Max</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="first">{type}</td>
            <td>{`${m}"`}</td>
            <td>{`${ws}+`}</td>
            <td>{`${bs}+`}</td>
            <td>{s}</td>
            <td>{t}</td>
            <td>{w}</td>
            <td>{a}</td>
            <td>{ld}</td>
            <td>{`${sv}+`}</td>
            <td>{max === -1 ? '∞' : max}</td>
            <td>{`${totalCost}pts`}</td>
          </tr>
        </tbody>
      </UnitStatsStyles>
    </ArticleStyle>
  );
};

export default UnitStats;
