// @flow
import React from 'react';

import {
  FiEdit as Copy, FiTrash2 as Delete,
} from 'react-icons/fi';
import { H } from '../../../styles';
import type {
  UnitType,
} from '../../../types';
import OptionsMenuStyles from './UnitOptionsMenuStyles';

const UnitOptionsMenu = ({
  isVisible, setIsVisible, unit, action,
}: {
  isVisible: boolean,
  setIsVisible: Function,
  unit: UnitType,
  action: (action: string, unit: UnitType) => void
}) => (
  <OptionsMenuStyles className={`${isVisible ? 'is-visible' : ''}`}>
    <div
      className="menu-background"
      onClick={() => setIsVisible(false)}
      onKeyPress={() => setIsVisible(false)}
      aria-label="button"
      role="button"
      tabIndex={0}
      title="background"
    />
    <div className="menu">
      <div className="menu-inner">
        <div className="list-edit-menu">
          <button
            type="button"
            onClick={() => { action('copy', unit); setIsVisible(false); }}
            aria-label="Copy Unit"
          >
            <Copy size="30" className="menu-icon" />
            <H as="h6">Copy</H>
          </button>
          <button
            type="button"
            onClick={() => { if (window.confirm('Are you sure you want to delete this unit?')) action('delete', unit); setIsVisible(false); }}
            aria-label="Delete Unit"
          >
            <Delete size="30" className="menu-icon" />
            <H as="h6">Delete</H>
          </button>
        </div>
      </div>
    </div>
  </OptionsMenuStyles>
);

export default UnitOptionsMenu;
