// @flow
import React from 'react';
import FormStyles from './FormStyles';
import { H } from '../../../../styles';
import { ORIGIN } from '../../constants';
import { SelectBox } from '../../common';
import type { FactionUnitType } from '../../../../types';

const splitKeywords = (keywords: Array<string>): string => keywords.join(', ');
const mergeKeywords = (keywords: string): Array<string> => keywords.split(', ');

const Form = ({
  formValues, setFormValues, disabled,
}: {
  formValues: FactionUnitType,
  setFormValues: Function,
  disabled: boolean,
}) => (
  <FormStyles>
    <H as="h5" className="stats-title">General</H>
    <label htmlFor="true">
      <span>Type* (My Type)</span>
      <input
        name="name"
        onFocus={(event) => event.target.select()}
        value={formValues ? formValues.type : ''}
        onChange={(e) => setFormValues({ ...formValues, type: e.currentTarget.value })}
        type="text"
        placeholder="Scout Gunner"
        disabled={disabled}
      />
    </label>
    <label htmlFor="true">
      <span>Group* (group)</span>
      <input
        name="group"
        onFocus={(event) => event.target.select()}
        value={formValues ? formValues.group : ''}
        onChange={(e) => setFormValues({ ...formValues, group: e.currentTarget.value })}
        type="text"
        placeholder="scout"
        disabled={disabled}
      />
    </label>
    <label htmlFor="true">
      <span>Cost*</span>
      <input
        name="cost"
        onFocus={(event) => event.target.select()}
        value={formValues ? formValues.cost : ''}
        onChange={(e) => setFormValues({ ...formValues, cost: Number(e.currentTarget.value) })}
        type="text"
        placeholder="10"
        disabled={disabled}
      />
    </label>
    <SelectBox
      name="Origin*"
      data={[ORIGIN.CORE, ORIGIN.ELITES, ORIGIN.COMMANDERS]}
      selectedData={formValues ? formValues.origin : ORIGIN.CORE}
      onSelect={(e) => setFormValues({
 ...formValues,
        origin: e.target[e.target.selectedIndex].value,
})}
      disabled={disabled}
    />
    <label htmlFor="true">
      <span>Keywords* (MY,KEYWORD)</span>
      <input
        name="keywords"
        onFocus={(event) => event.target.select()}
        value={formValues && formValues.keywords ? splitKeywords(formValues.keywords).toUpperCase() : ''}
        onChange={(e) => setFormValues({
 ...formValues,
          keywords: mergeKeywords(e.currentTarget.value),
})}
        type="text"
        placeholder="IMPERIUM, INFANTRY, SCOUT"
        disabled={disabled}
      />
    </label>
  </FormStyles>
);

export default Form;
