// @flow
import React from 'react';
import { ListUl } from '../../styles/ListStyles';
import type { TeamCollectionType } from '../../types';
import Team from './Team';

type Props = {
  teams: TeamCollectionType,
  onOptionsClick: Function,
  selectTeam: Function,
}

const TeamList = ({
  teams, onOptionsClick, selectTeam,
}: Props) => (
  <ListUl>
    {
        teams && Object.keys(teams).length
          ? Object.keys(teams).map((key) => {
            const team = teams[key];
            return (
              <Team
                key={key}
                team={team}
                onOptionsClick={onOptionsClick}
                selectTeam={selectTeam}
              />
            );
          }) : <p>No Teams yet.</p>
      }
  </ListUl>
);

export default TeamList;
