// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 924.3 342.5"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path d="M354,145.9c0.5-1.6,1.6-2.4,3.8-2.1c-1,15.1-1.9,30.1-2.9,45.5c0.7-0.4,0.9-0.5,1.1-0.7c5.6-6.3,22.5-23.8,22.4-28.4
  c-0.1-1.8,0-3.6-0.1-5.3c-0.2-5.2-0.4-10.3-0.6-15.5c-0.1-2,0.6-3,1.9-3.3c1.1-0.2,2,0.5,2.5,2.5c1.2,4.1,2.3,8.3,3.4,12.5
  c0.2,0.6,0.3,1.1,0.6,2.3c1-1.2,1.8-1.9,2.4-2.8c2.7-4.1,5.3-8.4,8.2-12.5c2.1-2.9,3.6-5.9,2.8-9.6c-0.1-0.3,0-0.6,0-0.8
  c0.3-3.2,5.1-6.6,7.4-3.2c0.6,0.9,0.7,2,0.5,3c-0.2,0.8-0.4,1.5-0.8,2.2c-0.9,1.8-1.9,3.6-3.1,5.4c-4.4,6.6-8.8,13.3-13.3,19.9
  c-5.8,8.6-11.6,17.1-17.3,25.7c-2.9,4.4-2.7,5,1.6,7.7c8.1,5.1,16.2,10.2,24.3,15.2c0.6,0.4,1.2,0.7,2,1.2c0.5-1.2,0.9-2.1,1.3-3
  c11.2-28.7,22.4-57.4,33.7-86.1c0.5-1.3,0.7-2.4,0.5-3.5c-0.6-2.4-15.5-16.9-19.6-21.2l-3-3.9c-6.5,2.4-17.3,7.4-23.8,9.8
  c-10.1,3.8-20.2,7.9-31.1,9.2c-4.9,0.6-9.8,1.2-14.8,1c-11.2-0.4-20.3-5-27.3-13.6c-2.1-2.5-4-5.3-5.2-8.3c-4.2-11-4.8-22,0.5-32.9
  c0.6-1.3,1.1-2.6,1.8-3.8c1.3-2.2,2.5-4.4,4.1-6.3c4-4.9,8.1-9.6,12.3-14.3c1.5-1.7,3.1-3.3,4.9-4.6c4.9-3.4,10.2-6.3,15.9-8.3V0
  c-1.3,0-2.4,0-3.5,0c-14.8,0.4-29.6,0.3-44.4,0.8c-9.4,0.3-18.7,0.3-28.1,0.5C258.1,1.5,241,1.8,224,2c-8.9,0.1-17.8,0.2-26.7,0.4
  c-17.3,0.3-34.7,0.5-52,0.8c-9.1,0.1-18.2,0.3-27.3,0.4C100.8,3.8,83.7,4,66.5,4.2C46.7,4.4,26.8,4.6,6.9,4.9
  c-2.2,0-4.4,0.3-6.6,0.4C0.2,5.6,0.2,5.7,0,6c0.4,0.5,0.7,1,1.1,1.5c2.1,2.6,4.3,5.2,6.4,7.8c1.3,1.6,2.3,3.6,3.8,5
  c5.4,5,10.1,10.6,13.9,16.8c2.4,4,5,5.9,9.2,5.6c6.2-0.5,12.3-0.8,18.5-1.1c13.9-0.6,27.7-1.2,41.6-1.8c16.6-0.8,33.2-1.6,49.7-2.3
  c8.4-0.4,16.9-0.8,25.3-1.2c14.6-0.7,29.2-1.4,43.8-2.1c10-0.5,20-0.9,30.1-1.4c13.3-0.6,26.6-1.3,39.9-1.9c0.6,0,1.2,0,1.8,0
  c-0.6,1.8-1.1,3.4-1.8,5.3C202.7,43.4,122,49.8,41,56.8c0.7,0.9,1.1,1.4,1.5,1.9c4.6,4.7,9.2,9.4,13.7,14.1
  c4.9,5.1,9.8,10.3,14.7,15.5c2.2,2.4,4.6,3.7,7.9,2.6c0.4-0.2,0.9-0.3,1.4-0.4c29.4-5.4,58.8-10.9,88.2-16.3
  c36.5-6.7,73-13.5,109.5-20.2c0.9-0.2,1.7-0.2,2.6-0.3c0.1,0.2,0.2,0.4,0.3,0.7c-0.6,0.6-1.1,1.4-1.7,1.8c-0.7,0.5-1.6,0.6-2.4,0.9
  c-8.2,2.3-16.4,4.5-24.6,6.8c-18.5,5.1-37,10.3-55.5,15.4c-7.6,2.1-15.2,4-22.8,6.1c-7,1.9-13.9,3.9-20.8,5.9
  c-0.4,0.1-0.7,0.1-1.1,0.2c-2.5,0.6-5.1,1.2-7.6,1.8c-4,1-7.9,2.2-11.9,3.2c-3,0.8-6,1.3-9,2c-1.3,0.3-2.6,0.9-3.9,1.3
  c-3.4,1-6.9,1.7-10.2,3c-4.5,1.8-9.3,2.8-13.9,4.3c-1,0.3-2.1,0.7-4,1.3c1.1,0.7,1.8,1.1,2.3,1.5c10.1,9,20.3,18.1,30.4,27.2
  c0.9,0.8,1.8,1.7,2.7,2.6c1.9,2,4,2.6,6.5,1.4c1.3-0.6,2.6-1,3.9-1.5c46.2-18,92.3-36.1,138.5-54.1c0.8-0.3,1.7-0.6,2.6-0.9
  c0.4,3.2,0.3,3.9-2.2,5.4c-3.1,1.8-6.3,3.3-9.4,5c-30.4,16.3-60.7,32.7-91.1,49c-6.6,3.6-13.3,7-19.9,10.5c-1.7,0.9-3.5,1.7-5,3.4
  c11.8,10,23.3,19.8,35.1,29.9c16.9-12.6,33-26.4,49.7-39.5c16.6-13.1,33.1-26.4,49.8-39.7c0.7,1,1.3,1.8,1.9,2.6
  c-0.6,0.7-1.1,1.3-1.6,1.9c-13.6,14.1-27.3,28.2-40.9,42.3c-13.1,13.6-26.3,27.3-39.4,41c-0.7,0.7-1.3,1.5-2,2.2
  c10.1,6.4,19.9,12.7,29.7,18.9c2.6,1.6,2.7,1.6,4.5-0.8c0.1-0.1,0.2-0.3,0.3-0.4c15.8-20.4,31.5-40.8,47.3-61.2
  c5.4-7,10.9-14.1,16.4-21.1c0.8-1,1.4-2.3,3.5-1.6c-0.5,1.2-0.9,2.4-1.6,3.5c-9.8,17-19.6,33.9-29.5,50.8
  c-6.5,11.1-13,22.2-19.4,33.3c-0.7,1.2-1.7,2.4-1.5,4c0.3,0.1,0.5,0.2,0.6,0.3c10.3,1.5,20.6,3.1,30.9,4.5c1.7,0.2,3.5,0.2,5.2,0.3
  c0.9-2,1.6-3.7,2.3-5.5c10.4-23.8,20.7-47.6,31.1-71.4c1.2-2.7,2.3-5.3,3.6-7.9c1.6-3.2,3.6-3.7,6.9-1.5c-0.3,1.2-0.6,2.5-1,3.7
  c-8.8,26.3-17.6,52.6-26.4,78.9c-0.2,0.7-0.3,1.4-0.5,2.2c0.4-0.1,0.6-0.2,0.8-0.3c10.7-7.6,21.4-15.2,32.1-22.8
  c2-1.4,3.2-3.4,3.7-5.8c0.7-3.3,1.3-6.6,2.1-9.9c2.6-11.8,5.2-23.6,7.9-35.4C352.7,150.8,353.3,148.4,354,145.9z"
      />
      <path d="M409.4,297.3c-2.2-3.5-4.4-7-6.8-10.4c-1.6-2.3-1.8-4.7-0.8-7.2c0.4-1,0.9-2,1.5-3c5.1-9,10.2-17.9,15.3-26.9
  c0.4-0.6,0.6-1.3,1.1-2.3c-6-4.7-11.9-9.4-18-14.2c-0.8,1.6-1.4,2.8-2,4.1c-5,10.5-9.9,21-15.1,31.4c-3.5,6.9-2.2,6.3-10.8,6.1
  c-7.4-0.2-14.8-0.4-22.2-0.7c-0.8,0-1.6,0.1-2.5,0.2c1.6,10.3,3.2,20.4,4.8,30.4c0.8-0.5,1.1-1.1,1.4-1.7c2.2-3.9,4.5-7.8,6.7-11.7
  c0.6-1.1,1.4-2,2.8-2c3.1,0,6.1,0,9.5,0c-0.4,0.9-0.7,1.5-1,2c-5.8,10.6-11.5,21.3-17.3,31.9c-0.7,1.2-1.3,2.5-1.9,3.8
  c-1.5,3.2-0.9,6.2,1.6,8.5c1.7,1.6,3.7,2.8,5.7,4c5.9,3.4,12.4,2.6,19.3,2.8c-1.1-1.2-1.8-2.2-2.6-3c-1.9-1.8-3.9-3.5-5.7-5.5
  c-3.3-3.6-3.5-6-1-10.1c3.3-5.3,6.6-10.7,9.9-16c1.8-2.8,3.6-5.7,5.4-8.6c1.3,1,2.4,1.9,3.7,2.7c1.5,1,2.1,2.2,1.4,4.1
  c-1.9,5.5-3.8,11-5.7,16.5c-0.2,0.6-0.3,1.1-0.6,2.3c1.3-0.9,2.2-1.4,3-2c6.6-4.7,13.1-9.4,19.6-14.1c0.5-0.4,1.1-0.7,1.6-1.2
  c2.1-1.8,2.5-3.1,1.6-5.7C411,300.3,410.3,298.7,409.4,297.3z"
      />
      <path d="M466.6,70.6c4.2,9.2,8.3,18.4,12.5,27.6c0.2,0.5,0.6,1.1,1,1.8c0.8-0.7,1.3-1.2,1.8-1.7c5-5.5,10-10.9,15-16.4
  c3.6-3.9,7.2-7.7,10.8-11.5c1.8-1.9,2.6-4.1,2.1-6.7c-1.2-6.8-4.5-12.4-9.7-17c-1-0.8-2.2-1.4-3.2-2.1c-2,1.5-3.6,2.8-5.3,4
  c-8.1,5.6-16.2,11.2-24.3,16.8c-2,1.4-2.1,1.6-1.2,3.9C466.3,69.7,466.5,70.2,466.6,70.6z"
      />
      <path d="M408.7,64.2c0.5-1.2,1-2.4,1.6-3.6c5.1-10.3,11.4-19.2,23.3-22.3c3.6-0.9,4.1-2.4,3.2-6.5c-4-0.3-8-0.7-12-1
  c-10.4-0.7-20.7-1.2-31.1-1.9c-5.3-0.3-10.6-0.6-16-1c-1.4-0.1-2.2,0.4-2.8,1.7c-1.8,3.8-3.7,7.6-5.5,11.4
  c-0.2,0.4-0.2,0.9-0.4,1.4c13,7.4,25.9,14.8,39,22.2C408.5,64.5,408.7,64.4,408.7,64.2z M390.4,46c-2.6,0-5.2-2.4-5.3-4.8
  c-0.1-2.4,2.2-5,4.4-4.9c2.7,0,5.7,2.7,5.6,4.9C395,43.4,392.5,46,390.4,46z"
      />
      <path d="M430.7,46.9c-7.9,4-15.6,13.5-17.9,22c2.6,3.9,26.1,32,27.8,33.2c2.1-3.3,13.7-33.2,14.1-36.1c-0.1-0.1-0.2-0.3-0.3-0.4
  c-6.7-5.4-13.4-10.9-20.1-16.3C433,48.4,431.7,47.7,430.7,46.9z"
      />
      <path d="M487.5,35.7c1.7,0.4,3.4,1,5.2,1.2c4.7,0.6,8.5,2.9,11.7,6.2c2.5,2.5,4.7,5.3,6.9,8.1c1.9,2.5,2.9,5.4,3.2,8.5
  c0.4,3.5,1.1,7,1.7,10.5c5.7-0.7,8.2-2.4,10.2-7.2c1.5-3.6,3.4-6.9,5.9-9.9c2.9-3.5,6.2-6.2,10.6-7.5c1.3-0.4,2.6-0.4,3.8-0.9
  c0.6-0.2,1.3-1,1.3-1.4c0-3.2-0.3-6.3-0.5-9.4c-8.2-1.3-58.5-5.2-60.9-4.6c-0.2,1.2-0.5,2.5-0.7,3.8
  C485.7,34.5,486.2,35.3,487.5,35.7z"
      />
      <path d="M533.4,60.5c-0.4,0.4-0.8,0.9-1.4,1.7c6.6,0.8,12.8,1.6,18.9,2.4c2.4,0.3,2.4,0.3,2.8,2.6c0.8,4,1.8,8,3.9,12.4
  c4.4-6.6,9.1-12.3,12-19.1c0.2-0.6,0.4-1.2,0.5-1.9c0.4-3.1-0.5-4.5-3.5-5.4c-3.6-1-7.2-2.1-10.8-3c-2.1-0.5-4.2-0.9-6.3-1.4
  c-1.4-0.3-2.5,0.1-3.6,1C541.9,53.4,537.6,56.9,533.4,60.5z"
      />
      <path d="M350.9,64.2c0.3,0.3,0.6,0.7,1,1c4.3,4.7,8.6,9.4,12.9,14c0.3,0.3,0.7,0.6,1.2,1c0-2.1,0.2-3.9,0-5.7
  c-0.6-4.9,1.6-7.7,5.9-9.6c4.3-1.9,8.5-4,12.7-6c0.8-0.4,1.6-0.8,2.8-1.5c-5.3-2.8-10.2-5.3-15-8c-1-0.6-1.8-0.1-2.6,0.3
  c-5.9,2.7-11.7,5.3-17.6,8C348.4,59.7,348.1,61.1,350.9,64.2z"
      />
      <path d="M509.1,284.3c-0.5-1.1-0.9-2.3-1.4-3.4c-5.3-11.5-10.7-22.9-16-34.4c-0.2-0.3-0.2-0.7-0.4-1.3c0.4-0.5,0.9-1,1.4-1.5
  c7-6.1,14-12.1,21.1-18.2c4.2-3.6,4.4-4.3,2.4-9.3c-14.6-37.1-29.2-74.3-43.7-111.4c-3.6-9.1-7.1-18.1-10.7-27.2
  c0-0.1-0.1-0.2-0.1-0.3l-0.6,0.3c-3.6,9.1-7.1,18.1-10.7,27.2c-14.6,37.1-29.2,74.3-43.7,111.4c-2,5.1-1.8,5.7,2.4,9.3
  c7.1,6,14.1,12.1,21.1,18.2c0.5,0.5,1,1,1.4,1.5c-0.2,0.6-0.3,0.9-0.4,1.3c-5.3,11.5-10.7,22.9-16,34.4c-0.5,1.1-0.9,2.2-1.4,3.4
  c-0.5,1.1-0.3,2.2,0.3,3.2c0.9,1.5,5.7,6.3,7.6,7.4c0.4-0.9,0.8-1.7,1.2-2.5c4.2-9.9,8.5-19.8,12.7-29.7c0.7-1.5,1.2-3.2,2.8-4
  c2.7,1.4,2.9,2,1.7,5.2c-3.7,10.5-7.4,21-11.2,31.5c-0.8,2.3-0.8,4.4,0.8,6.3c2.1,2.5,4.2,5,6.2,7.5c1.9-0.4,2.1-1.9,2.6-3.2
  c3.6-10.4,7.2-20.9,10.8-31.3c0.7-2.2,1.7-4.3,2.5-6.5c2,1.9,2.1,2,1.5,4c-1,4-2,8-3.2,11.9c-2.6,8.8-5.2,17.6-7.8,26.4
  c-0.8,2.7-0.4,5.1,1.4,7.3c3.5,4.5,13.2,13.5,17.4,17.4v0.5c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.2v-0.5
  c4.1-3.9,13.9-12.9,17.4-17.4c1.8-2.3,2.2-4.6,1.4-7.3c-2.6-8.8-5.3-17.6-7.8-26.4c-1.1-4-2.2-8-3.2-11.9c-0.5-2-0.5-2.1,1.5-4
  c0.9,2.2,1.8,4.3,2.5,6.5c3.6,10.4,7.2,20.9,10.8,31.3c0.4,1.3,0.6,2.8,2.6,3.2c2-2.5,4.1-5,6.2-7.5c1.6-1.9,1.6-4,0.8-6.3
  c-3.8-10.5-7.5-21-11.2-31.5c-1.1-3.2-0.9-3.8,1.7-5.2c1.7,0.8,2.2,2.4,2.8,4c4.3,9.9,8.5,19.8,12.7,29.7c0.4,0.9,0.8,1.7,1.2,2.5
  c1.9-1.2,6.7-5.9,7.6-7.4C509.4,286.5,509.7,285.5,509.1,284.3z"
      />
      <path d="M554.6,289.5c1.4,0,2.2,0.9,2.8,2c2.2,3.9,4.5,7.8,6.7,11.7c0.3,0.6,0.6,1.1,1.4,1.7c1.6-10,3.2-20.1,4.8-30.4
  c-0.9-0.1-1.7-0.2-2.5-0.2c-7.4,0.2-14.8,0.4-22.2,0.7c-8.6,0.2-7.3,0.8-10.8-6.1c-5.2-10.4-10.1-20.9-15.1-31.4
  c-0.6-1.3-1.2-2.5-2-4.1c-6.1,4.8-12,9.5-18,14.2c0.5,0.9,0.7,1.6,1.1,2.3c5.1,9,10.2,17.9,15.3,26.9c0.6,1,1.1,2,1.5,3
  c1,2.5,0.8,4.9-0.8,7.2c-2.3,3.4-4.6,6.9-6.8,10.4c-0.9,1.4-1.6,3-2.2,4.6c-0.9,2.5-0.5,3.9,1.6,5.7c0.5,0.4,1.1,0.8,1.6,1.2
  c6.5,4.7,13.1,9.4,19.6,14.1c0.8,0.6,1.7,1.1,3,2c-0.3-1.1-0.4-1.7-0.6-2.3c-1.9-5.5-3.7-11-5.7-16.5c-0.6-1.8-0.1-3,1.4-4.1
  c1.2-0.8,2.4-1.8,3.7-2.7c1.8,2.9,3.6,5.7,5.4,8.6c3.3,5.3,6.6,10.7,9.9,16c2.5,4.1,2.4,6.5-1,10.1c-1.8,1.9-3.8,3.6-5.7,5.5
  c-0.8,0.8-1.6,1.8-2.6,3c6.9-0.2,13.4,0.6,19.3-2.8c2-1.2,4-2.4,5.7-4c2.5-2.3,3.1-5.3,1.6-8.5c-0.6-1.3-1.2-2.5-1.9-3.8
  c-5.8-10.6-11.5-21.3-17.3-31.9c-0.3-0.6-0.6-1.1-1-2C548.5,289.5,551.5,289.5,554.6,289.5z"
      />
      <path d="M924,5.3c-2.2-0.1-4.4-0.4-6.6-0.4c-19.9-0.2-39.7-0.4-59.6-0.7c-17.2-0.2-34.3-0.4-51.5-0.7c-9.1-0.1-18.2-0.3-27.3-0.4
  c-17.3-0.3-34.7-0.5-52-0.8c-8.9-0.1-17.8-0.2-26.7-0.4c-17.1-0.2-34.1-0.4-51.2-0.7c-9.4-0.1-18.7-0.2-28.1-0.5
  c-14.8-0.5-29.6-0.5-44.4-0.8c-1.1,0-2.2,0-3.5,0v14.7c5.7,2,11,4.9,15.9,8.3c1.8,1.3,3.4,2.9,4.9,4.6c4.2,4.7,8.3,9.5,12.3,14.3
  c1.6,1.9,2.8,4.2,4.1,6.3c0.7,1.2,1.2,2.5,1.8,3.8c5.3,10.9,4.8,22,0.5,32.9c-1.2,3-3.1,5.8-5.2,8.3c-7,8.6-16.1,13.2-27.3,13.6
  c-5,0.2-9.9-0.4-14.8-1c-10.9-1.4-21-5.4-31.1-9.2c-6.5-2.5-17.3-7.4-23.8-9.8l-3,3.9c-4.1,4.3-19.1,18.8-19.6,21.2
  c-0.2,1,0,2.2,0.5,3.5c11.3,28.7,22.5,57.4,33.7,86.1c0.4,0.9,0.8,1.9,1.3,3c0.8-0.4,1.4-0.8,2-1.2c8.1-5.1,16.2-10.1,24.3-15.2
  c4.3-2.7,4.5-3.3,1.6-7.7c-5.7-8.6-11.5-17.1-17.3-25.7c-4.4-6.6-8.9-13.2-13.3-19.9c-1.1-1.7-2.1-3.5-3.1-5.4
  c-0.3-0.7-0.6-1.4-0.8-2.2c-0.2-1-0.1-2.1,0.5-3c2.3-3.4,7.1,0,7.4,3.2c0,0.3,0.1,0.6,0,0.8c-0.8,3.7,0.7,6.7,2.8,9.6
  c2.9,4.1,5.4,8.3,8.2,12.5c0.6,0.9,1.4,1.6,2.4,2.8c0.3-1.1,0.5-1.7,0.6-2.3c1.1-4.2,2.2-8.3,3.4-12.5c0.5-1.9,1.4-2.7,2.5-2.5
  c1.3,0.3,2,1.3,1.9,3.3c-0.2,5.2-0.4,10.3-0.6,15.5c-0.1,1.8,0,3.6-0.1,5.3c-0.1,4.5,16.8,22,22.4,28.4c0.2,0.3,0.4,0.3,1.1,0.7
  c-1-15.3-1.9-30.4-2.9-45.5c2.1-0.3,3.3,0.5,3.8,2.1c0.7,2.4,1.3,4.9,1.8,7.3c2.6,11.8,5.3,23.6,7.9,35.4c0.7,3.3,1.4,6.6,2.1,9.9
  c0.5,2.4,1.7,4.3,3.7,5.8c10.7,7.6,21.4,15.2,32.1,22.8c0.1,0.1,0.3,0.1,0.8,0.3c-0.2-0.8-0.3-1.5-0.5-2.2
  c-8.8-26.3-17.6-52.6-26.4-78.9c-0.4-1.2-0.7-2.5-1-3.7c3.3-2.1,5.3-1.7,6.9,1.5c1.3,2.6,2.5,5.3,3.6,7.9
  c10.4,23.8,20.7,47.6,31.1,71.4c0.7,1.7,1.5,3.4,2.3,5.5c1.7-0.1,3.5,0,5.2-0.3c10.3-1.5,20.6-3,30.9-4.5c0.2,0,0.3-0.1,0.6-0.3
  c0.2-1.6-0.8-2.8-1.5-4c-6.4-11.1-12.9-22.2-19.4-33.3c-9.8-16.9-19.7-33.9-29.5-50.8c-0.6-1.1-1-2.3-1.6-3.5
  c2.1-0.7,2.7,0.6,3.5,1.6c5.5,7,11,14,16.4,21.1c15.8,20.4,31.5,40.8,47.3,61.2c0.1,0.1,0.2,0.3,0.3,0.4c1.8,2.3,1.9,2.4,4.5,0.8
  c9.8-6.2,19.6-12.5,29.7-18.9c-0.7-0.8-1.3-1.5-2-2.2c-13.1-13.7-26.2-27.3-39.4-41c-13.6-14.1-27.2-28.2-40.9-42.3
  c-0.5-0.5-1-1.1-1.6-1.9c0.6-0.8,1.2-1.6,1.9-2.6c16.7,13.3,33.2,26.6,49.8,39.7c16.6,13.1,32.7,26.9,49.7,39.5
  c11.8-10.1,23.4-19.9,35.1-29.9c-1.5-1.8-3.3-2.5-5-3.4c-6.6-3.5-13.3-7-19.9-10.5c-30.4-16.3-60.7-32.7-91.1-49
  c-3.1-1.7-6.4-3.2-9.4-5c-2.5-1.5-2.7-2.2-2.2-5.4c0.9,0.3,1.8,0.6,2.6,0.9c46.2,18,92.3,36.1,138.5,54.1c1.3,0.5,2.7,0.9,3.9,1.5
  c2.5,1.2,4.6,0.6,6.5-1.4c0.9-0.9,1.8-1.8,2.7-2.6c10.1-9.1,20.2-18.1,30.4-27.2c0.5-0.5,1.2-0.8,2.3-1.5c-1.9-0.6-2.9-0.9-4-1.3
  c-4.6-1.5-9.3-2.5-13.9-4.3c-3.3-1.3-6.8-2-10.2-3c-1.3-0.4-2.6-1-3.9-1.3c-3-0.7-6-1.3-9-2c-4-1-7.9-2.2-11.9-3.2
  c-2.5-0.7-5.1-1.2-7.6-1.8c-0.4-0.1-0.7-0.1-1.1-0.2c-6.9-2-13.9-4-20.8-5.9c-7.6-2.1-15.2-4-22.8-6.1c-18.5-5.1-37-10.3-55.5-15.4
  c-8.2-2.3-16.4-4.5-24.6-6.8c-0.8-0.2-1.7-0.4-2.4-0.9c-0.7-0.5-1.2-1.2-1.7-1.8c0.1-0.2,0.2-0.4,0.3-0.7c0.9,0.1,1.7,0.1,2.6,0.3
  c36.5,6.7,73,13.5,109.5,20.2c29.4,5.4,58.8,10.9,88.2,16.3c0.5,0.1,0.9,0.2,1.4,0.4c3.2,1.2,5.6-0.2,7.9-2.6
  c4.9-5.2,9.8-10.4,14.7-15.5c4.5-4.7,9.2-9.4,13.7-14.1c0.4-0.4,0.8-1,1.5-1.9c-81-7-161.7-13.4-242.3-20.7
  c-0.6-1.9-1.1-3.4-1.8-5.3c0.6,0,1.2,0,1.8,0c13.3,0.6,26.6,1.3,39.9,1.9c10,0.5,20,0.9,30.1,1.4c14.6,0.7,29.2,1.4,43.8,2.1
  c8.4,0.4,16.9,0.8,25.3,1.2c16.6,0.8,33.2,1.6,49.7,2.3c13.9,0.6,27.7,1.2,41.6,1.8c6.2,0.3,12.4,0.6,18.5,1.1
  c4.1,0.4,6.7-1.5,9.2-5.6c3.8-6.3,8.5-11.8,13.9-16.8c1.6-1.4,2.5-3.4,3.8-5c2.1-2.6,4.2-5.2,6.4-7.8c0.4-0.5,0.7-1,1.1-1.5
C924.1,5.7,924.1,5.6,924,5.3z"
      />
    </g>
  </svg>
);
