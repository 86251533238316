// @flow
import React from 'react';
import AbilitiesListStyle from './AbilitiesList.styles';
import { H } from '../../../styles';
import type { SpecialistListType } from '../../../types';

const AbilitiesList = ({ specialists, onClick }: {
  specialists: SpecialistListType,
  onClick: Function
}) => {
  if (specialists) {
    return (
      <AbilitiesListStyle>
        <H as="h5">Click to Edit</H>
        <ul className="scroller">
          {Object.keys(specialists).map((key) => (
            <li key={key}>
              <button
                type="button"
                onClick={() => onClick(key)}
              >
                {specialists[key].name}
              </button>
            </li>
))}
        </ul>
        {`Total: ${Object.keys(specialists).length} specialists`}
      </AbilitiesListStyle>
    );
  }

  return (<p>No Abilities</p>);
};

export default AbilitiesList;
