// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <path d="M738.63,368.94l12.79,7.58c-10.2-42.69-20.35-83.67-29.82-105.15l7.84-1.61c-15-35.56-29.4-66.68-42.06-85.27l8.61-2.79
	c-20.03-35.02-40.26-69.47-65.43-89.65c-1.62-3.4,2.5-6.09,10.54-8.3c-21.54-21.19-45.23-38.43-71.67-50.58
	c25.46,39.45,43.36,76.82,54.17,114.05c0.58-0.9,1.3-1.52,2.16-1.8c4.7-1.53,11.84,7.44,15.95,20.04
	c4.11,12.6,3.62,24.06-1.08,25.59c-2.38,0.77-5.38-1.14-8.29-4.84c7.69,48.61,3.64,98.13-11.16,152.81
	c42.35-37.31,20.97-2.28,16.22,24.23c11.87-16.74,20.68-18.55,28.75-14.09c0,0,18.12,13.63-6.25,39.06
	c-67.24,40.39-27.89-6.51-27.89-6.51c-39.73,7.32-65.15-9.98-85.62-19.13c16.18,14.95,26.2,29.85,25.35,43.79l-9.7-0.41
	C537.62,346.4,518.07,282.04,500,219c-5.39,45.23-11.98,87.35-19.7,126.58c2.17-2.42,4.59-3.67,6.88-3.22
	c5.15,1.02,7.67,10.19,5.63,20.48s-7.86,17.81-13,16.8c-2.42-0.48-4.25-2.77-5.31-6.12c-5.43,24.88-11.33,48.53-17.68,71.02L443,448
	c-3.64-13.48,3.17-30.1,16-48c-18.2,13.09-39.61,35.15-80,36c0,0,48,38-26,12c-29-20-14-37-14-37c7-6,16-6,31,8c-10-25-38-55,11-27
	c-25.53-50.57-39.48-98.25-41.75-147.42c-2.11,4.22-4.66,6.7-7.15,6.42c-4.92-0.55-7.7-11.68-6.22-24.85
	c1.48-13.17,6.67-23.4,11.58-22.85c0.9,0.1,1.73,0.56,2.48,1.32C343.02,165.99,353.02,125.77,370,82c-23.45,17.24-43.17,38.9-60,64
	c8.32,0.54,12.9,2.35,12,6c-20.58,24.84-33.44,62.66-46,101l9,1c-8.66,20.76-16.48,54.14-24,92h8c-4.95,22.94-6.62,65.13-8,109
	l11-10c5.7,57.29,22,127,62,200c-22-91,15-122,60-131c16.85,7.33,23.36,20.36,26.53,35.61c-7.19,17.15-14.72,33.41-22.58,48.82
	c1.44-0.41,2.81-0.39,4.03,0.14c4.8,2.12,5.35,11.41,1.22,20.76c-4.13,9.35-11.36,15.21-16.16,13.09c-2.18-0.96-3.47-3.42-3.86-6.68
	C356.82,671.67,327.23,709.25,295,740c41.26-27.71,82.92-51.02,125-69.43c-0.04-0.05-0.09-0.1-0.12-0.15
	c-2.81-4.43,1.9-12.46,10.53-17.94c8.63-5.48,17.9-6.32,20.71-1.9c1.24,1.96,1.01,4.62-0.37,7.45
	c16.99-6.42,34.05-12.02,51.18-16.78c16,8.33,26.26,53.19-8.93,33.75c5.31,23.26,5.05,44.01,0,63c4.26,1.36,8.05,3.16,11.38,5.38
	C468.15,768.25,440.25,776.54,413,761c11.36,45.64-15.34,99.56-49,155c27.39-31.05,54.69-60.21,81.9-84.94
	c-1.29-0.11-2.31-0.52-2.94-1.3c-3.07-3.77,3.64-14.32,14.99-23.55c11.35-9.24,23.04-13.67,26.11-9.9c0.56,0.69,0.79,1.6,0.73,2.69
	c12.09-8.86,24.16-16.62,36.21-23c0.62-0.25,1.26-0.31,1.88-0.21c0.75,5.07,1.03,10.57,0.87,16.45
	C503.59,847.26,466.99,906.35,424,967c87.78-88.43,171.61-166.01,244-222c-32.6,17.49-62.17,28.93-85.4,29.59
	C566.3,771.6,556.7,763.8,554,751c5.43-22,21-31,21-8c21.54-29.06,36.64-30.12,46-7l35-102c-36.37,44.47-69.45,70.42-96,50
	c-20,5-27-15-1-21c-23.97-5.99,22.87-31.93,46.9-39.96c0.06,0,0.13-0.01,0.19-0.02c-2.54-1.49-4.09-3.41-4.09-5.52
	c0-4.69,7.61-8.5,17-8.5s17,3.81,17,8.5c0,1.59-0.89,3.07-2.4,4.34c32.19-0.49,64.65,2.16,97.4,8.16
	c-10.8-6.29-21.08-13.32-30.9-20.98c0.08,2-0.43,3.73-1.64,4.94c-3.45,3.45-11.16,1.34-17.21-4.71c-6.05-6.05-8.16-13.76-4.71-17.21
	c0.62-0.62,1.37-1.05,2.23-1.32c-11.48-10.8-22.28-22.54-32.44-35.1c-0.17,0.72-0.5,1.34-1,1.8c-2.43,2.25-7.9,0.31-12.22-4.35
	c-4.32-4.65-5.86-10.26-3.43-12.51c1.05-0.98,2.68-1.16,4.54-0.68c-6.82-9.32-13.34-19.04-19.59-29.07
	c-1.39-19.3,0.41-37.01,18.43-49.69c45.89-0.26,88.38,22.64,85.19,116.21C742.7,497.77,744.6,426.2,738.63,368.94z M584.71,571.68
	c-0.49,4.64-6.23,9.52-14.08,11.55c-9.04,2.34-17.46,0.03-18.8-5.16c-0.21-0.81-0.21-1.65-0.07-2.49
	C514.54,581.9,477.58,594.59,443,613c32.38-64.66,55.75-139.18,71-215c9.84,26.73,21.49,54.32,35.05,80.88
	c0.35-0.48,0.76-0.88,1.27-1.17c3.22-1.86,8.4,1.1,11.58,6.6s3.14,11.47-0.07,13.33c-0.73,0.42-1.57,0.59-2.46,0.54
	C574.2,524.7,591.04,549.66,610,571C601.61,570.88,593.17,571.12,584.71,571.68z"
    />
  </svg>
);
