// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="skull_copy"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 831.6 902.9"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path d="M380.2,0c-2.7,0-5.5,0-8.2,0.1v77.1l-45,4.5v65.2c-22.3,3.5-43.7,9.4-64.1,17.5l-34.4-55.2c-11,3-25.7,8.2-41.5,17.5
  c-16.4,9.7-28.4,20.5-36.5,29c0,0,22.6,35.9,34.1,53.7c-17.9,14.2-34.3,30.3-48.7,48L77,226.6c-8.1,8.7-17.7,20.8-26.5,36.5
  c-9.2,16.5-14.4,31.7-17.5,43.5c18.3,11,36.6,22.1,55,33.1c-7.8,20-13.5,40.9-17,62.7l-68.5,5.7c-1.4,12.1-2.4,25.5-2.5,40
  c-0.1,17.1,0.9,32.7,2.5,46.5c22.6,1.1,45.3,2.2,67.9,3.3c3.5,23.5,9.7,46.1,18.2,67.6L33,594.7c2.5,10.4,6.7,23.5,14,37.5
  c10.2,19.7,22.6,33.8,32,43l54.6-31.7c14.3,18,30.5,34.4,48.3,48.8l-33,55.4c9.6,7.5,20.6,15.3,33,23c16.4,10.1,32,17.9,46,24
  c11-18.7,22.1-37.4,33.1-56c21.1,8.4,43.3,14.6,66.4,18.3v60.8c9.7,4.1,24.2,8.4,41,6c1.2-0.2,2.4-0.4,3.5-0.6v79.8
  c2.7,0,5.4,0.1,8.2,0.1c249.3,0,451.4-202.1,451.4-451.4S629.5,0,380.2,0z M711.7,605.9c-10.5,22.6-23.3,44-38,63.8L615,639.6
  c-8.6,4.9-17.9,14.7-28.5,24c-11.6,10.2-21.3,17.4-27.5,26c4.1,7.5,8.2,15.1,12.3,22.7c6.8,12.6,14.9,27.8,20.2,38
  c-21.1,15-43.8,27.8-67.9,38.1L490,734.6c-12.4,2.5-25.5,6.5-39,10c-12.7,3.3-24.7,5.9-36,9.5c0,20.6,0,41.2,0,61.8
  c-11.6,1.1-23.3,1.7-35.2,1.7c-1.3,0-2.5,0-3.8,0c0-41.7,0-83.3,0-125l-8,2.1v-22.6h-7.6l-0.9,24.2h-19.1l-1.1-23.6l-7.9,1.1
  l-2.2,22.5h-23.6l-2.2-23.6l-11.6,0.1l-6.5,23.4h-13.5c0,0,4.8-67.3,10-76.2c6.9-11.7-36.7,11-50.4-0.2
  c-7.6-6.2-63.4-81.5-22.3-128.9c10.2-11.8-40.2-99.6-13.6-177.4c31.2-91.1,123.4-105.3,163.3-107.2c0,0,3.3-0.2,9.2-0.2
  c0,0,0,0,0.1,0v359.1c0.6-0.3,1.2-0.7,1.8-1c3.3,1.9,6.3,3.8,8.5,6c17.3,17.1,17.8,40.7,17.1,52.1c-0.1,1.6-2,2.2-3,1
  c-2.4-3-5.6-6.5-10-9.7c-4.7-3.4-9.1-5.6-12.6-6.8c-0.6,0.2-1.2,0.5-1.8,0.7v64.6h7.9l0.9,24.2h19.1l1.1-23.6l7.9,1.1l2.2,22.5
  h23.6l2.2-23.6l11.6,0.1l6.5,23.4h13.5c0,0-4.8-67.3-10-76.2c-6.9-11.7,36.7,10.9,50.4-0.2c7.6-6.2,63.4-81.5,22.3-128.9
  c-10.2-11.9,40.1-99.7,13.6-177.4c-31.2-91.1-123.4-105.3-163.3-107.2V86c0.8,0,1.6,0,2.4,0c10.2,0,20.3,0.4,30.3,1.2l4.9,59.4
  c9.2,3.9,20.5,5.7,32,9c16.2,4.7,30.1,9.4,43,11c12-16.7,24-33.5,36.1-50.2c21.3,9.3,41.6,20.6,60.5,33.6L559,208.6
  c7.1,9.4,17,18.9,28,29.5c9.8,9.4,18.3,18.2,27,24.5c20.7-8.4,41.4-16.9,62.2-25.3c14.2,19.6,26.5,40.6,36.6,62.8L655,334.6
  c2.1,11.6,6,24.4,10,38c4.6,15.4,8.8,29.1,14,41c21.6,0,43.1,0,64.7,0c1.3,12.6,2,25.3,2,38.2c0,13.1-0.7,26.1-2,38.8H680
  c-5.4,9.1-9.2,21.8-14,36c-5.7,17-10.1,31.2-11,43C673.9,581.7,692.8,593.8,711.7,605.9z M395.5,532.9c10.8-5.6,24-11.5,39.5-16.3
  c19.2-6,36.5-8.8,50.4-10c0.7-0.1,1.3,0,2,0.3c11.5,4.2,15.2,11.1,16.2,13.1c6.5,13.6-1.9,35.4-24,49.3c-0.6,0.4-1.2,0.6-1.9,0.7
  c-10.1,1.4-34.9,3.4-60.2-10.8c-10.5-5.9-18.2-13-23.5-19C392.1,537.8,392.7,534.3,395.5,532.9z"
      />
      <path d="M344.2,622.4c0.1,1.6,2,2.2,3,1c2.4-3,5.6-6.5,10-9.7c3.9-2.8,7.7-4.8,10.8-6.1v-42.2c-2.6,1.5-4.9,3.2-6.7,5
  C344,587.4,343.5,610.9,344.2,622.4z"
      />
      <path d="M341.5,532.9c-10.8-5.6-24-11.5-39.5-16.3c-19.1-6-36.4-8.8-50.4-10c-0.7,0-1.3,0.1-2,0.3c-11.6,4.2-15.3,11.1-16.2,13.1
  c-6.5,13.6,1.9,35.3,23.9,49.3c0.5,0.4,1.2,0.6,1.9,0.7c10.1,1.4,35,3.4,60.2-10.8c10.5-5.9,18.2-13,23.5-19
  C344.7,537.8,344.2,534.3,341.5,532.9z"
      />
    </g>
  </svg>

);
