import { assign, spawn } from 'xstate';
import { useMachine } from '@xstate/react';
import managerMachine from './managerMachine';
import createTeamMachine from './teamMachine';
import {
  APP, ACTIONS, SERVICES, GUARDS,
} from '../../../constants';
import { getLocalState, setLocalState } from '../teamHandlers';
import { useFirebase } from '../../Firebase';
import { useAuth } from '../../Session';

const defaultContext = {
  actors: {},
  teams: null,
  factions: null,
  specialists: null,
  abilities: null,
  wargear: null,
  team: null,
};

export default (context = defaultContext) => {
  const firebase = useFirebase();
  const { uid } = useAuth();
  return useMachine(managerMachine, {
    context: { ...defaultContext, ...context },
    actions: {
      [ACTIONS.AUTO_SELECT]: assign((ctx, event) => {
        const newTeam = event.data;
        const team = spawn(createTeamMachine({ ...ctx, team: newTeam }, firebase, uid));

        return {
          actors: {
            ...ctx.actors,
            [newTeam.id]: team,
          },
          team,
        };
      }),
      [ACTIONS.SELECT]: assign((ctx, event) => {
        let team = ctx.actors[event.id];

        if (team) {
          return {
            ...ctx,
            team,
          };
        }

        team = spawn(createTeamMachine({ ...ctx, team: ctx.teams[event.id] }, firebase, uid));

        return {
          actors: {
            ...ctx.actors,
            [event.id]: team,
          },
          team,
        };
      }),
      [ACTIONS.SET_FACTIONS]: assign({
        factions: (_, event) => event.data,
      }),
      [ACTIONS.SET_ABILITIES]: assign({
        abilities: (_, event) => event.data,
      }),
      [ACTIONS.SET_WARGEAR]: assign({
        wargear: (_, event) => event.data,
      }),
      [ACTIONS.SET_SPECIALISTS]: assign({
        specialists: (_, event) => event.data,
      }),
      [ACTIONS.SET_TEAMS]: assign({
        teams: (_, event) => event.data,
      }),
      [ACTIONS.ADD_TEAM]: assign((ctx, event) => {
        const team = event.data;
        return {
          teams: {
            ...ctx.teams,
            [team.id]: team,
          },
        };
      }),
      [ACTIONS.DELETE_TEAM]: assign((ctx, event) => {
        const team = event.data;
        const teams = Object.keys(ctx.teams).reduce((updatedTeams, teamId) => {
          if (teamId !== team.id) {
            return {
              ...updatedTeams,
              [teamId]: ctx.teams[teamId],
            };
          } return { ...updatedTeams };
        }, {});
        return {
          teams,
        };
      }),
      [ACTIONS.COPY_TEAM]: assign((ctx, event) => {
        const { team, id } = event.data;
        return {
          teams: {
            ...ctx.teams,
            [id]: team,
          },
        };
      }),
      [ACTIONS.UPDATE_TEAM]: assign((ctx, event) => {
        const team = event.data;
        return {
          teams: {
            ...ctx.teams,
            [team.id]: {
              ...team,
            },
          },
        };
      }),
    },
    services: {
      [SERVICES.GET_FACTIONS]: () => firebase.getFactions().orderByChild('name').once('value')
        .then((snapshot) => {
          const factions = snapshot.val();
          if (factions) {
            const localState = getLocalState(APP.STORAGE_KEY) || {};
            setLocalState(APP.STORAGE_KEY, { ...localState, ...factions });
          }

          return factions;
        }),
      [SERVICES.GET_ABILITITES]: () => firebase.getByNamespace('abilities').orderByChild('name').once('value')
        .then((snapshot) => snapshot.val()),
      [SERVICES.GET_WARGEAR]: () => firebase.getByNamespace('wargear').orderByChild('name').once('value')
        .then((snapshot) => snapshot.val()),
      [SERVICES.GET_SPECIALISTS]: () => firebase.getByNamespace('specialists').orderByChild('name').once('value')
        .then((snapshot) => snapshot.val()),
      [SERVICES.GET_TEAMS]: () => firebase.getUserTeams(uid).orderByChild('created').once('value')
        .then((snapshot) => snapshot.val()),
      [SERVICES.GET_LOCAL_STORAGE_FACTIONS]: () => {
        async function hasLocalStorage() {
          return getLocalState(APP.STORAGE_KEY);
        }
        return hasLocalStorage();
      },
    },
    guards: {
      [GUARDS.HAS_LOCAL_FACTIONS]: (_, event) => event.data,
    },
  });
};
