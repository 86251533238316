// @flow
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../Session';
import { useFirebase } from '../../Firebase';
import { FormStyles } from '../../../styles';
import Spinner from '../../Spinner';

const formatDate = (now) => {
  const d = new Date(now);
  const month = d.getMonth() < 10 ? `0${d.getMonth()}` : d.getMonth();
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  return `${d.getFullYear()}-${month}-${day}`;
};

const UserList = ({ users }) => {
  const usersMarkup = Object.keys(users).map((key) => <li key={key}>{users[key].username}</li>);

  return (
    <div>
      <h1>Users</h1>
      <hr />
      <ul>
        {usersMarkup}
      </ul>
    </div>
  );
};


const Settings = () => {
  const { uid } = useAuth();
  const firebase = useFirebase();
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    firebase.getUser(uid)
      .then((snapshot) => {
        setUser(snapshot.val());
      });

    firebase.getUsers()
      .then((snapshot) => {
        setUsers(snapshot.val());
      });
  }, []);

  if (!user) return <Spinner label="Loading user data..." />;

  return (
    <FormStyles>
      {users && <UserList users={users} />}
      <label htmlFor="true">
        <span>Favourite Faction</span>
        selectbox
        {/* <input
          type="text"
          value={user ? user.firstName : ''}
          placeholder="First Name"
        /> */}
      </label>
      <label htmlFor="true">
        <span>Username</span>
        <p>{user && user.username}</p>
      </label>
      <label htmlFor="true">
        <span>Registered email</span>
        <p>{user && user.email}</p>
      </label>
      <label htmlFor="true">
        <span>Account created</span>
        <p>{user && formatDate(user.created)}</p>
      </label>
      <div>
        <button type="submit">
          Submit
        </button>
      </div>
      {/* {error && <p className="error">{error.message}</p>} */}
    </FormStyles>
  );
};

export default Settings;
