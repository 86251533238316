// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g id="Layer_3_1_" />
    <g id="Layer_1">
      <g>
        <path d="M232.2,366.3v74.9h131.6c-4.5-23.1-6.8-49.3-1.2-74.9H232.2z" />
        <path d="M232.2,552.6h133.1c0-13.4,3.7-26.7,13.6-38.2c3.5-4-0.7-17.6-6.1-36.7H232.2V552.6z" />
        <path d="M816.5,366.3H685.1c5.6,25.6,3.3,51.8-1.2,74.9h132.6V366.3z" />
        <path d="M816.5,477.6H675c-5.4,19.1-9.5,32.7-6,36.7c9.9,11.5,13.7,24.8,13.6,38.2h133.9V477.6z" />
        <path d="M531.7,270c0,0-2.9-0.2-7.7-0.2c-0.1,0-0.1,0-0.1,0s-0.1,0-0.1,0c-4.9,0-7.7,0.2-7.7,0.2c-33.4,1.6-110.7,13.5-136.9,89.8
			c-22.3,65.2,20,138.8,11.4,148.7c-34.4,39.8,12.4,102.9,18.7,108.1c11.5,9.4,48.1-9.6,42.3,0.2c-4.4,7.5-8.4,63.9-8.4,63.9h11.3
			l5.4-19.6l9.7-0.1l1.9,19.8h19.8l1.9-18.8l6.6-0.9l0.9,19.8h16l0.7-20.3h13l0.7,20.3h16l0.9-19.8l6.6,0.9l1.9,18.8h19.8l1.9-19.8
			l9.7,0.1l5.4,19.6h11.3c0,0-4-56.4-8.4-63.9c-5.8-9.8,30.8,9.1,42.3-0.2c6.4-5.2,53.1-68.3,18.7-108.1
			c-8.6-9.9,33.6-83.6,11.4-148.7C642.5,283.5,565.2,271.6,531.7,270z M502.6,549.7c-4.5,5-10.9,11-19.7,15.9
			c-21.1,11.9-42,10.2-50.4,9c-0.6-0.1-1.1-0.2-1.6-0.6c-18.5-11.7-25.5-29.9-20-41.3c0.8-1.7,3.9-7.5,13.6-11
			c0.6-0.2,1.1-0.2,1.7-0.2c11.7,1.1,26.2,3.4,42.2,8.4c13,4,24.1,9,33.1,13.7C503.8,544.8,504.3,547.8,502.6,549.7z M546.8,618.6
			c-0.1,1.3-1.7,1.8-2.5,0.8c-2-2.5-4.7-5.5-8.4-8.1c-3.9-2.9-7.6-4.7-10.6-5.7c-2.9,1.1-6.7,2.9-10.6,5.7c-3.7,2.7-6.4,5.6-8.4,8.1
			c-0.8,1-2.4,0.5-2.5-0.8c-0.6-9.6-0.2-29.3,14.3-43.6c1.9-1.9,4.3-3.5,7.2-5c2.8,1.6,5.3,3.2,7.2,5
			C547,589.3,547.4,609.1,546.8,618.6z M596.1,567.6c-12,0-21.7-9.7-21.7-21.7c0-6.7,3.1-12.7,7.8-16.7c6.4-1.9,12.5-3.4,18.2-4.6
			c9.9,2,17.3,10.8,17.3,21.2C617.8,557.9,608.1,567.6,596.1,567.6z"
        />
        <path d="M271.1,249.2c12.3,1,47,34.3,62.8,52.9c12.5,14.7,42.8,47.1,42.8,47.1l20-34c-9.2-15.5-53.2-39.6-74.9-75.5
			c-0.8-1.3-0.4-7.3-2.2-14.8c-0.3-1.2-0.8-3.3-1.5-5.9c6.1-5.4,9.8-5.1,12.2-13.9c6.1-21.8-4.7-43.9-24-49.3
			c-19.3-5.4-39.9,8-46,29.8c-2.2,7.9-1,8.1,0.8,15.2c-10,5.7-20.8,10.9-32,10.7C208.3,211.3,224.2,222,271.1,249.2z"
        />
        <path d="M783.3,682c2.1-10.7-5.2-21.1-16.2-23.2c-7.5-1.4-15.4,5.1-19.3,6.6c-4.5-1.1-37.5-27.9-83.8-78.8l-18.7,29.7
			c48.1,43.7,46.2,31.9,77.7,66.6c1.2,2.8,4.4,5.8,3.2,8.9c-1.2,3.1-6.1,7.2-7,12c-2.3,11.8,5.7,23.2,17.8,25.5
			c12.1,2.3,29.3-2.3,31.6-14c1-5.2-0.9-10.4-3.4-14.7C768.9,703.4,781.9,699.8,783.3,682z"
        />
        <path d="M726.7,239.7c-21.7,35.9-65.7,60-74.9,75.5l20,34c0,0,30.3-32.4,42.8-47.1c15.8-18.6,50.6-51.9,62.8-52.9
			c46.9-27.2,62.8-38,41.9-37.7c-11.2,0.2-22-5.1-32-10.7c1.8-7.1,2.9-7.3,0.8-15.2c-6.1-21.8-26.7-35.2-46-29.8
			c-19.3,5.4-30.1,27.4-24,49.3c2.4,8.8,6.1,8.5,12.2,13.9c-0.7,2.6-1.3,4.7-1.5,5.9C727.1,232.4,727.5,238.4,726.7,239.7z"
        />
        <path d="M401.8,616.3l-18.7-29.7c-46.3,50.9-79.3,77.7-83.8,78.8c-3.8-1.5-11.8-8-19.3-6.6c-11,2.1-18.2,12.5-16.2,23.2
			c1.4,17.8,14.4,21.3,18,18.6c-2.4,4.3-4.4,9.5-3.4,14.7c2.3,11.8,19.5,16.3,31.6,14c12.1-2.3,20.1-13.7,17.8-25.5
			c-0.9-4.9-5.8-8.9-7-12c-1.2-3.1,2-6.1,3.2-8.9C355.6,648.2,353.7,660,401.8,616.3z"
        />
        <path d="M860.3,69v0.2H524.5h-0.1h-0.1H188.3V69h-21.7v86.7h15.5c0.1-14,1.1-26.7,6.2-32.4v0c5.5-0.7,122.3-37.3,174.8,38.7
			c21.2,29.7,23.2,61.5,23.2,75.9l0.4,59.1c3,2.6,5.8,5,8.1,7.3c37.4-40.3,92.7-48.2,120.4-49.5c0,0,3-0.2,8.4-0.2
			c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c5.3,0,8.4,0.2,8.4,0.2c27.8,1.3,83.5,9.2,120.9,49.9c2.4-2.4,5.3-5,8.6-7.8l0.4-59.1
			c0-14.4,2-46.1,23.2-75.9c52.5-76,169.3-39.4,174.8-38.7v0c5.1,5.7,6,18.3,6.2,32.4H882V69H860.3z"
        />
        <path d="M232.2,663.9h31.7c2.7-5.6,7.9-10,14.6-11.2c7.5-1.4,15.4,5.1,19.3,6.6c4.2-1,33.6-24.7,75.5-69.8
			c-0.1-0.2-0.1-0.3-0.2-0.5H232.2V663.9z"
        />
        <path d="M816.5,663.9V589H674.9c42.1,45.4,71.8,69.3,76,70.3c3.8-1.5,11.8-8,19.3-6.6c6.7,1.3,12,5.6,14.6,11.2H816.5z" />
        <path d="M860.3,861.6v0.2c-83.6,6.2-141.1-13.5-170.2-48c-30.2-35.8-27.9-88.3-27.9-88.3l-2.6-61.6h34.9
			c-8.8-6.6-20.4-15.8-38.6-32.3c0,0-5.8-1-7.4,0.3c-12.5,10.1-49.2-10.5-42.9,0.2c4.7,8.1,9.1,55.5,9.1,55.5H433
			c0,0,4.4-47.4,9.1-55.5c6.3-10.7-30.4,10-42.9-0.2c-1.5-1.3-8.2-0.3-8.2-0.3c-18.2,16.5-29.8,25.7-38.6,32.3H389l-2.6,61.6
			c0,0,2.3,52.5-27.9,88.3c-29.1,34.5-86.6,54.2-170.2,48v-0.2c-5.1-5.7-6-18.3-6.2-32.4h-15.5V916h21.5h0.1h335.9h0.1h0.1h335.9
			h0.1H882v-86.7h-15.5C866.4,843.3,865.4,855.9,860.3,861.6z"
        />
        <circle cx="596" cy="546" r="15" />
      </g>
    </g>
  </svg>
);
