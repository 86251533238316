// @flow
import type { WargearListType, WargearType } from '../../../../types';

export const getWargearByName = (wargear: WargearListType,
  name: string): WargearType | null => {
  const id = Object.keys(wargear).find((key) => wargear[key].name === name);

  if (!id) return null;

  return wargear[id];
};


export const updateUnitProperties = (e: any,
  formValues: any, specialist: string, type: string): Array<string> => {
  if (e.target.checked) {
    const newFormValues = { ...formValues };

    if (!newFormValues[type]) {
      newFormValues[type] = [];
    }

    return [...newFormValues[type], specialist];
  }

  const newFormValues = { ...formValues };
  return newFormValues[type].filter((s) => s !== specialist);
};

export const setActiveOptions = (wargear: WargearListType,
  activeOptions: Array<WargearType> | null,
  selectedOption: string,
  index: number): Array<WargearType> => {
  const newActiveOptions = activeOptions ? [...activeOptions] : [];
  const selectedWargear = getWargearByName(wargear, selectedOption);

  if (!activeOptions) return selectedWargear ? [selectedWargear] : newActiveOptions;

  if (selectedWargear && activeOptions[index]) {
    newActiveOptions[index] = selectedWargear;

    return newActiveOptions;
  }

  if (selectedWargear) {
    return [...newActiveOptions, selectedWargear];
  }

  if (selectedOption === '') {
    return newActiveOptions.filter((option, i) => i !== index);
  }

  return newActiveOptions;
};
