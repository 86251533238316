// @flow
import React from 'react';
import useAdminMachine from './machine';
import { useFirebase } from '../Firebase';
import { Content } from '../../styles';
import Faction from './Faction';
import Wargear from './Wargear';
import Abilities from './Abilities';
import Specialists from './Specialists';
import FactionUnits from './FactionUnits';
import type { SpecialistListType, AbilitiesListType, WargearListType } from '../../types';

const createSpecialistList = (specialists: SpecialistListType): Array<string> => Object
  .keys(specialists).reduce((array, key) => [...array, specialists[key].name], []);

const createAbilitiesList = (abilities: AbilitiesListType): Array<string> => Object
  .keys(abilities).reduce((array, key) => [...array, abilities[key].name], []);

const AdminStart = () => {
  const firebase = useFirebase();
  const [current, send] = useAdminMachine(firebase);
  const {
    factions, wargear, abilities, specialists,
  } = current.context;
  let content = '';
  const specialistsList = specialists ? createSpecialistList(specialists) : [];
  const abilitiesList = abilities ? createAbilitiesList(abilities) : [];

  if (current.matches('gettingFactions')) content = <p>Loading Factions...</p>;
  if (current.matches('faction.addFactionForm')) content = <Faction firebase={firebase} send={send} factions={factions} />;

  if (current.matches('gettingWargear')) content = <p>Loading Wargear...</p>;
  if (current.matches('wargear.addWargearForm')) content = <Wargear firebase={firebase} send={send} wargear={wargear} />;

  if (current.matches('gettingAbilities')) content = <p>Loading Abilities...</p>;
  if (current.matches('abilities.addAbilityForm')) content = <Abilities firebase={firebase} send={send} abilities={abilities} />;

  if (current.matches('gettingSpecialists')) content = <p>Loading Specialists...</p>;
  if (current.matches('specialists.addSpecialistForm')) content = <Specialists firebase={firebase} send={send} specialists={specialists} />;

  if (current.matches('factionUnits.addFactionUnitForm')) {
    content = (
      <FactionUnits
        current={current}
        firebase={firebase}
        send={send}
        factions={factions}
        specialists={specialistsList}
        abilities={abilitiesList}
        wargear={wargear}
      />
    );
  }

  return (
    <Content title="Admin" pt>
      <button
        type="button"
        onClick={() => send('ADD_FACTION')}
        disabled={!factions
        || current.matches('faction.addFactionForm')}
      >
        Faction
      </button>
      <button
        type="button"
        onClick={() => send('ADD_WARGEAR')}
        disabled={!wargear
        || current.matches('wargear.addWargearForm')}
      >
        Wargear
      </button>
      <button
        type="button"
        onClick={() => send('ADD_ABILITY')}
        disabled={!abilities
        || current.matches('abilities.addAbilityForm')}
      >
        Abilities
      </button>
      <button
        type="button"
        onClick={() => send('ADD_SPECIALIST')}
        disabled={!specialists
        || current.matches('specialists.addSpecialistForm')}
      >
        Specialists
      </button>
      <button
        type="button"
        onClick={() => send('ADD_FACTION_UNIT')}
        disabled={!factions
        || current.matches('factionUnits.addFactionUnitForm')}
      >
        Faction Units
      </button>
      {content}
    </Content>
  );
};

export default AdminStart;
