// @flow
import React from 'react';
import { SelectBox } from '../../common';
import { updateWargear } from '../FactionUnits.utils';
import { setActiveOptions } from './Forms.utils';
import type { FactionUnitType, WargearListType } from '../../../../types';
import FormStyles from './FormStyles';

const Form = ({
  wargear,
  wargearList,
  formValues,
  setFormValues,
  optionsCount,
  activeOptionsCount,
  disabled,
}: {
  wargear: WargearListType,
  wargearList: Array<string>,
  formValues: FactionUnitType,
  setFormValues: Function,
  optionsCount: number,
  activeOptionsCount: number,
  disabled: boolean,
}) => (
  <FormStyles>
    {formValues && formValues.activeOptions && formValues.activeOptions.map((activeOption, i) => (
      <SelectBox
        key={activeOption.id}
        name={`Active Option ${i}`}
        data={wargearList}
        onSelect={(e) => setFormValues({
 ...formValues,
 activeOptions: setActiveOptions(wargear,
  formValues.activeOptions || null, e.target[e.target.selectedIndex].value, i),
})}
        selectedData={formValues && formValues.activeOptions ? formValues.activeOptions[i].name : ''}
        disabled={disabled}
      />
))}
    <SelectBox
      name={`Active Option ${activeOptionsCount}`}
      data={wargearList}
      onSelect={(e) => setFormValues({
 ...formValues,
 activeOptions: setActiveOptions(wargear,
  formValues.activeOptions || null, e.target[e.target.selectedIndex].value, activeOptionsCount),
})}
      selectedData=""
      disabled={disabled}
    />
    <p className="error">Please add active options to options as well</p>
    {formValues && formValues.options && formValues.options.map((option, i) => (
      <SelectBox
        key={option}
        name={`Option ${i}`}
        data={wargearList}
        onSelect={(e) => setFormValues({
 ...formValues,
            options: updateWargear(formValues, 'options', e.target[e.target.selectedIndex].value, i),
})}
        selectedData={formValues && formValues.options ? formValues.options[i] : ''}
        disabled={disabled}
      />
))}
    <SelectBox
      name={`Option ${optionsCount}`}
      data={wargearList}
      onSelect={(e) => setFormValues({
 ...formValues,
          options: updateWargear(formValues, 'options', e.target[e.target.selectedIndex].value, optionsCount),
})}
      selectedData=""
      disabled={disabled}
    />
  </FormStyles>
);

export default Form;
