// @flow
import React from 'react';
import type { WargearListType, WargearType } from '../../../types';
import WargearStyles from './WargearStyles';

const SimpleProfile = ({ item }: { item: WargearType }) => (
  <tr>
    <td className="left">{item.name}</td>
    <td className="small">{item.range && `${item.range}"`}</td>
    <td className="limited">{item.type}</td>
    <td className="small">{item.s}</td>
    <td className="small">{item.ap}</td>
    <td className="small">{item.d}</td>
    <td className="left">{item.abilities && item.abilities}</td>
  </tr>
);

const WargearItem = ({ item }: { item: WargearType }) => (item.profile
  ? Object.keys(item.profile)
    .map((key) => <SimpleProfile key={key} item={item.profile[key]} />)

  : <SimpleProfile item={item} />);

const WargearTable = ({ children }: { children: any }) => (
  <WargearStyles>
    <thead>
      <tr>
        <th>Weapon</th>
        <th>Range</th>
        <th>Type</th>
        <th>S</th>
        <th>AP</th>
        <th>D</th>
        <th>Abilities</th>
      </tr>
    </thead>
    <tbody>
      {children}
    </tbody>
  </WargearStyles>
);

const Wargear = ({ wargear }: { wargear: WargearListType }) => {
  const wargearList = Object.keys(wargear).reduce((list, key) => [...list, <WargearItem key={key} item={wargear[key]} />], []);
  return (
    <WargearTable>
      {wargearList}
    </WargearTable>

  );
};

export default Wargear;
