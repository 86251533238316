// @flow
import React, { useState } from 'react';
import { DisplayUnitStyles, UnitListStyles } from './DisplayTeamStyles';
import Abilities from './Abilities';
import Wargear from './Wargear';
import UnitList from './UnitList';
import type { TeamType, UnitType } from '../../../types';
import Content from '../../../styles/Content';
import Grid from '../../../styles/Grid';
import H from '../../../styles/Headings';
import UnitStats from './UnitStats';

const DisplayUnit = ({ unit }: { unit: UnitType }) => (
  <DisplayUnitStyles>
    <H as="h3" bottomBorder>{unit.name}</H>
    <H as="h4">{unit.type}</H>
    {/* TODO: Specialist */}
    <br />
    <UnitStats stats={unit.stats} />
    <Wargear wargear={{ ...unit.wargear, ...unit.options }} />
    {unit.abilities && <Abilities abilities={unit.abilities} />}
  </DisplayUnitStyles>
);

const DisplayTeam = ({ team, units }: { team: TeamType, units: { [string]: UnitType } }) => {
  const getFirstUnitID = Object.keys(units)[0];
  const [selectedUnit, setSelectedUnit] = useState(getFirstUnitID);

  const onSelectUnit = (id) => {
    setSelectedUnit(id);
  };

  return (
    <Content title={`${team.name} - ${team.cost}pts`} wide>
      <Grid columns="275px 1fr">
        <UnitListStyles>
          <UnitList units={units} onSelectUnit={onSelectUnit} selectedUnit={selectedUnit} />
        </UnitListStyles>
        {selectedUnit && <DisplayUnit unit={units[selectedUnit]} />}
      </Grid>
    </Content>
  );
};

export default DisplayTeam;
