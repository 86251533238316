// @flow
import React from 'react';
import WargearListStyles from './WargearList.styles';
import { H } from '../../../styles';
import type { WargearListType } from '../../../types';

const WargearList = ({ wargear, onClick }: { wargear: WargearListType, onClick: Function}) => {
  if (wargear) {
    return (
      <WargearListStyles>
        <H as="h5">Click to Edit</H>
        <ul className="scroller">
          {Object.keys(wargear).map((key) => (
            <li key={key}>
              <button
                type="button"
                onClick={() => onClick(key)}
              >
                {wargear[key].name}
              </button>
            </li>
        ))}
        </ul>
        {`Total: ${Object.keys(wargear).length} wargear types`}
      </WargearListStyles>
    );
  }

  return (<p>No Wargear</p>);
};

export default WargearList;
