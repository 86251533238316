// @flow
import React, { useState } from 'react';
import AddFactionForm from './AddFactionForm';
import FactionList from './FactionList';
import { H } from '../../../styles';
import type { FactionListType } from '../../../types';

const Faction = ({ firebase, send, factions }: {
  firebase: any,
   send: Function,
   factions: FactionListType
}) => {
  const [selectedFaction, setSelectedFaction] = useState(null);

  const onSubmit = (faction, formType) => {
    if (formType === 'Add') {
      firebase.addByNamespace('factions', faction, (data) => {
        send('ADD_FACTION', { data });
      });
    } else if (formType === 'Edit') {
      firebase.updateByNamespace(`factions/${faction.id}`, faction)
        .then((data) => {
          send('UPDATE_FACTION', { data });
        });
    }
  };

  const onEditFaction = (fid) => {
    setSelectedFaction(fid);
  };

  return (
    <div>
      <H as="h3">Faction</H>
      <AddFactionForm
        onSubmit={onSubmit}
        selectedFaction={selectedFaction
        && factions[selectedFaction]}
      />
      <H as="h5">Click to Edit</H>
      <FactionList factions={factions} onClick={onEditFaction} />
    </div>
  );
};

export default Faction;
