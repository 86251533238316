// @flow
import React from 'react';
import { useFirebase } from '../../../Firebase';
import { useAuth } from '../../../Session';
import { ACTIONS } from '../../../../constants';
import ListEditForm from './ListEditForm';
import {
  getListCost,
} from './ListEdit.utils';
import { getListUnits } from './Errors/Errors.utils';
import type { UnitListType, TeamListType, TeamType } from '../../../../types';

const TeamListEdit = ({
  team, list, units, send, setTeamListEdit,
}: {
  team: TeamType,
  list: TeamListType,
  units: UnitListType,
  send: Function,
  setTeamListEdit: Function,
}) => {
  const firebase = useFirebase();
  const { uid } = useAuth();
  const { id: tid } = team;

  const onChange = (name) => {
    const updatedList = { ...list, name };
    firebase.updateTeamList(uid, tid, list.id, updatedList);
    send({ type: ACTIONS.UPDATE_TEAM_LIST, data: updatedList });
  };

  const deleteList = (id) => {
    setTeamListEdit(false);
    firebase.deleteTeamList(uid, tid, id);
    send(ACTIONS.DELETE_TEAM_LIST, { data: id });
  };

  const copyList = (listToBeCopied) => {
    firebase.copyTeamList(uid, tid, listToBeCopied, (data) => {
      send(ACTIONS.COPY_TEAM_LIST, { data });
    });
  };

  const selectTeamListUnit = (unit) => {
    const listUnits = list.units ? [...list.units] : [];
    let updatedList = {};
    const isSelected = list.units ? !!list.units.find((id) => id === unit.id) : false;
    // if unit already in list, remove unit
    if (isSelected) {
      const index = listUnits.indexOf(unit.id);
      listUnits.splice(index, 1);
    } else {
      listUnits.push(unit.id);
    }

    updatedList = { ...list, units: [...listUnits] };

    const unitsInList = getListUnits(units, updatedList);
    const totalCost = getListCost(unitsInList);

    const listWithCost = { ...updatedList, totalCost };

    firebase.updateTeamList(uid, tid, list.id, listWithCost);
    send({ type: ACTIONS.UPDATE_TEAM_LIST, data: listWithCost });
  };

  return (
    <ListEditForm
      list={list}
      units={units}
      team={team}
      selectUnit={selectTeamListUnit}
      onChange={onChange}
      deleteList={deleteList}
      copyList={copyList}
    />
  );
};

export default TeamListEdit;
