// @flow
import type {
  UnitListType, TeamListType, UnitType,
} from '../../../../../types';
import { SPECIALISTS } from '../../../../../constants';

export const getUnit = (units: UnitListType, id: string): UnitType | null => {
  const unitId = Object.keys(units).find((key) => key === id);
  return unitId ? units[unitId] : null;
};

export const getListUnits = (units: UnitListType,
  list: TeamListType): Array<UnitType> => (list.units
  ? list.units.map((id) => getUnit(units, id)) : []);

export const getUnitByName = (units: UnitListType, name: string): UnitType | null => {
  const unitId = Object.keys(units).find((key) => units[key].name === name);
  return unitId ? units[unitId] : null;
};

export const getUnitByGroupOrType = (units: UnitListType, name: string, list: TeamListType): UnitType => {
  const unit = getUnitByName(units, name);
  const isInList = list.units.find((id) => id === unit.id);

  if (!isInList) return null;

  return isInList;
};

export const getListUnitTypeCount = (listUnits: Array<UnitType>): any => listUnits
  .reduce((typeCount, unit) => {
    const unitType = typeCount[unit.type] ? typeCount[unit.type] : {
      count: 0,
      max: unit.stats.max,
    };
    unitType.count += 1;

    return { ...typeCount, [unit.type]: unitType };
  }, {});

export const hasTooManyUnitTypesMessage = (unitTypeCount: any) => Object.keys(unitTypeCount)
  .reduce((message, typeKey) => {
    const unitType = unitTypeCount[typeKey];
    if (unitType.max !== -1 && unitType.count > unitType.max) return [...message, `Has too many ${typeKey}`];

    return message;
  }, []);

// TODO: add to test and refactor using listUnits
export const hasTooManyUniqueItems = (units: UnitListType, list: TeamListType): boolean => {
  if (!list.units) return false;
  const uniqueItems = [];

  for (let j = 0; j < list.units.length; j += 1) {
    const id = list.units[j];
    const unitId = Object.keys(units).find((key) => key === id);
    if (unitId) {
      const unit = units[unitId];
      if (id === unit.id && unit.activeOptions) {
        for (let i = 0; i < unit.activeOptions.length; i += 1) {
          const option = unit.activeOptions[i];
          if (option.max === 1) {
            // already taken
            if (uniqueItems.find((itemName) => itemName === option.name)) return true;

            uniqueItems.push(option.name);
          }
        }
      }
    }
  }
  return false;
};

export const getSpecialistCount = (listUnits: Array<UnitType>,
  withLeader: boolean = false): number => listUnits
  .reduce((total, unit) => {
    if (withLeader && unit.specialist) return total + 1;

    if (unit.specialist && unit.specialist.name !== SPECIALISTS.LEADER) {
      return total + 1;
    }

    return total;
  },
  0);

export const hasLeader = (listUnits: Array<UnitType>): boolean => !!listUnits
  .find((unit) => unit.specialist && unit.specialist.name === SPECIALISTS.LEADER);

export const isOverListBudget = (listCost: number, maxTotal: number): number => listCost > maxTotal;

export const getListDependencies = (listUnits: Array<UnitType>): Array<string> => listUnits
  .reduce((message, unit) => {
    if (unit.dependency) {
      const unforfilledDependencies = [];
      unit.dependency.forEach((depName) => {
        if (!listUnits.find((listUnit) => listUnit.type === depName
        || listUnit.group === depName)) {
          unforfilledDependencies.push(depName);
        }
      });

      const messages = unforfilledDependencies.map((deps) => `Need at least one ${deps} in order to have a ${unit.type}`);

      return [...message, ...messages];
    }
    return message;
  }, []);

export const getSpecialistsTaken = (listUnits: Array<UnitType>): Array<string> => {
  const specialists = [];
  const hasError = [];
  return listUnits
    .reduce((message, unit) => {
      if (unit.specialist) {
        if (specialists.find((specialist) => specialist === unit.specialist.name
        && !hasError.find((specialistErrorName) => specialistErrorName === unit.specialist.name))) {
          hasError.push(unit.specialist.name);
          return [...message, `You have more than one ${unit.specialist.name} specialist`];
        }

        specialists.push(unit.specialist.name);
      }
      return message;
    }, []);
};
