// @flow
import React from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { getUnitDependencies } from './AddUnit.utils';
import { ListUl, ListLi, H } from '../../../../styles';
import { groupUnits, getUnitTypeCount } from '../../../../utils';
import AddUnitStyles from './AddUnit.styles';
import { Skull } from '../../../../assets/icons';
import type {
  UnitListType, FactionUnitListType,
} from '../../../../types';

type AddUnitTypes = {
  units: UnitListType,
  factionUnits: FactionUnitListType,
  onAddUnit: Function,
}

const AddUnit = ({
  units, factionUnits, onAddUnit,
}: AddUnitTypes) => {
  const groupedUnits = groupUnits(factionUnits);
  const unitsMarkup = Object.keys(groupedUnits).map((groupKey) => {
    const unitMarkup = groupedUnits[groupKey].map((factionUnit) => {
      const unitDependencies = getUnitDependencies(units, factionUnit);
      const isValid = !unitDependencies.length;
      const unitTypeCount = getUnitTypeCount(units, factionUnit);
      return (
        <ListLi key={factionUnit.type}>
          <div className="list-name">
            <button
              type="button"
              onClick={() => onAddUnit(factionUnit)}
              className="edit-list-btn"
            >
              <Skull className="faction-icon" />
              <IoIosAddCircleOutline className="icon" />
              <div className="list-info">
                <h5>{factionUnit.type}</h5>
                <p className="strong">
                  <span>{`${factionUnit.cost} points`}</span>
                </p>
                <p>
                  { `${unitTypeCount} of ${factionUnit.stats.max === -1 ? '∞ ' : factionUnit.stats.max} `}
                  {!isValid && (
                  <span className="highlight">
                    • You need at least one
                    {' '}
                    {unitDependencies.join(' or ')}
                  </span>
                )}
                </p>
              </div>
            </button>
          </div>
        </ListLi>
      );
    });
    return (
      <div key={groupKey}>
        <H as="h4">{groupKey}</H>
        <ListUl>
          {unitMarkup}
        </ListUl>
      </div>
    );
  });
  return (
    <AddUnitStyles>
      <H as="h2" pb>
        Add Unit
      </H>
      {unitsMarkup}
    </AddUnitStyles>
  );
};

export default AddUnit;
