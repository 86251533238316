// @flow
import React, { useState } from 'react';
import { UnitListStyles } from './DisplayTeam.styles';
import UnitList from '../UnitList';
import { Content, Grid } from '../../../../styles';
import type {
  TeamType, UnitType, WargearListType, SpecialistListType, AbilitiesListType,
} from '../../../../types';
import DisplayUnit from '../DisplayUnit';

const DisplayTeam = ({
  team, units, wargear, specialists, abilities,
}: {
  team: TeamType,
  units: Array<UnitType>,
  wargear: WargearListType,
  specialists: SpecialistListType,
  abilities: AbilitiesListType
}) => {
  const [selectedUnit, setSelectedUnit] = useState(units[0]);

  const onSelectUnit = (id) => {
    setSelectedUnit(id);
  };

  return (
    <Content title={`${team.name} - ${team.cost}pts`} wide>
      <Grid columns="275px 1fr">
        <UnitListStyles>
          <UnitList units={units} onSelectUnit={onSelectUnit} selectedUnit={selectedUnit} />
        </UnitListStyles>
        {selectedUnit && (
        <DisplayUnit
          unit={selectedUnit}
          wargear={wargear}
          specialists={specialists}
          abilities={abilities}
        />
)}
      </Grid>
    </Content>
  );
};

export default DisplayTeam;
