// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_4_copy"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path d="M501.6,35c-257,0-465.4,208.4-465.4,465.4c0,257,208.4,465.4,465.4,465.4S967,757.4,967,500.4C967,243.4,758.6,35,501.6,35
		z M501.6,938.7c-242.1,0-438.3-196.2-438.3-438.3S259.6,62.1,501.6,62.1s438.3,196.2,438.3,438.3S743.7,938.7,501.6,938.7z"
      />
      <path d="M309.1,254.7c0-64.1,31.2-120.8,79.2-156C213.6,148.3,85.7,309,85.7,499.6c0,225.9,179.7,409.7,403.9,416.6V447.6
		C388.8,440.9,309.1,357.1,309.1,254.7z"
      />
      <path d="M616.6,98.6c48,35.2,79.2,92,79.2,156c0,102.4-79.7,186.3-180.5,192.9v468.5c224.2-6.8,403.9-190.7,403.9-416.6
		C919.2,309,791.3,148.3,616.6,98.6z"
      />
      <circle cx="502.4" cy="254.7" r="171.9" />
    </g>
  </svg>
);
