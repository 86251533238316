// @flow
import React, { useState } from 'react';
import AddAbilitiesForm from './AddAbilitiesForm';
import AbilitiesList from './AbilitiesList';
import { H, Grid } from '../../../styles';
import type { AbilitiesListType } from '../../../types';

const Faction = ({ firebase, send, abilities }: {
  firebase: any,
   send: Function,
   abilities: AbilitiesListType
}) => {
  const [selectedAbility, setSelectedAbility] = useState(null);

  const onSubmit = (ability, formType) => {
    if (formType === 'Add') {
      firebase.addByNamespace('abilities', ability, (data) => {
        send('ADD_ABILITY', { data });
      });
    } else if (formType === 'Edit') {
      firebase.updateByNamespace(`abilities/${ability.id}`, ability)
        .then((data) => {
          send('UPDATE_ABILITY', { data });
        });
    }
  };

  const onEditAbility = (fid) => {
    setSelectedAbility(fid);
  };

  return (
    <div>
      <H as="h3">Abilities</H>
      <Grid columns="1fr 200px">
        <AddAbilitiesForm
          onSubmit={onSubmit}
          selectedAbility={selectedAbility
        && abilities[selectedAbility]}
        />
        <AbilitiesList abilities={abilities} onClick={onEditAbility} />
      </Grid>
    </div>
  );
};

export default Faction;
