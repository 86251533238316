// @flow
import React, { useState, useEffect } from 'react';
import LevelsProfileStyles from './AbilitiesProfile.styles';
import SelectBox from '../common/SelectBox';
import { H } from '../../../styles';

const levelCosts = [0, 4, 8, 12];

const updateLevel = (array, id, newValue) => {
  const copy = [...array];
  copy[id] = { ...copy[id], ...newValue };
  return copy;
};

const Ability = ({ ability, onChange, id }: {
  ability: any,
  onChange: Function,
  id: number,
}) => (
  <article>
    <label htmlFor="true">
      <span>Specialist Name*</span>
      <input
        name="name"
        value={ability && ability.name}
        onChange={(e) => onChange({ name: e.currentTarget.value }, id)}
        type="text"
        placeholder="Comms"
      />
    </label>
    <label htmlFor="true">
      <span>Description</span>
      <textarea
        name="descr"
        value={ability && ability.descr}
        onChange={(e) => onChange({ descr: e.currentTarget.value }, id)}
        type="text"
        placeholder="Some warriors excel in the close-quarters cut and thrust of battle. ..."
      />
    </label>
  </article>
);

const LevelsProfile = ({ setLevels, levels, id }: {
  setLevels: Function,
  levels: Array<any>,
  id: number
}) => {
  const [abilitiesMarkup, setAbilitiesMarkup] = useState('');
  const [numberOfAbilities, setNumberOfAbilities] = useState(0);

  const onChangeAbility = (value, index) => {
    const newLevels = [...levels];
    const currentLevel = newLevels[id];
    currentLevel.abilities[index] = { ...currentLevel.abilities[index], ...value };

    setLevels(newLevels);
  };

  useEffect(() => {
    if (levels && levels.length) {
      const level = levels[id];

      if (level.abilities) {
        const abilitiesCount = level.abilities.length;
        const profiles = [...Array(abilitiesCount).keys()];
        const markup = profiles.map((i) => (
          <Ability
            key={i.toString()}
            ability={level.abilities[i]}
            onChange={onChangeAbility}
            id={i}
          />
        ));

        setAbilitiesMarkup(markup);
        setNumberOfAbilities(abilitiesCount);
      }
    }
  }, [levels]);

  return (
    <LevelsProfileStyles>
      <H as="h5">
        {`Level ${id + 1}`}
      </H>
      <SelectBox
        data={[1, 2, 3, 4]}
        name="Number of Abilities"
        onSelect={(e) => setNumberOfAbilities(e.target[e.target.selectedIndex].value)}
        selectedData={numberOfAbilities}
      />
      {abilitiesMarkup}
      <label htmlFor="true">
        <span>Cost*</span>
        <input
          name="cost"
          value={levels && levels[id] && levels[id].cost}
          onChange={(e) => setLevels(updateLevel(levels, id, { cost: e.currentTarget.value }))}
          type="text"
          defaultValue={levelCosts[id]}
        />
      </label>
      <label htmlFor="true">
        <span>Description</span>
        <input
          name="descr"
          value={levels && levels[id] && levels[id].descr}
          onChange={(e) => setLevels(updateLevel(levels, id, { descr: e.currentTarget.value }))}
          type="text"
          placeholder=""
        />
      </label>
    </LevelsProfileStyles>
  );
};

export default LevelsProfile;
