// @flow
import React, { useState, useEffect } from 'react';
import { FormStyles } from '../../../styles';
import type { SpecialistType } from '../../../types';
import LevelsProfile from './LevelsProfile';

const style = {
  maxWidth: 'none',
  width: '100%',
};

const formatLevels = (levels) => levels.reduce((obj, level) => {
  const {
    id, cost, descr, abilities,
  } = level;
  return { ...obj, [id]: { cost, descr, abilities } };
}, {});

const AddFactionForm = ({ onSubmit, selectedSpecialist }: {
  onSubmit: Function,
  selectedSpecialist?: SpecialistType | null
}) => {
  const [name, setName] = useState('');
  const [descr, setDescr] = useState('');
  const [levels, setLevels] = useState([]);
  const [formType, setFormType] = useState('Add');
  const [levelsMarkup, setLevelsMarkup] = useState('');
  const amountOfLevels = 4;
  const isValid = !!name;

  const resetForm = () => {
    setName('');
    setDescr('');
  };

  useEffect(() => {
    if (selectedSpecialist) {
      setName(selectedSpecialist.name);
      setDescr(selectedSpecialist.descr);

      if (selectedSpecialist.levels) {
        const selectedSpecialistLevels = Object.keys(selectedSpecialist.levels).map((key) => ({
          id: key,
          cost: selectedSpecialist.levels[key].cost,
          descr: selectedSpecialist.levels[key].descr || '',
          abilities: selectedSpecialist.levels[key].abilities || [],
        }));

        setLevels(selectedSpecialistLevels);
      }

      setFormType('Edit');
    }
  }, [selectedSpecialist]);

  useEffect(() => {
    if (amountOfLevels) {
      const profiles = [...Array(amountOfLevels).keys()];
      const markup = profiles.map((i) => (
        <LevelsProfile key={i.toString()} setLevels={setLevels} levels={levels} id={i} />
      ));
      setLevelsMarkup(markup);
    }
  }, [amountOfLevels, levels]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    let specialist = { name, descr, levels: formatLevels(levels) };
    if (selectedSpecialist) {
      specialist = { ...specialist, id: selectedSpecialist.id };
    }

    onSubmit(specialist, formType);

    resetForm();
  };

  return (
    <FormStyles onSubmit={(e) => onFormSubmit(e)} style={style}>
      <label htmlFor="true">
        <span>Specialist Name*</span>
        <input
          name="name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          type="text"
          placeholder="Comms"
        />
      </label>
      <label htmlFor="true">
        <span>Description</span>
        <textarea
          name="descr"
          value={descr}
          onChange={(e) => setDescr(e.currentTarget.value)}
          type="text"
          placeholder="Some warriors excel in the close-quarters cut and thrust of battle. ..."
        />
      </label>
      {levelsMarkup}
      <div>
        <button disabled={!isValid || formType !== 'Add'} type="submit">
          Add Specialist
        </button>
        <button disabled={!isValid || formType !== 'Edit'} type="submit">
          Edit Specialist
        </button>
      </div>
      {/* {error && <p className="error">{error.message}</p>} */}
    </FormStyles>
  );
};

AddFactionForm.defaultProps = {
  selectedSpecialist: null,
};

export default AddFactionForm;
