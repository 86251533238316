import styled from 'styled-components';

export default styled.div`
.modal--background {
  z-index: 1;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  z-index: 100;
  display: block;
  position: fixed;
  overflow: scroll;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: var(--white);
  width: 100%;
  ${({ wide }) => wide === 'true' || 'max-width: 800px;'};
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
}

/* @media screen and (min-width: 550px) {
  .modal {
    top: 134px;
  }
} */

.modal--inner {
  /* padding: 1rem 0.1rem; */
}

.modal--button {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
}
`;
