// @flow
import React from 'react';
import styled from 'styled-components';
import H from './Headings';

const ContentStyles = styled.main`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  padding: 4rem 0.3rem;
  min-height: calc(100vh - 46px);
  ${({ wide }) => !wide && 'max-width: 700px;'};

  & > section {
    flex: 1;
    background-color: var(--white);
    ${({ pt }) => pt && 'padding-top: 5px'};
  }

  p {
    padding: 0.2rem 0.5rem;;
  }

  & a {
    color: var(--red);
  }

  @media screen and (min-width: 550px) {
    min-height: calc(100vh - 75px);
    padding-top: 4.5rem;
  }
`;

const Content = ({
  children, title, pt, wide,
}: {
  children: Object,
  title?: string,
  pt?: number | null,
  wide?: boolean,
}) => {
  if (title) {
    return (
      <ContentStyles pt={pt} wide={wide}>
        <H as="h3" red bold bottomBorder pb>{title}</H>
        <section>
          {children}
        </section>
      </ContentStyles>
    );
  }

  return (
    <ContentStyles pt={pt} wide={wide}>
      {children}
    </ContentStyles>
  );
};

Content.defaultProps = {
  title: '',
  pt: null,
  wide: false,
};

export default Content;
