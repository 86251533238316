// @flow
import app from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import type { TeamType, UnitType, TeamListType } from '../../types';

const devConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
};

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_apiKey,
  authDomain: process.env.REACT_APP_PROD_authDomain,
  databaseURL: process.env.REACT_APP_PROD_databaseURL,
  projectId: process.env.REACT_APP_PROD_projectId,
  storageBucket: process.env.REACT_APP_PROD_storageBucket,
  messagingSenderId: process.env.REACT_APP_PROD_messagingSenderId,
  appId: process.env.REACT_APP_PROD_appId,
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  app: any

  auth: any

  db: any

  user: any

  constructor() {
    this.app = app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
  }

  doCreateUserWithEmailAndPassword = (email: string, password: string) => this.auth.createUserWithEmailAndPassword(email, password); // eslint-disable-line

  doSignInWithEmailAndPassword = (email: string, password: string) => this.auth.signInWithEmailAndPassword(email, password);  // eslint-disable-line

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password: string) => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next: Function,
    fallback: Function) => this.auth.onAuthStateChanged((authUser) => {
    if (authUser) {
      this.user(authUser.uid)
        .once('value')
        .then((snapshot) => {
          const dbUser = snapshot.val();
          // default empty roles
          if (!dbUser.roles) {
            dbUser.roles = {};
          }
          // merge auth and db user
          const mergedAuthUser = {
            uid: authUser.uid,
            email: authUser.email,
            ...dbUser,
          };
          next(mergedAuthUser);
        });
    } else {
      fallback();
    }
  });

  user = (uid: string) => this.db.ref(`users/${uid}`);

  getUser = (uid: string) => this.db.ref(`users/${uid}`).once('value');

  getUsers = () => this.db.ref('users').orderByChild('name').once('value');

  createFaction = ({ name, descr }: { name: string, descr: string}) => this.db.ref('factions')
    .add({
      name,
      descr,
      created: Date.now(),
    });

  getFactions = () => this.db.ref('factions');

  getFaction = (fid: string) => this.db.ref(`factions/${fid}`);

  getFactionUnits = (fid: string) => this.db.ref(`faction-units/${fid}`);

  getWargear = () => this.db.ref('wargear').orderByChild('name').once('value');

  getUserTeams = (uid: string) => this.db.ref(`teams/${uid}`);

  getUserTeam = (uid: string, tid: string) => this.db.ref(`teams/${uid}/${tid}`);

  getUserTeamUnits = (uid: string, tid: string) => this.db.ref(`units/${uid}/${tid}`);

  addUserTeamUnit = (uid: string, tid: string, unit: UnitType) => {
    const namespace = `units/${uid}/${tid}`;
    const newFirebaseKey = this.db.ref(namespace).push().key;
    const updates = {};
    const newUnit = { ...unit, id: newFirebaseKey };
    updates[`/${namespace}/${newFirebaseKey}`] = newUnit;

    this.db.ref().update(updates, (error) => {
      if (error) {
        // The write failed...
        console.error('Something went wrong: ', error);
      } else {
        // Data saved successfully!
        console.log('Data saved: ', namespace);
      }
    });

    return newUnit;
  };

  addTeam = (uid: string, team: TeamType, callback: Function) => {
    const teamKey = this.db.ref(`/teams/${uid}`).push().key;
    const newTeam = { ...team, id: teamKey };
    const updates = {};
    updates[`/teams/${uid}/${teamKey}`] = newTeam;

    return this.db.ref().update(updates, (error) => {
      if (error) {
        // The write failed...
        console.error('Something fucked up ', error);  /* eslint-disable-line */
      } else {
        callback(newTeam);
      }
    });
  };

  deleteTeam = (uid: string, tid: string) => {
    this.db.ref(`teams/${uid}/${tid}`).remove();
    this.db.ref(`units/${uid}/${tid}`).remove();
  };

  copyTeam = (uid: string, teamToCopy: TeamType) => {
    const { id, ...rest } = teamToCopy;
    const team = {
      ...rest,
      name: `${rest.name}-copy`,
      created: Date.now(),
    };

    const teamKey = this.db.ref(`/teams/${uid}`).push().key;
    const updates = {};
    updates[`/teams/${uid}/${teamKey}`] = { ...team, id: teamKey };

    this.db.ref().update(updates, (error) => {
      if (error) {
        // TODO: throw error
        console.error('Something fucked up ', error);  /* eslint-disable-line */
      }
    });

    return this.getUserTeamUnits(uid, teamToCopy.id).once('value')
      .then((snapshot) => {
        const teamUnits = snapshot.val();
        updates[`/units/${uid}/${teamKey}`] = teamUnits;
        this.db.ref().update(updates, (error) => {
          if (error) {
            // TODO: throw error
            console.error('Something fucked up ', error);  /* eslint-disable-line */
          }
        });
      })
      .then(() => ({
        team, id: teamKey,
      }));
  };

  updateTeam(uid: string, tid: string, updates: any) {
    this.db.ref(`/teams/${uid}/${tid}`).update(updates);
  }

  copyUnit = (uid: string, tid: string, originalUnit: UnitType, callback: Function) => {
    const unit = { ...originalUnit, name: `${originalUnit.name}-copy` };
    const id = this.db.ref(`/units/${uid}/${tid}`).push().key;
    const newUnit = { ...unit, id };
    const updates = {};
    updates[`/units/${uid}/${tid}/${id}`] = newUnit;

    this.db.ref().update(updates, (error) => {
      if (error) {
        // The write failed...
        console.error('Something fucked up ', error);  /* eslint-disable-line */
      } else {
        // Data saved successfully!
        callback(newUnit);
      }
    });
  }

  deleteUnit = (uid: string, tid: string, id: string) => {
    try {
      this.db.ref(`units/${uid}/${tid}/${id}`).remove();
    } catch (error) {
      console.error('Could not delete unit: ', error);  /* eslint-disable-line */
    }

    console.log('Unit deleted: ', id);  /* eslint-disable-line */
  }

  updateUnit(uid: string, tid: string, unit: UnitType) {
    this.db.ref(`units/${uid}/${tid}/${unit.id}`).set({
      ...unit,
    })
      .then(() => {
        console.log('Unit updated', unit.name);  /* eslint-disable-line */
      })
      .catch((error) => {
        console.error('Error removing document: ', error);  /* eslint-disable-line */
      });
  }

  getTeamLists = (uid: string, tid: string) => this.db.ref(`lists/${uid}/${tid}`);

  addTeamList = (uid: string, tid: string, teamList: TeamListType, callback: Function) => {
    const teamListKey = this.db.ref(`/lists/${uid}/${tid}`).push().key;
    const newTeamList = { ...teamList, id: teamListKey, created: Date.now() };
    const updates = {};
    updates[`/lists/${uid}/${tid}/${teamListKey}`] = newTeamList;

    return this.db.ref().update(updates, (error) => {
      if (error) {
        // The write failed...
        console.error('Something fucked up ', error);  /* eslint-disable-line */
      } else {
        callback(newTeamList);
      }
    });
  };

  updateTeamList(uid: string, tid: string, lid: string, list: string) {
    this.db.ref(`/lists/${uid}/${tid}/${lid}`).set({
      ...list,
    })
      .then(() => {
        console.log('List updated', list);  /* eslint-disable-line */
      })
      .catch((error) => {
        console.error('Error updating document: ', error);  /* eslint-disable-line */
      });
  }

  deleteTeamList = (uid: string, tid: string, id: string) => {
    try {
      this.db.ref(`lists/${uid}/${tid}/${id}`).remove();
    } catch (error) {
      console.error('Could not delete list: ', error);  /* eslint-disable-line */
    }

    console.log('List deleted: ', id);  /* eslint-disable-line */
  }

  copyTeamList = (uid: string, tid: string, listToCopy: TeamListType, callback: Function) => {
    const { id, ...rest } = listToCopy;
    const listKey = this.db.ref(`/lists/${uid}/${tid}`).push().key;
    const list = {
      ...rest,
      id: listKey,
      name: `${rest.name}-copy`,
      created: Date.now(),
    };

    const updates = {};
    updates[`/lists/${uid}/${tid}/${listKey}`] = list;

    this.db.ref().update(updates, (error) => {
      if (error) {
        // TODO: throw error
        console.error('Something fucked up ', error);  /* eslint-disable-line */
      } else {
        callback(list);
      }
    });
  }

  getByNamespace = (name: string) => this.db.ref(name);

  addByNamespace = (namespace: string, data: any, callback?: Function) => {
    const firebaseKey = this.db.ref(namespace).push().key;
    const dataWithKey = { ...data, id: firebaseKey, created: Date.now() };
    const updates = {};
    updates[`${namespace}/${firebaseKey}`] = dataWithKey;

    return this.db.ref().update(updates, (error) => {
      if (error) {
        // The write failed...
        console.error('Something fucked up ', error);  /* eslint-disable-line */
      } else if (callback) callback(dataWithKey);
    });
  };

  updateByNamespace(namespace: string, data: any) {
    return this.db.ref(`${namespace}`).set({
      ...data,
    })
      .then(() => {
        console.log('Data updated', data);  /* eslint-disable-line */
        return data;
      })
      .catch((error) => {
        console.error('Error updating document: ', error);  /* eslint-disable-line */
      });
  }
}

export default Firebase;
