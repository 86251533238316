// @flow
import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlineFileText } from 'react-icons/ai';
import {
  FiEdit as Edit,
  FiList as DisplayTeamIcon, FiPrinter as Print,
  FiMoreVertical as Options,
  FiEdit as Copy,
  FiTrash2 as Delete,
} from 'react-icons/fi';
import { ListEditHeaderStyles } from './ListEdit.styles';
import PrintList from './PrintList';
import { H } from '../../../../styles';
import type { TeamListType, UnitType, TeamType } from '../../../../types';
import EditMenu from '../../../EditMenu';
import Modal from '../../../Modal';
import DisplayTeam from '../../common/DisplayTeam';

const ListEditHeader = ({
  list, listUnits, team, onChange, deleteList, copyList,
}: {
  list: TeamListType,
  listUnits: Array<UnitType>,
  team: TeamType,
  onChange: Function,
  deleteList: Function,
  copyList: Function,
}) => {
  const [editMenu, setEditMenu] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const componentRef = useRef();
  return (
    <ListEditHeaderStyles>
      <section className="edit-team">
        <AiOutlineFileText name={list.name} className="unit-name-icon" />
        <label htmlFor="true">
          List Name
          <input
            type="text"
            onFocus={(event) => event.target.select()}
            onBlur={(e) => onChange(e.target.value)}
            defaultValue={list.name}
          />
          <span className="edit-icon">
            <Edit color="#24C6A2" size="20" />
          </span>
        </label>
        <button type="button" className="team-edit-btn" onClick={() => setEditMenu(true)}>
          <Options size="30" />
        </button>
      </section>
      <section className="edit-team">
        <H as="h5">{list.type}</H>
      </section>
      <section className="edit-team">
        <H as="h6">
          {list.maxPoints === -1 ? '∞ list points' : `Max ${list.maxPoints} points`}
          {list.leaderLimit === -1 ? ' • ∞ number of leaders' : ` • Max ${list.leaderLimit} leader`}
          {list.specialistLimit === -1 ? ' • ∞ numbers of specialists' : ` • Max ${list.specialistLimit} specialists`}
        </H>
      </section>
      <section className="edit-team">
        <H as="h5">
          {list.units ? `${list.units.length} units` : '0 units'}
          {list.totalCost ? ` • ${list.totalCost} points` : ' • 0 points'}
        </H>
      </section>
      {editMenu && (
      <EditMenu onClick={() => setEditMenu(false)}>
        <div className="list-edit-menu">
          <button
            type="button"
            onClick={() => { setEditMenu(false); deleteList(list.id); }}
            aria-label="Delete list"
          >
            <Delete size="30" className="menu-icon" />
            <H as="h6">Delete List</H>
          </button>
          <button
            type="button"
            onClick={() => { setEditMenu(false); copyList(list); }}
            aria-label="Copy list"
          >
            <Copy size="30" className="menu-icon" />
            <H as="h6">Copy List</H>
          </button>
          <ReactToPrint
            trigger={() => (
              <button
                type="button"
                onClick={() => setEditMenu(false)}
                aria-label="Print Team"
                disabled={!list.units}
              >
                <Print size="30" className="menu-icon" />
                <H as="h6">Print List</H>
              </button>
                )}
            content={() => componentRef.current}
          />
          <div style={{ display: 'none' }}>
            {listUnits && <PrintList ref={componentRef} units={listUnits} />}
          </div>
          <button
            type="button"
            onClick={() => { setPreviewMode(true); setEditMenu(false); }}
            aria-label="Display Team"
            disabled={!list.units}
          >
            <DisplayTeamIcon size="30" className="menu-icon" />
            <H as="h6">Display List</H>
          </button>
        </div>
      </EditMenu>
        )}
      {previewMode && (
        <Modal onClick={setPreviewMode} wide>
          <DisplayTeam
            team={team}
            units={listUnits}
          />
        </Modal>
          )}
    </ListEditHeaderStyles>
  );
};

export default ListEditHeader;
