import styled from 'styled-components';

export default styled.div`
flex-direction: column !important; 
align-items: flex-start !important;
padding: 0.5rem 0;
border-bottom: 1px solid var(--red);
 
& > label {
  width: 100%;
}
`;
