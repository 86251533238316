import styled from 'styled-components';

export default styled.button`
border: none;
margin: 0;
padding: 0;
margin-right: 1rem;
display: 'inline-flex';
align-items: 'center';

& .icon {
  width: 30px;
  height: 22px;
  color: #99C624;
}
`;
