import React from 'react';
import { SignUpLink } from '../Account/SignUp';
import { SignInLink } from '../Account/SignIn';
import { Content } from '../../styles';

const Landing = () => (
  <Content title="Welcome to Kill Team Booster">
    <p className="paragraph">It&apos;s simply a Kill Team manager app with some extra features for Ipad and Desktop in game!</p>
    <br />
    <p className="paragraph">
      Create an account and
      <strong> test it out!</strong>
    </p>
    <br />
    <SignInLink />
    <br />
    <SignUpLink />
  </Content>
);
export default Landing;
