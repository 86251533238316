import styled from 'styled-components';

export default styled.section`
  padding: 0.3rem 0.1rem 0.1rem;
  border-bottom: 1px solid var(--red);

  .specialist-menu {
    display: flex;
    padding: 0.1rem 0 0;

    & > span:first-child {
      margin-left: 0;
    } 
    & > span:last-child {
      margin-right: 0;
    } 

    & > span {
      flex: 1 0;
      color: var(--white);
      background-color: var(--base110);
      margin: 0 0.1rem;
      padding: 0.3rem 0.3rem;
      font-size: 0.5rem;
      text-align: center;

      &.leader {
        color: var(--white);
        background-color: var(--red);
      }

      &.empty {
        color: var(--base110);
        background-color: var(--base090);
      }
    }
  }

  .error {
    border-top: 1px solid  var(--red);
    padding: 3px 5px 1px;
    font-size: 0.7rem;
    color: var(--red);
  }
`;
