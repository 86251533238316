// @flow
import React, { useState, useEffect } from 'react';
import SimpleProfileForm from './SimpleProfileForm';
import { FormStyles } from '../../../styles';
import MultipleProfileForm from './MultipleProfileForm';
import type { WargearType } from '../../../types';

const style = {
  maxWidth: 'none',
  width: '100%',
};

const AddFactionForm = ({ onSubmit, selectedWargear, wargear }: {
  onSubmit: Function,
  selectedWargear?: WargearType | null,
  wargear: WargearType
}) => {
  const [formValues, setFormValues] = useState(null);
  const [formType, setFormType] = useState('Add');
  const [profileType, setProfileType] = useState('simple');
  const isValid = formValues && !!formValues.name;

  const onChangeProfileType = (type) => {
    setProfileType(type);
    setFormValues(null);
  };

  const resetForm = () => {
    setFormValues(null);
  };

  useEffect(() => {
    if (selectedWargear) {
      setFormValues(selectedWargear);

      if (selectedWargear.profile) {
        setProfileType('multiple');
      } else {
        setProfileType('simple');
      }

      setFormType('Edit');
    }
  }, [selectedWargear]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    let updatedWargear = formValues;
    if (selectedWargear) {
      updatedWargear = { ...updatedWargear, id: selectedWargear.id };
    }

    onSubmit(updatedWargear, formType);

    resetForm();
  };

  return (
    <FormStyles onSubmit={(e) => onFormSubmit(e)} style={style}>
      <label htmlFor="true">
        <span>With Profile</span>
        Simple Profile
        <input
          name="simpleProfile"
          type="radio"
          checked={profileType === 'simple'}
          onChange={() => onChangeProfileType('simple')}
        />
        Multiple Profile
        <input
          name="multipleProfile"
          type="radio"
          checked={profileType === 'multiple'}
          onChange={() => onChangeProfileType('multiple')}
        />
      </label>
      <hr />
      {(selectedWargear && selectedWargear.profile)
    || profileType === 'multiple'
    ? <MultipleProfileForm formType={formType} formValues={formValues} setFormValues={setFormValues} wargear={wargear} />
     : <SimpleProfileForm formValues={formValues} setFormValues={setFormValues} />}
      <div>
        <button disabled={!isValid || formType !== 'Add'} type="submit">
          Add Wargear
        </button>
        <button disabled={!isValid || formType !== 'Edit'} type="submit">
          Edit Wargear
        </button>
      </div>
      {/* {error && <p className="error">{error.message}</p>} */}
      <p>
        Den här delen är väldigt yxig, men tror allt finns man behöver
      </p>
      <p>
        Man lägger upp data på vilka kombinationer enheten kan ha. Så enklast är att kartlägga dem först, sedan lägga till all wargear som inte finns (simple profile) och
        sedan gör man multiple profiles för dessa kombinationer.
      </p>
      <p>
        Exempel: 'Boltgun, Chainsword'
        <br />
        Skapa först simple profile Boltgun, sedan simple profile Chainsword
        <br />
        Skapa sedan multiple profile, välj 2 profiles i dropdown. Välj sedan Boltgun i första, Chainsword i andra.
        Namnet uppdateras automatiskt. Men ett pris kan man sätta då vissa kombinationer blir dyrare än standard pris.
      </p>
      <p>
        <b>Undvik att editera tidigare tillagd wargear då det kommer påverka enheter som redan använder dessa.</b>
      </p>
    </FormStyles>
  );
};

AddFactionForm.defaultProps = {
  selectedWargear: null,
};

export default AddFactionForm;
