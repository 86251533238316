// @flow
import React from 'react';
import H from '../../../styles/Headings';
import SpecialistStyles from './SpecialistStyles';
import type { UnitType } from '../../../types';

const SpecialistForm = ({
  unit, updateUnit,
}: {
  unit: UnitType,
  updateUnit: Function,
}) => {
  const options = unit.specialists
    ? unit.specialists
      .map((specialist) => <option key={specialist} value={specialist}>{specialist}</option>)
    : <option value="">No Specialists</option>;
  return (
    <SpecialistStyles>
      <H as="h5" pb>Specialist</H>
      <select value={unit.specialist ? unit.specialist.name : ''} onChange={(e) => updateUnit(e, 'specialists')}>
        <option value="">- Select Specialism -</option>
        {options}
      </select>
      {unit.specialist && <p>{unit.specialist.levels.lvl1.abilities[0].descr}</p>}
    </SpecialistStyles>
  );
};

export default SpecialistForm;
