export const SET_FACTIONS = 'setFactions';
export const SET_FACTION_UNITS = 'setFactionUnits';
export const SET_TEAMS = 'getTeams';
export const ADD_TEAM = 'addTeam';
export const DELETE_TEAM = 'deleteTeam';
export const UPDATE_TEAM = 'updateTeam';
export const UPDATE_PARENT_TEAM = 'updateParentTeam';
export const COPY_TEAM = 'copyTeam';
export const AUTO_SELECT = 'autoSelect';
export const SELECT = 'select';
export const ADD_TEAM_UNIT = 'AddTeamUnit';
export const DELETE_TEAM_UNIT = 'DeleteTeamUnit';
export const UPDATE_TEAM_UNIT = 'UpdateTeamUnit';
export const COPY_TEAM_UNIT = 'copyTeamUnit';
export const SET_TEAM_UNITS = 'setTeamUnits';
export const UPDATE_TEAM_UNITS = 'updateTeamUnits';
export const BACK_TO_DEFAULT = 'back';
export const OPEN_TEAM_OPTIONS_MENU = 'openTeamOptionsMenu';
export const CLOSE_TEAM_OPTIONS_MENU = 'closeTeamOptionsMenu';
export const OPEN_UNIT_OPTIONS_MENU = 'openUnitOptionsMenu';
export const CLOSE_UNIT_OPTIONS_MENU = 'closeUnitOptionsMenu';
export const SET_TEAM_LISTS = 'setTeamLists';
export const ADD_TEAM_LIST = 'addTeamList';
export const UPDATE_TEAM_LIST = 'updateTeamList';
export const DELETE_TEAM_LIST = 'deleteTeamList';
export const COPY_TEAM_LIST = 'copyTeamList';
export const SET_SPECIALISTS = 'setSpecialists';
export const SET_ABILITIES = 'setAbilities';
export const SET_WARGEAR = 'setWargear';
