// @flow
import React from 'react';
import SelectBox from '../common/SelectBox';
import { wargearGroupData, wargearTypeData } from './constants';

const SimpleProfileForm = ({ formValues, setFormValues }: {
    formValues: any,
    setFormValues: Function
  }) => (
    <section>
      <label htmlFor="true">
        <span>Name*</span>
        <input
          name="name"
          value={formValues ? formValues.name : ''}
          onChange={(e) => setFormValues({ ...formValues, name: e.currentTarget.value })}
          type="text"
          placeholder="Shotgun"
        />
      </label>
      <label htmlFor="true">
        <span>Abilities</span>
        <input
          name="abilities"
          value={formValues ? formValues.abilities : ''}
          onChange={(e) => setFormValues({ ...formValues, abilities: e.currentTarget.value })}
          type="text"
          placeholder="Short description..."
        />
      </label>
      <SelectBox
        name="Group"
        data={wargearGroupData}
        selectedData={formValues ? formValues.group : ''}
        onSelect={(e) => setFormValues({
 ...formValues,
          group: e.target[e.target.selectedIndex].value,
})}
      />
      <label htmlFor="true">
        <span>Cost</span>
        <input
          name="cost"
          value={formValues ? formValues.cost.toString() : ''}
          onChange={(e) => setFormValues({ ...formValues, cost: Number(e.currentTarget.value) })}
          type="text"
          placeholder="0"
        />
      </label>
      <label htmlFor="true">
        <span>Damage</span>
        <input
          name="damage"
          value={formValues ? formValues.d : ''}
          onChange={(e) => setFormValues({ ...formValues, d: e.currentTarget.value })}
          type="text"
          placeholder="D6"
        />
      </label>
      <label htmlFor="true">
        <span>Strength</span>
        <input
          name="strength"
          value={formValues ? formValues.s : ''}
          onChange={(e) => setFormValues({ ...formValues, s: e.currentTarget.value })}
          type="text"
          placeholder="+1"
        />
      </label>
      <SelectBox
        name="Type"
        data={wargearTypeData}
        selectedData={formValues ? formValues.type : ''}
        onSelect={(e) => setFormValues({
 ...formValues,
          type: e.target[e.target.selectedIndex].value,
})}
      />
      <label htmlFor="true">
        <span>Range</span>
        <input
          name="range"
          value={formValues ? formValues.range : ''}
          onChange={(e) => setFormValues({ ...formValues, range: e.currentTarget.value })}
          type="text"
          placeholder="12"
        />
      </label>
    </section>
);

export default SimpleProfileForm;
