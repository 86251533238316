// @flow
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import HamburgerStyles from './HamburgerStyles';

const HamburgerMenu = ({
  children, desktopHidden, isOpen, setIsOpen,
}: {
  children: any, desktopHidden?: boolean, isOpen: boolean, setIsOpen: Function}) => {
  const toggleIsOpen = (e) => {
    e.preventDefault(e);
    setIsOpen(!isOpen);

    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('disable-scroll');
  };

  return (
    <HamburgerStyles desktopHidden={desktopHidden}>
      <button type="button" onClick={toggleIsOpen} className="menu-button">
        <GiHamburgerMenu className="icon" />
      </button>
      <div
        className={`menu-background${isOpen ? ' menu-background--is-open' : ''}`}
        onClick={toggleIsOpen}
        onKeyPress={toggleIsOpen}
        aria-label="button"
        role="button"
        tabIndex={0}
        title="background"
      />
      <div className={`menu${isOpen ? ' is-open' : ''}`}>
        { children }
      </div>
    </HamburgerStyles>
  );
};

HamburgerMenu.defaultProps = {
  desktopHidden: false,
};

export default HamburgerMenu;
