// @flow
import React from 'react';
import { FiEdit as Edit } from 'react-icons/fi';
import type { TeamListType } from '../../../../types';

export default ({
  list, setList,
}: { list: TeamListType, setList: Function}) => (
  <div>
    <section className="edit-team" style={{ display: 'block' }}>
      <label htmlFor="true">
        Max Points
        <input
          type="text"
          onFocus={(event) => event.target.select()}
          onChange={(e) => setList({ ...list, maxPoints: Number(e.target.value) })}
          placeholder="Max amount of points in list..."
          defaultValue={list.maxPoints}
        />
        <span className="edit-icon">
          <Edit color="#24C6A2" size="20" />
        </span>
      </label>
    </section>
    <section className="edit-team" style={{ display: 'block' }}>
      <label htmlFor="true">
        Leader Limit
        <input
          type="text"
          onFocus={(event) => event.target.select()}
          onChange={(e) => setList({ ...list, leaderLimit: Number(e.target.value) })}
          placeholder="Limit amount of leaders..."
          defaultValue={list.leaderLimit}
        />
        <span className="edit-icon">
          <Edit color="#24C6A2" size="20" />
        </span>
      </label>
      <label htmlFor="true">
        Specialists Limit
        <input
          type="text"
          onFocus={(event) => event.target.select()}
          onChange={(e) => setList({ ...list, specialistLimit: Number(e.target.value) })}
          placeholder="Limit amount of specialists..."
          defaultValue={list.specialistLimit}
        />
        <span className="edit-icon">
          <Edit color="#24C6A2" size="20" />
        </span>
      </label>
    </section>
  </div>
);
