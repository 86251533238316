import { Machine, assign, sendParent } from 'xstate';
import { STATES, ACTIONS, SERVICES } from '../../../constants';

export default ({
  team, specialists, wargear, abilities,
}, firebase, uid) => Machine({
  id: 'team',
  initial: STATES.GETTING_FACTION_UNITS,
  context: {
    team,
    units: undefined,
    specialists,
    wargear,
    abilities,
    factionUnits: undefined,
    lists: undefined,
    error: undefined,
  },
  states: {
    [STATES.GETTING_FACTION_UNITS]: {
      invoke: {
        id: SERVICES.GET_FACTION_UNITS,
        src: SERVICES.GET_FACTION_UNITS,
        onDone: {
          target: STATES.GETTING_TEAM_UNITS,
          actions: ACTIONS.SET_FACTION_UNITS,
        },
      },
    },
    [STATES.GETTING_TEAM_UNITS]: {
      invoke: {
        id: SERVICES.GET_TEAM_UNITS,
        src: SERVICES.GET_TEAM_UNITS,
        onDone: {
          target: STATES.GETTING_TEAM_LISTS,
          actions: ACTIONS.SET_TEAM_UNITS,
        },
        onError: STATES.FAILURE,
      },
      on: {
        [ACTIONS.UPDATE_TEAM_UNITS]: { actions: ACTIONS.SET_TEAM_UNITS },
      },
    },
    [STATES.GETTING_TEAM_LISTS]: {
      invoke: {
        id: SERVICES.GET_TEAM_LISTS,
        src: SERVICES.GET_TEAM_LISTS,
        onDone: {
          target: STATES.SUCCESS,
          actions: ACTIONS.SET_TEAM_LISTS,
        },
        onError: {
          target: STATES.FAILURE,
          actions: 'getTeamListsError',
        },
      },
    },
    [STATES.SUCCESS]: {
      initial: 'INITIAL',
      states: {
        INITIAL: {},
        [STATES.UNIT_OPTIONS_MENU]: {},
      },
      on: {
        [ACTIONS.OPEN_UNIT_OPTIONS_MENU]: { target: `.${STATES.UNIT_OPTIONS_MENU}` },
        [ACTIONS.CLOSE_UNIT_OPTIONS_MENU]: { target: '.INITIAL' },
      },
    },
    [STATES.FAILURE]: {},
  },
  on: {
    [ACTIONS.ADD_TEAM_UNIT]: { actions: ACTIONS.ADD_TEAM_UNIT },
    [ACTIONS.DELETE_TEAM_UNIT]: { actions: ACTIONS.DELETE_TEAM_UNIT },
    [ACTIONS.UPDATE_TEAM]: { actions: ACTIONS.UPDATE_TEAM },
    [ACTIONS.UPDATE_TEAM_UNIT]: { actions: ACTIONS.UPDATE_TEAM_UNIT },
    [ACTIONS.COPY_TEAM_UNIT]: { actions: ACTIONS.COPY_TEAM_UNIT },
    [ACTIONS.UPDATE_PARENT_TEAM]: { actions: ACTIONS.UPDATE_PARENT_TEAM },
    [ACTIONS.ADD_TEAM_LIST]: { actions: ACTIONS.ADD_TEAM_LIST },
    [ACTIONS.UPDATE_TEAM_LIST]: { actions: ACTIONS.UPDATE_TEAM_LIST },
    [ACTIONS.DELETE_TEAM_LIST]: { actions: ACTIONS.DELETE_TEAM_LIST },
    [ACTIONS.COPY_TEAM_LIST]: { actions: ACTIONS.COPY_TEAM_LIST },
  },
}, {
  actions: {
    [ACTIONS.ADD_TEAM_UNIT]: assign((ctx, event) => ({
      units: { ...ctx.units, [event.data.id]: event.data },
    })),
    [ACTIONS.DELETE_TEAM_UNIT]: assign((ctx, event) => {
      const units = Object.keys(ctx.units).reduce((updatedUnits, key) => {
        if (ctx.units[key] !== event.data) {
          return {
            ...updatedUnits,
            [key]: ctx.units[key],
          };
        }
        return updatedUnits;
      }, {});

      return {
        units,
      };
    }),
    [ACTIONS.COPY_TEAM_UNIT]: assign((ctx, event) => {
      const unit = event.data;

      return {
        units: {
          ...ctx.units,
          [unit.id]: unit,
        },
      };
    }),
    [ACTIONS.UPDATE_TEAM_UNIT]: assign((ctx, event) => {
      const unit = event.data;
      return {
        units: {
          ...ctx.units,
          [unit.id]: unit,
        },
      };
    }),
    [ACTIONS.SET_TEAM_UNITS]: assign({ units: (ctx, event) => event.data }),
    [ACTIONS.SET_FACTION_UNITS]: assign({
      factionUnits: (_ctx, event) => event.data,
    }),
    [ACTIONS.UPDATE_PARENT_TEAM]: sendParent((ctx, event) => ({
      type: ACTIONS.UPDATE_TEAM,
      data: event.data,
    })),
    [ACTIONS.UPDATE_TEAM]: assign({ team: (ctx, event) => event.data }),
    [ACTIONS.SET_TEAM_LISTS]: assign({ lists: (ctx, event) => event.data }),
    [ACTIONS.ADD_TEAM_LIST]: assign((ctx, event) => {
      const list = event.data;
      return {
        lists: {
          ...ctx.lists,
          [list.id]: list,
        },
      };
    }),
    [ACTIONS.UPDATE_TEAM_LIST]: assign((ctx, event) => {
      const list = event.data;
      return {
        lists: {
          ...ctx.lists,
          [list.id]: list,
        },
      };
    }),
    [ACTIONS.DELETE_TEAM_LIST]: assign((ctx, event) => {
      const listId = event.data;
      const lists = Object.keys(ctx.lists).reduce((updatedLists, key) => {
        if (key !== listId) {
          return {
            ...updatedLists,
            [key]: ctx.lists[key],
          };
        }
        return updatedLists;
      }, {});

      return {
        lists,
      };
    }),
    [ACTIONS.COPY_TEAM_LIST]: assign((ctx, event) => {
      const list = event.data;

      return {
        lists: {
          ...ctx.lists,
          [list.id]: list,
        },
      };
    }),
    getTeamListsError: assign({ error: (context, event) => event.data }),
    [ACTIONS.SET_SPECIALISTS]: assign({ specialists: (ctx, event) => event.data }),
  },
  services: {
    [SERVICES.GET_TEAM_UNITS]: (context) => firebase.getUserTeamUnits(uid, context.team.id).once('value')
      .then((snapshot) => snapshot.val()),
    [SERVICES.GET_FACTION_UNITS]: (context) => firebase.getFactionUnits(context.team.faction.id).once('value')
      .then((snapshot) => snapshot.val()),
    [SERVICES.GET_TEAM_LISTS]: (context) => firebase.getTeamLists(uid, context.team.id).once('value')
      .then((snapshot) => snapshot.val()),
  },
});
