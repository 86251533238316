import styled from 'styled-components';

export default styled.section`
width: 100%;
padding: 1rem 0;
background: var(--white);
font-size: 0.7em;

& > h3{
  font-size: 1.3em;
  margin: 0.2rem 0;
}

& > h4 {
  font-size: 1.1em;
}
`;
