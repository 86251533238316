// @flow
import React from 'react';
import H from '../../../styles/Headings';
import UnitAbilitiesStyles from './UnitAbilitiesStyles';
import { useRosterContext } from '../EditTeam/context';

const getAbilityDescription = (name, abilities) => {
  const id = Object.keys(abilities).find(
    (key) => abilities[key].name === name,
  );
  return abilities[id].descr;
};

const UnitAbilities = ({ abilities: unitAbilities }: {
  abilities: Array<string>}) => {
  const [current] = useRosterContext();
  const {
    abilities,
  } = current.context;
  const abilitiesMarkup = unitAbilities.map((ability) => (
    <li key={ability}>
      <H as="h6">{ability}</H>
      <p>{getAbilityDescription(ability, abilities)}</p>
    </li>
  ));
  return (
    <UnitAbilitiesStyles>
      <H as="h5">Abilities</H>
      <ul>
        {abilitiesMarkup}
      </ul>
    </UnitAbilitiesStyles>
  );
};

export default UnitAbilities;
