import { WARGEAR_GROUP, WARGEAR_TYPE } from '../constants';

export const wargearGroupData = [
  WARGEAR_GROUP.MELEE,
  WARGEAR_GROUP.MIXED,
  WARGEAR_GROUP.OTHER,
  WARGEAR_GROUP.RANGED,
];

export const wargearTypeData = [
  WARGEAR_GROUP.MELEE,
  WARGEAR_TYPE.OTHER,
  WARGEAR_TYPE.ASSAULT_1,
  WARGEAR_TYPE.ASSAULT_2,
  WARGEAR_TYPE.ASSAULT_3,
  WARGEAR_TYPE.ASSAULT_4,
  WARGEAR_TYPE.ASSAULT_D3,
  WARGEAR_TYPE.ASSAULT_D6,
  WARGEAR_TYPE.ASSAULT_2D6,
  WARGEAR_TYPE.PISTOL_1,
  WARGEAR_TYPE.PISTOL_2,
  WARGEAR_TYPE.PISTOL_3,
  WARGEAR_TYPE.PISTOL_4,
  WARGEAR_TYPE.PISTOL_5,
  WARGEAR_TYPE.PISTOL_D3,
  WARGEAR_TYPE.PISTOL_D6,
  WARGEAR_TYPE.PISTOL_STAR,
  WARGEAR_TYPE.RAPID_FIRE_1,
  WARGEAR_TYPE.RAPID_FIRE_2,
  WARGEAR_TYPE.RAPID_FIRE_3,
  WARGEAR_TYPE.HEAVY_1,
  WARGEAR_TYPE.HEAVY_2,
  WARGEAR_TYPE.HEAVY_3,
  WARGEAR_TYPE.HEAVY_4,
  WARGEAR_TYPE.HEAVY_6,
  WARGEAR_TYPE.HEAVY_D3,
  WARGEAR_TYPE.HEAVY_D6,
  WARGEAR_TYPE.GRENADE_D3,
  WARGEAR_TYPE.GRENADE_D6,
  WARGEAR_TYPE.GRENADE_1,
];
