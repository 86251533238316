import styled from 'styled-components';

export const OptionsProfileStyles = styled.div`
padding: 0 0.3rem 0.3rem,
`;

export default styled.article`
padding: 0.3rem 0;
font-size: 0.7rem;

ul {
  & > li {
    display: inline-flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0.2rem 0.2rem 0rem;
  }

  & > li:nth-child(even) {
    background: var(--base080);
  }

  & > li > div:first-child {
    flex: 1;
  }

  .wargear-item-row--wide {
    display: block;
    width: 100%;
  }
}

button {
  border: none;
  margin: 0 0.1rem;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline:0;
}

button:hover > svg {
    opacity: 0.6;
  }

.error {
  padding: 2px 2px 1px;
    font-size: 0.7rem;
    color: var(--red);
}

@media screen and (min-width: 550px) {
  ul {
    & > li {
      font-size: 1.2em;
      padding: 0.5rem 0.5rem 0.3rem;
    }
  }
  svg {
    transform: scale(1.5);
  }    
}

.error {
  padding: 2px 2px 1px;
    font-size: 0.7rem;
    color: var(--red);
}
`;
