import styled from 'styled-components';

export default styled.div`
padding: 0.5rem;

& .add-list-button {
  background: var(--red);
  padding: 0.5rem;
  color: var(--base080);

  &:disabled {
    background: var(--base);
    color: var(--base110);
  }
}
`;
