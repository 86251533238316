import styled from 'styled-components';

export default styled.div`

&.is-visible {
  .menu-background, .menu {
    display: block;
  }
}

.menu-background {
  z-index: 1;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

}

.menu {
  z-index: 100;
  display: none;
  position: absolute;
  width: 100vw;
  right: 0;
  background: var(--white);
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
}

.menu-inner {
  padding: 0;
}

.list-edit-menu {
  background: var(--white);
  color: var(--base130);
  display: flex;
  flex-direction: column;

  & > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    color: var(--base130);
    border-bottom: 1px solid var(--base);

    &.btn-disabled {
      background: var(--base090);
      color: var(--base);

      & .menu-icon {
        color: var(--base);
      }
    }

    & .menu-icon {
      color: var(--base130);
      margin: 0 0.5rem;
    }
  }
}

@media screen and (min-width: 550px) {
  .menu {
    right: 20px;
    width: 350px;
  }

  & .list-edit-menu {
    & > button {
      padding: 1rem;
      transition: background-color 0.2s ease-in-out;
    }

    & > button:hover {
      background: var(--base); 
      border-color: var(--base110);
    }
  }
}
`;
