import styled from 'styled-components';

export default styled.article`
display: flex;
align-items: center;
padding: 0.1rem 0.3rem;
border-bottom: 1px solid var(--red);

& h5 {
  white-space: nowrap;
  margin-right: 5px;
}
`;
