// @flow
import React, { useState, useEffect } from 'react';
import { FormStyles } from '../../../styles';
import type { AbilitiesType } from '../../../types';
import SelectBox from '../common/SelectBox';
import AbilitiesProfile from './AbilitiesProfile';

const style = {
  maxWidth: 'none',
  width: '100%',
};

const formatRules = (rules) => rules.reduce((obj, rule) => {
  const { id, name, descr } = rule;
  return { ...obj, [id]: { name, descr } };
}, {});

const AddFactionForm = ({ onSubmit, selectedAbility }: {
  onSubmit: Function,
  selectedAbility?: AbilitiesType | null
}) => {
  const [name, setName] = useState('');
  const [descr, setDescr] = useState('');
  const [rules, setRules] = useState([]);
  const [formType, setFormType] = useState('Add');
  const [amountOfRules, setAmountOfRules] = useState(0);
  const [abilityMarkup, setAbilityMarkup] = useState('');
  const isValid = !!name;

  const resetForm = () => {
    setName('');
    setDescr('');
  };

  useEffect(() => {
    if (selectedAbility) {
      setName(selectedAbility.name);
      setDescr(selectedAbility.descr);

      const rulesCount = selectedAbility.rules ? Object.keys(selectedAbility.rules).length : 0;

      if (rulesCount) {
        const selectedAbilityRules = Object.keys(selectedAbility.rules).map((key) => ({
          id: key,
          name: selectedAbility.rules[key].name,
          descr: selectedAbility.rules[key].descr,
        }));

        setRules(selectedAbilityRules);
        setAmountOfRules(rulesCount);
      }

      setFormType('Edit');
    }
  }, [selectedAbility]);

  useEffect(() => {
    if (amountOfRules) {
      const profiles = [...Array(amountOfRules).keys()];
      const markup = profiles.map((i) => (
        <AbilitiesProfile key={i.toString()} setRules={setRules} rules={rules} id={i} />
      ));
      setAbilityMarkup(markup);
    }
  }, [amountOfRules, rules]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    let ability = { name, descr, rules: formatRules(rules) };
    if (selectedAbility) {
      ability = { ...ability, id: selectedAbility.id };
    }

    onSubmit(ability, formType);

    resetForm();
  };

  return (
    <FormStyles onSubmit={(e) => onFormSubmit(e)} style={style}>
      <label htmlFor="true">
        <span>Ability Name*</span>
        <input
          name="name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          type="text"
          placeholder="And They Shall Know No Fear"
        />
      </label>
      <label htmlFor="true">
        <span>Description</span>
        <textarea
          name="descr"
          value={descr}
          onChange={(e) => setDescr(e.currentTarget.value)}
          type="text"
          placeholder="You can re-roll failed Nerve tests for this model."
        />
      </label>
      <SelectBox
        name="Amount of rules"
        data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        selectedData={amountOfRules}
        onSelect={(e) => setAmountOfRules(Number(e.target[e.target.selectedIndex].value))}
      />
      {abilityMarkup}
      <div>
        <button disabled={!isValid || formType !== 'Add'} type="submit">
          Add Ability
        </button>
        <button disabled={!isValid || formType !== 'Edit'} type="submit">
          Edit Ability
        </button>
      </div>
      {/* {error && <p className="error">{error.message}</p>} */}
      <p>Exempel på Ability med rules är Combat Drugs (Swenglish ™)</p>
    </FormStyles>
  );
};

AddFactionForm.defaultProps = {
  selectedAbility: null,
};

export default AddFactionForm;
