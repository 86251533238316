// @flow
import React from 'react';
import { type WargearType } from '../../../types';
import { DualProfileRow, ProfileRow } from './SimpleProfile';
import WargearStyles from './SimpleProfileStyles';

const MultipleProfile = ({ profiles, abilities }: {
  profiles: { [string]: WargearType },
  abilities?: string | null
}) => {
  const profilesMarkup = Object.keys(profiles).map((key) => {
    const profile = profiles[key];
    const isDual = !!profile.profile;

    return isDual
      ? <DualProfileRow key={key} item={{ ...profile, name: key }} />
      : (<tbody key={key}><ProfileRow item={{ ...profile, name: key }} /></tbody>);
  });
  return (
    <article className="wargear-profile">
      <WargearStyles>
        <thead>
          <tr>
            <th>Weapon</th>
            <th>Range</th>
            <th>Type</th>
            <th>S</th>
            <th>AP</th>
            <th>D</th>
            <th>Abilities</th>
          </tr>
        </thead>
        {abilities && (
          <tbody>
            <tr>
              <td colSpan="7" className="left italic">{abilities}</td>
            </tr>
          </tbody>
)}
        {profilesMarkup}
      </WargearStyles>
    </article>
  );
};

MultipleProfile.defaultProps = {
  abilities: null,
};

export default MultipleProfile;
