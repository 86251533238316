// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
  viewBox: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 1000 1000',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_3_copy_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox={viewBox}
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path d="M649.9,485c30.1,73.3-8.1,153-63.1,136l1.9,17h60.6l297.5-455.8l-109.4-1.1L649.9,485z" />
      <polygon points="879.4,299.3 847.9,344.8 849.6,344.8 931.9,344.8 985,278.9 893.6,278.9" />
      <polygon points="823,385.3 795.9,424.4 797.3,424.4 892.6,424.4 945.7,358.6 841.6,358.6" />
      <polygon points="746.4,502 852.2,502 905.3,436.1 789.7,436.1 788.4,436.1 742.8,502" />
      <polygon points="739.5,512.6 738.5,512.6 692.8,578.5 696.2,578.5 815,578.5 868.1,512.6" />
      <polygon points="657.2,638 690.7,638 723.6,589.1 689.6,589.1 675.7,609.8 656.7,638" />
      <polygon points="527.1,834.6 555.8,834.6 606.8,763.4 575.9,763.4" />
      <polygon points="435.7,763.4 500.5,862.2 565.3,763.4" />
      <path d="M412.3,638l1.9-17c-62.4,23.4-93.3-93.5-63.1-136L163.7,181.1l-109.4,1.1L351.8,638H412.3z" />
      <path d="M500.5,232.1c141.3,10.6,192.3,95.6,150.9,234.8L860.7,128H140.3l202.9,335.8C301.8,324.6,359.2,242.8,500.5,232.1z" />
      <polygon points="151.4,344.8 153.1,344.8 121.6,299.3 107.4,278.9 16,278.9 69.1,344.8" />
      <polygon points="203.7,424.4 205.1,424.4 178,385.3 159.4,358.6 55.3,358.6 108.4,424.4" />
      <polygon points="258.2,502 212.6,436.1 211.3,436.1 95.7,436.1 148.8,502 254.6,502" />
      <polygon points="304.8,578.5 308.2,578.5 262.5,512.6 261.5,512.6 132.9,512.6 186,578.5" />
      <polygon points="343.8,638 344.3,638 325.3,609.8 311.4,589.1 277.4,589.1 310.3,638" />
      <polygon points="394.2,763.4 445.2,834.6 473.9,834.6 425.1,763.4" />
      <path d="M710.9,629.5l-6.4,14.9H589.8l1.1,42.5h-187l4.2-42.5H218.9c-16.4,12.6-44.4,20.9-79.7,26.6c8.6,20.7,24.2,35.5,49.9,41.4
l511.1,6.4c13.9-4.3-3.2,66.9,4.2,76.5c24.4-24.4,18.1-89.2,18.1-89.2c7.4,1.1,12.8-68,6.4-76.5H710.9z"
      />
      <path d="M129.7,674.1L84,680.5c32.7,38.1,71.9,65.7,125.4,70.1l419.7,6.4l12.8-30.8l-452.6-6.4
C166.9,715.6,146.7,702.7,129.7,674.1z"
      />
      <path d="M748.1,652.9h-4.2c-3.5,0-6.4,2.9-6.4,6.4v35.1c0,3.5,2.9,6.4,6.4,6.4h4.2c3.5,0,6.4-2.9,6.4-6.4v-35.1
C754.4,655.7,751.6,652.9,748.1,652.9z"
      />
      <path d="M774.1,652.9h-4.2c-3.5,0-6.4,2.9-6.4,6.4v35.1c0,3.5,2.9,6.4,6.4,6.4h4.2c3.5,0,6.4-2.9,6.4-6.4v-35.1
C780.5,655.7,777.6,652.9,774.1,652.9z"
      />
      <path d="M800.1,652.9h-4.2c-3.5,0-6.4,2.9-6.4,6.4v35.1c0,3.5,2.9,6.4,6.4,6.4h4.2c3.5,0,6.4-2.9,6.4-6.4v-35.1
C806.5,655.7,803.6,652.9,800.1,652.9z"
      />
      <path d="M826.2,652.9h-4.2c-3.5,0-6.4,2.9-6.4,6.4v35.1c0,3.5,2.9,6.4,6.4,6.4h4.2c3.5,0,6.4-2.9,6.4-6.4v-35.1
C832.5,655.7,829.7,652.9,826.2,652.9z"
      />
      <path d="M852.2,652.9h-4.2c-3.5,0-6.4,2.9-6.4,6.4v35.1c0,3.5,2.9,6.4,6.4,6.4h4.2c3.5,0,6.4-2.9,6.4-6.4v-35.1
C858.6,655.7,855.7,652.9,852.2,652.9z"
      />
      <path d="M909.9,647.7l-37.5,0.9l-6.2,10.5c-0.1,0.1-0.2,0.2-0.2,0.4v30.1c0,5.1,3.9,9.5,9,10.1l9.1,1.1l0.2,2.6
c0.5,5.1,4.8,9,9.9,9h9.7c4.9,0,9-3.9,9.2-8.8l2.5-50C915.8,650.3,913.2,647.6,909.9,647.7z"
      />
      <path d="M620.7,612c6.8-5.6,56.8-73,20-115.5c-9.2-10.6,36-89.3,12.2-158.9c-28-81.6-110.6-94.4-146.4-96c0,0-3.1-0.2-8.2-0.2
c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c-5.3,0-8.2,0.2-8.2,0.2c-35.7,1.7-118.4,14.4-146.3,96c-23.9,69.7,21.3,148.3,12.2,158.9
c-36.8,42.5,13.2,110,20,115.5c12.3,10,51.4-10.3,45.2,0.2c-4.7,8-9,68.3-9,68.3H424l5.8-21l10.4-0.1l2,21.1h21.1l2-20.1l7-1
l1,21.1h17.1l0.8-21.7h13.9l0.8,21.7h17.1l1-21.1l7,1l2,20.1h21.1l2-21.1l10.4,0.1l5.8,21h12.1c0,0-4.3-60.3-9-68.3
C569.4,601.7,608.4,622,620.7,612z M618.4,474.7c5.9,0,10.6,4.8,10.6,10.6c0,5.9-4.8,10.6-10.6,10.6s-10.6-4.8-10.6-10.6
C607.8,479.5,612.6,474.7,618.4,474.7z M498.2,341.6c7.6,0,13.8,6.2,13.8,13.8s-6.2,13.8-13.8,13.8s-13.8-6.2-13.8-13.8
S490.6,341.6,498.2,341.6z M512.7,410.6c0,7-5.7,12.8-12.7,12.8s-12.8-5.7-12.8-12.8c0-7,5.7-12.8,12.8-12.8
S512.7,403.6,512.7,410.6z M370.9,496c-5.9,0-10.6-4.8-10.6-10.6c0-5.9,4.8-10.6,10.6-10.6s10.6,4.8,10.6,10.6
C381.5,491.2,376.7,496,370.9,496z M431.4,576.4c-29.3,0-53.1-23.8-53.1-53.1c0-29.3,23.8-53.1,53.1-53.1s53.1,23.8,53.1,53.1
C484.6,552.6,460.8,576.4,431.4,576.4z M522.6,614.2c-0.1,1.4-1.8,1.9-2.7,0.9c-2.1-2.7-5.1-5.8-9-8.7c-4.2-3.1-8.2-5-11.3-6.1
c-3.1,1.1-7.1,3.1-11.3,6.1c-3.9,2.9-6.8,6-9,8.7c-0.9,1.1-2.6,0.5-2.7-0.9c-0.7-10.3-0.2-31.4,15.3-46.7c2-2,4.6-3.7,7.6-5.4
c3,1.7,5.6,3.4,7.6,5.4C522.8,582.9,523.3,604,522.6,614.2z M542.6,557.5c-9.4-5.3-16.3-11.6-21.1-17c-1.8-2.1-1.3-5.3,1.2-6.5
c9.7-5.1,21.5-10.3,35.4-14.6c17.2-5.4,32.7-7.8,45.1-9c0.6-0.1,1.2,0,1.8,0.3c10.3,3.8,13.6,10,14.6,11.8
c5.8,12.2-1.7,31.7-21.5,44.1c-0.5,0.3-1.1,0.5-1.7,0.6C587.4,568.4,565.2,570.2,542.6,557.5z"
      />
      <circle cx="498.4" cy="355.4" r="6.4" />
      <circle cx="618.4" cy="485" r="6.4" />
      <circle cx="370.9" cy="485" r="6.4" />
      <circle cx="431.4" cy="523.2" r="39.3" />
    </g>
  </svg>
);
