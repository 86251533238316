
// @flow
import type {
  SpecialistListType, WargearListType, WargearType, SpecialistType,
} from '../../../types';

export const getSpecialistId = (specialist: string,
  specialists: SpecialistListType) => Object
  .keys(specialists).find((key) => specialists[key].name === specialist);

export const getSpecialist = (specialist: string,
  specialists: SpecialistListType): SpecialistType | null => {
  const id = getSpecialistId(specialist, specialists);
  return id ? specialists[id] : null;
};

export const getWargearId = (specialist: string,
  wargear: WargearListType) => Object
  .keys(wargear).find((key) => wargear[key].name === specialist);

export const getWargear = (wargearName: string,
  wargear: WargearListType): WargearType | null => {
  const id = getWargearId(wargearName, wargear);
  return id ? wargear[id] : null;
};
