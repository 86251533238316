// @flow
import React, { useState, useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Icon } from '../utils/getFactionIcon';
import {
  SelectStyles, OptionsStyles, SelectBackground, SelectWrapper,
} from './SelectFactionsStyles';
import type { FactionListType, FactionType } from '../../../types';

const Option = ({
  faction, itemClass, onSelectFaction, onDisplayOptions, isOpen,
}: {
  faction: FactionType,
  itemClass?: string,
  onSelectFaction?: Function,
  onDisplayOptions?: Function,
  isOpen?: boolean,
 }) => (
   <OptionsStyles className={itemClass}>
     {(itemClass === 'selected' || itemClass === 'none-selected')
      ? (
        <button type="button" className={`selected-button${isOpen ? ' is-open' : ''}`} onClick={onDisplayOptions}>
          <Icon name={faction.name} className="icon" />
          <span>{faction.name}</span>
          <IoIosArrowDown className="arrow-icon" />
        </button>
      ) : (
        <button type="button" className="item-button" onClick={() => (onSelectFaction ? onSelectFaction(faction.id) : null)}>
          <Icon name={faction.name} />
          <span>
            {faction.name}
            {' '}
          </span>
        </button>
      )}
   </OptionsStyles>
);

Option.defaultProps = {
  itemClass: 'item',
  onSelectFaction: null,
  onDisplayOptions: null,
  isOpen: false,
};

const noFactionSelected: FactionType = {
  id: 'none-selected',
  name: '- None selected -',
  factionKeywords: [],
};

const SelectFactions = ({ factions, onSelect, selected }: {
  factions: FactionListType,
  onSelect: (id: FactionType) => void,
  selected?: string | null
}) => {
  const [selectedFaction, setSelectedFaction] = useState(selected);
  const [displayingOptions, setDisplayingOptions] = useState(false);
  const onDisplayOptions = () => {
    setDisplayingOptions(!displayingOptions);
  };

  useEffect(() => {
    if (selected) {
      setSelectedFaction(selected);
    }
  }, [selected]);

  const onSelectFaction = (id) => {
    setSelectedFaction(id);
    setDisplayingOptions(false);

    onSelect(factions[id]);
  };
  const selectedMarkup = selectedFaction ? (
    <Option
      key={factions[selectedFaction].id}
      faction={factions[selectedFaction]}
      onDisplayOptions={onDisplayOptions}
      isOpen={displayingOptions}
      itemClass="selected"
    />
  ) : (
    <Option
      key={noFactionSelected.id}
      faction={noFactionSelected}
      itemClass={noFactionSelected.id}
      onDisplayOptions={onDisplayOptions}
      isOpen={displayingOptions}
    />
  );

  const filtered = Object.keys(factions)
    .filter((key) => key !== selectedFaction)
    .reduce((obj, key) => [...obj, { ...factions[key] }],
      [])
    .map((faction) => (
      <Option
        key={faction.id}
        faction={faction}
        onSelectFaction={onSelectFaction}
      />
    ));

  return (
    <SelectWrapper>
      {displayingOptions && (
        <SelectBackground
          onClick={() => setDisplayingOptions(false)}
          onKeyPress={() => setDisplayingOptions(false)}
          aria-label="button"
          role="button"
          tabIndex={0}
          title="background"
        />
      )}
      <SelectStyles>
        {selectedMarkup}
        {displayingOptions && (
          <div className="list">
            {filtered}
          </div>
        )}
      </SelectStyles>
    </SelectWrapper>
  );
};

SelectFactions.defaultProps = {
  selected: null,
};

export default SelectFactions;
