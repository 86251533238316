
import styled from 'styled-components';

export const ArticleStyle = styled.article`
 margin-bottom: '5px'
 `;

export default styled.table`
width: 100%;
font-size: 0.8em;
text-align: center;
text-transform: capitalize;
border-collapse: collapse;

& tr th {
  padding-bottom: 2px;
  border-bottom: 1px solid red;
} 

& tr td {
  padding: 0.1rem 0 0.1rem 0.3rem;
}
& tr td.first {
  text-align: left;
}

@media screen and (min-width: 550px) {
  font-size: 1em;

  & tr td {
    padding: 0.1rem;
  }

  & tr th {
    padding: 0.1rem 0 0.1rem 0.3rem;
  } 
}
`;
