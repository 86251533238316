import React from 'react';
import { withAuthorization, AuthUserContext } from '../Session';
import { PasswordForgetForm } from './PasswordForget';
import PasswordChangeForm from './PasswordChange';
import Settings from './Settings';
import { Content } from '../../styles';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {() => (
      <Content title="Account" pt>
        <Settings />
        <PasswordForgetForm />
        <PasswordChangeForm />
      </Content>
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AccountPage);
