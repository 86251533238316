// @flow

import React, { useState, useEffect } from 'react';
import type {
  UnitListType, TeamListType,
} from '../../../../../types';
import ErrorsStyles from './Errors.styles';
import {
  getListUnits, getSpecialistCount, getListUnitTypeCount,
  hasTooManyUnitTypesMessage, hasTooManyUniqueItems, isOverListBudget,
  hasLeader, getListDependencies, getSpecialistsTaken,
} from './Errors.utils';

const Errors = ({ list, units }: {
  list: TeamListType,
  units: UnitListType,
}) => {
  const [overBudget, setOverBudget] = useState(null);
  const [noLeader, setNoLeader] = useState(null);
  const [tooManySpecialists, setTooManySpecialists] = useState(null);
  const [tooManyUniqueItems, setTooManyUniqueItems] = useState(null);
  const [tooManyUnitTypes, setTooManyUnitTypes] = useState([]);
  const [hasDependencies, setHasDependencies] = useState([]);
  const [hasDuplicateSpecialists, setHasDuplicateSpecialists] = useState([]);
  const listUnits = getListUnits(units, list);
  const listHasLeader = hasLeader(listUnits);
  const amountOfSpecialists = getSpecialistCount(listUnits);
  const unitTypeCount = getListUnitTypeCount(listUnits);
  const getManyUnitTypes = hasTooManyUnitTypesMessage(unitTypeCount);
  const listDependencies = getListDependencies(listUnits);
  const duplicateSpecialists = getSpecialistsTaken(listUnits);

  useEffect(() => {
    if (!listHasLeader) {
      setNoLeader('You must have a leader');
    } else {
      setNoLeader(null);
    }
    if (isOverListBudget(list.totalCost, list.maxPoints)) {
      setOverBudget('List is over budget');
    } else {
      setOverBudget(null);
    }
    if (amountOfSpecialists > list.specialistLimit) {
      setTooManySpecialists('Too many specialists');
    } else {
      setTooManySpecialists(null);
    }

    if (hasTooManyUniqueItems(units, list)) {
      setTooManyUniqueItems('You have more than one unique item');
    } else {
      setTooManyUniqueItems(null);
    }

    if (getManyUnitTypes.length) {
      setTooManyUnitTypes(getManyUnitTypes);
    } else {
      setTooManyUnitTypes([]);
    }

    if (listDependencies.length) {
      setHasDependencies(listDependencies);
    } else {
      setHasDependencies([]);
    }

    if (duplicateSpecialists.length) {
      setHasDuplicateSpecialists(duplicateSpecialists);
    } else {
      setHasDuplicateSpecialists([]);
    }
  }, [list]);

  return (
    <ErrorsStyles>
      <p className="error">
        {[noLeader, overBudget, tooManySpecialists, tooManyUniqueItems, ...tooManyUnitTypes, ...hasDependencies, ...hasDuplicateSpecialists].filter(Boolean).join(', ')}
      </p>
    </ErrorsStyles>
  );
};

export default Errors;
