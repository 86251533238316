// @flow
import React, { useState, useEffect } from 'react';
import { FiInfo as Info } from 'react-icons/fi';
import H from '../../../styles/Headings';
import type { WargearType, WargearListType } from '../../../types';
import SimpleProfile from './SimpleProfile';
import MultipleProfile from './MultipleProfile';
import WargearStyles from './WargearStyles';
import { getWargear } from './EditUnit.utils';

const wargearCost = (wargear) => ('cost' in wargear
  ? wargear.cost : Object.keys(wargear.profile)
    .reduce((total, key) => total + wargear.profile[key].cost, 0));

const WargearOption = ({
  profile, activeProfile, showProfile, toggleProfile, updateUnit,
}) => {
  const profileCost = 'cost' in profile ? profile.cost : wargearCost(profile);
  const isProfileMax = profile.max === 1 && 'Only one per Kill Team';
  const displayProfile = !profile.profile
    ? <SimpleProfile profile={profile} />
    : <MultipleProfile profiles={profile.profile} abilities={profile.abilities} />;
  return (
    <li>
      <div>
        <label htmlFor={profile.name}>
          {!profile.passive && (
          <input
            id={profile.name}
            type="radio"
            name="wargear"
            value={profile.name}
            checked={profile.name === activeProfile.name}
            onChange={(e) => updateUnit(e, 'primaryWeapons')}
          />
            )}
          {profile.name}
          <strong>{` ${profileCost}pts`}</strong>
        </label>
        <span className="error">
          {isProfileMax}
        </span>
      </div>
      <div>
        <button
          type="button"
          onClick={() => toggleProfile(profile.name)}
        >
          <Info color="#2499C6" size="20" />
        </button>
      </div>
      {showProfile === profile.name
          && (
            <div className="wargear-item-row--wide">
              {displayProfile}
            </div>
          )}
    </li>
  );
};

const Wargear = ({
  wargear,
  activeWeapon,
  primaryWeapons,
  updateUnit,
}: {
  wargear: WargearListType,
  activeWeapon?: WargearType,
  primaryWeapons: Array<string>,
  updateUnit: Function,
}) => {
  const [showProfile, setShowProfile] = useState();
  const toggleProfile = (id) => {
    const profileId = showProfile === id ? null : id;
    setShowProfile(profileId);
  };
  useEffect(() => {
    if (activeWeapon) {
      setShowProfile(activeWeapon.name);
    }
  }, [activeWeapon]);

  const wargearOptions = primaryWeapons.map((name) => {
    const profile = getWargear(name, wargear);

    return (
      <WargearOption
        key={name}
        profile={profile}
        activeProfile={activeWeapon}
        showProfile={showProfile}
        toggleProfile={toggleProfile}
        updateUnit={updateUnit}
      />
    );
  });

  return (
    <WargearStyles>
      <H as="h5" pb>Wargear</H>
      <ul>
        {wargearOptions}
      </ul>
    </WargearStyles>
  );
};

Wargear.defaultProps = {
  activeWeapon: null,
};

export default Wargear;
