// @flow

import React from 'react';
import DisplayUnit from '../common/DisplayUnit';
import PrintTeamStyles from './PrintTeam.styles';
import Ablitites from '../DisplayTeam/Abilities';
import H from '../../../styles/Headings';
import type {
  PrinterFriendlyTeam, PrinterFriendlyUnit, AbilitiesType,
} from '../../../types';

type Props = {
  printerFriendlyTeam: PrinterFriendlyTeam
};

export const getUniqueAbilities = (units: Array<PrinterFriendlyUnit>): Array<AbilitiesType> => {
  const totalAbilities: Array<AbilitiesType> = units.reduce((list, unit) => {
    const abilities = unit.abilities ? unit.abilities : [];
    return [...list, ...abilities];
  },
  []);
  return totalAbilities
    .filter((value, index, self) => self.map((x) => x.name).indexOf(value.name) === index);
};

class PrintTeam extends React.Component<Props> {
  renderUnits() {
    const { printerFriendlyTeam: { units } } = this.props;
    // $FlowFixMe
    return units.map((unit) => <DisplayUnit key={unit.id} unit={unit} />);
  }

  render() {
    const { printerFriendlyTeam, printerFriendlyTeam: { units } } = this.props;
    const abilitiesList = units ? getUniqueAbilities(units) : [];

    return (
      <PrintTeamStyles>
        <H as="h2">
          {`${printerFriendlyTeam.name} - ${printerFriendlyTeam.cost} points`}
        </H>
        {this.renderUnits()}
        {abilitiesList.length > 0 && <Ablitites abilities={abilitiesList} />}
      </PrintTeamStyles>
    );
  }
}

export default PrintTeam;
