import styled from 'styled-components';

export const SelectWrapper = styled.div`
width: 100%;
max-width: 400px;
`;

export const SelectBackground = styled.div`
z-index: 1;
position: fixed;
display: block;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
`;

export const SelectStyles = styled.div`
z-index: 10;

& .list {
  position: absolute;
  left: 0;
  width: 100vw;
  max-width: 400px;
  max-height: 510px;
  overflow: scroll;
  background: var(--white);
  z-index: 100;
  box-shadow: 0 0 1px var(--base);
}

@media screen and (min-width: 550px) {
  & .list {
    left: auto;
  }
}
`;

export const OptionsStyles = styled.div`
display: flex;
border-bottom: 1px solid var(--base080);

&:first-child {
  border-top: none;
}

& .arrow-icon {
  width: 30px;
  height: 30px;
}

& .icon {
  margin: 0 0.5rem 0 0;
  width: 40px;
  height: 30px;
  fill: var(--base110);
  stroke: var(--base130);
  stroke-width: 8;
}

& .selected-button, 
& .item-button {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 1.2em;
  text-transform: capitalize;
  padding: 0.8rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

& .selected-button {
  padding: 0.5rem 0.8rem;
}

& .selected-button {
  & .icon {
    display: none;
  } 

  & span {
    flex: 1;
  }

  &.is-open {
    & .arrow-icon {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: var(--base080);
  }
}

& .item-button:hover {
  background: var(--base080);

  & .icon {
    stroke: var(--base130);
    stroke-width: 6;
    fill: var(--red)
  }
}
`;
