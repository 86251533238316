import styled from 'styled-components';

export default styled.article`
padding: 0.3rem 0;

ul {
  list-style-type: none;
  font-size: 0.8rem;
  padding-top: 5px;
  
  & > li {
    padding: 0.2rem;
  }
}

p {
  padding: 0 0 0.2rem;
}
`;
