// @flow
import React, { useState, useEffect } from 'react';
import { FormStyles } from '../../../styles';
import type { FactionType } from '../../../types';

const AddFactionForm = ({ onSubmit, selectedFaction }: {
  onSubmit: Function,
  selectedFaction?: FactionType | null
}) => {
  const [name, setName] = useState('');
  const [descr, setDescr] = useState('');
  const [factionKeywords, setKeywords] = useState([]);
  const [formType, setFormType] = useState('Add');
  const isValid = !!name && !!factionKeywords;

  const parseKeywords = (keywords) => keywords.split(', ');

  const resetForm = () => {
    setName('');
    setDescr('');
    setKeywords('');
  };

  useEffect(() => {
    if (selectedFaction) {
      setName(selectedFaction.name);
      setDescr(selectedFaction.descr);

      if (selectedFaction.factionKeywords) {
        setKeywords(selectedFaction.factionKeywords);
      } else {
        setKeywords([]);
      }

      setFormType('Edit');
    }
  }, [selectedFaction]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    let faction = { name, descr, factionKeywords };
    if (selectedFaction) {
      faction = { ...faction, id: selectedFaction.id };
    }

    onSubmit(faction, formType);

    resetForm();
  };

  return (
    <FormStyles onSubmit={(e) => onFormSubmit(e)}>
      <label htmlFor="true">
        <span>Faction Name*</span>
        <input
          name="name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          type="text"
          placeholder="Adeptus Astartes"
        />
      </label>
      <label htmlFor="true">
        <span>Description</span>
        <input
          name="descr"
          value={descr}
          onChange={(e) => setDescr(e.currentTarget.value)}
          type="text"
          placeholder="The Adeptus Astartes are the Imperium’s Angels of Death..."
        />
      </label>
      <label htmlFor="true">
        <span>Faction Keywords*</span>
        <input
          name="factionKeywords"
          value={factionKeywords}
          onChange={(e) => setKeywords(parseKeywords(e.currentTarget.value))}
          type="text"
          placeholder="ADEPTUS ASTARTES, SPACE WOLVES"
        />
      </label>
      <div>
        <button disabled={!isValid || formType !== 'Add'} type="submit">
          Add Faction
        </button>
        <button disabled={!isValid || formType !== 'Edit'} type="submit">
          Edit Faction
        </button>
      </div>
      {/* {error && <p className="error">{error.message}</p>} */}
    </FormStyles>
  );
};

AddFactionForm.defaultProps = {
  selectedFaction: null,
};

export default AddFactionForm;
