import styled from 'styled-components';

export default styled.table`
  margin-top: 5px;
  width: 100%;
  font-size: 0.8em;
  border-collapse:collapse;
  font-size: 1.1em;

  thead tr th {
    text-align: center;
    border-bottom: 1px solid var(--red);
  }

  tbody tr td:first-child {
    width: 130px;
  }
  & td {
    vertical-align: top;
    text-align: center;

    &.italic {
      font-style: italic;
    }
  }

  & tr td:last-child {
    max-width: 100px;
  }

  & tr:nth-child(even) {
    background: var(--base080);
  }

  & td.left {
    text-align: left;
  }

  & td.limited {
    width: 75px;
  }

  & td.small {
    width: 40px;
  }
`;
