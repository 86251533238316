// @flow
import React, { useState, useEffect } from 'react';
import { FormStyles } from '../../../styles';
import {
  AbilitiesForm, StatsForm, SpecialistsForm, GeneralForm,
} from './Forms';
import type { FactionUnitType, WargearListType } from '../../../types';
import { defaultFormValues } from '../constants';
import { createWargearList } from './FactionUnits.utils';
import PrimaryWeaponsWeaponsForm from './Forms/PrimaryWeaponsForm';
import SecondaryWeaponsForm from './Forms/SecondaryWeaponsForm';
import OptionsForm from './Forms/OptionsForm';

const Form = ({
  onSubmit, selectedFactionUnit, specialists, abilities, wargear, disabled,
}: {
  onSubmit: Function,
  selectedFactionUnit?: FactionUnitType | null,
  specialists: Array<string>,
  abilities: Array<string>,
  wargear: WargearListType,
  disabled?: boolean,
}) => {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [formType, setFormType] = useState('Add');
  const isValid = disabled && wargear;
  const wargearList = wargear ? createWargearList(wargear) : [];
  const primaryWeaponsCount = formValues && formValues.primaryWeapons
    ? formValues.primaryWeapons.length : 0;
  const secondaryWeaponsCount = formValues && formValues.secondaryWeapons
    ? formValues.secondaryWeapons.length : 0;
  const optionsCount = formValues && formValues.options
    ? formValues.options.length : 0;
  const activeOptionsCount = formValues && formValues.activeOptions
    ? formValues.activeOptions.length : 0;

  const resetForm = () => {
    setFormValues(defaultFormValues);
    setFormType('Add');
  };

  useEffect(() => {
    if (selectedFactionUnit) {
      setFormValues(selectedFactionUnit);

      setFormType('Edit');
    }
  }, [selectedFactionUnit]);

  const onFormSubmit = (e) => {
    e.preventDefault();

    const newFormValues = { ...formValues };

    onSubmit(newFormValues, formType);

    resetForm();
  };

  return (
    <FormStyles onSubmit={(e) => onFormSubmit(e)}>
      <GeneralForm
        formValues={formValues}
        setFormValues={setFormValues}
        disabled={!isValid}
      />
      <StatsForm
        formValues={formValues}
        setFormValues={setFormValues}
        disabled={!isValid}
      />
      <SpecialistsForm
        specialists={specialists}
        formValues={formValues}
        setFormValues={setFormValues}
        disabled={!isValid}
      />
      <AbilitiesForm
        abilities={abilities}
        formValues={formValues}
        setFormValues={setFormValues}
        disabled={!isValid}
      />
      <PrimaryWeaponsWeaponsForm
        wargear={wargear}
        wargearList={wargearList}
        formValues={formValues}
        setFormValues={setFormValues}
        primaryWeaponsCount={primaryWeaponsCount}
        disabled={!isValid}
      />
      <SecondaryWeaponsForm
        wargearList={wargearList}
        formValues={formValues}
        setFormValues={setFormValues}
        secondaryWeaponsCount={secondaryWeaponsCount}
        disabled={!isValid}
      />
      <OptionsForm
        wargear={wargear}
        wargearList={wargearList}
        formValues={formValues}
        setFormValues={setFormValues}
        optionsCount={optionsCount}
        activeOptionsCount={activeOptionsCount}
        disabled={!isValid}
      />
      <div>
        <button disabled={!isValid || formType !== 'Add' || !formValues.type} type="submit">
          Add Faction Unit
        </button>
        <button disabled={!isValid || formType !== 'Edit' || !formValues.type} type="submit">
          Edit Faction Unit
        </button>
      </div>
      {/* {error && <p className="error">{error.message}</p>} */}
    </FormStyles>
  );
};

Form.defaultProps = {
  selectedFactionUnit: null,
  disabled: false,
};

export default Form;
