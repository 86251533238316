import styled from 'styled-components';

export default styled.div`
padding: 0.5rem;

.roster-content {
  display: flex;
  height: 100%;

  & > div:first-child {
    max-width: 120px;
    border-right: 1px solid var(--red);
  }

  & .roster-units, & .left-menu {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & .no-units {
    text-align: center;
    height: 100%;
    align-items: stretch;
  }
}

@media screen and (min-width: 550px) {
  .roster-content {
    & > div:first-child {
      max-width: 280px;
    }
  }
}
`;

export const ListEditHeaderStyles = styled.header`
padding: 0 0.1rem;
border-bottom: 1px solid var(--red);

& .edit-team:last-child {
  padding: 0.5rem 0;
}

& .edit-team {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 0.4rem;

  .team-edit-btn {
    height: 100%;
    align-self: flex-end;
    padding: 0 0 0 0.3rem;
    margin-bottom: 3px;
  }

  & button > span {
    margin-right: 3px;
  }

  & > label {
    flex: 1;
  }

  & > label > input {
    margin: 0.2rem 0 0;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    transition: 300ms;
  }

  & .unit-name-icon {
    display: none;
    align-self: flex-end;
    margin-bottom: 5px;
    padding-right: 4px;
    width: 30px;
    height: 30px;
    color: var(--base130);
  }

  & .edit-icon {
    opacity: 0.5;
    margin-left: -22px;
    transition: opacity 0.3s ease-in-out;
  }

  & input:focus ~ .edit-icon {
    opacity: 0.1;
  }

  & input:focus {
    background-color: var(--base080);
  }
}

h5 {
  flex: 1;
}

.error {
  padding: 2px 2px 1px;
  font-size: 0.7rem;
  color: var(--red);
}  

.list-edit-menu {
  background: var(--white);
  color: var(--base130);
  display: flex;
  flex-direction: column;

  & > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    color: var(--base130);
    border-bottom: 1px solid var(--base);

    &[disabled="disabled"] {
      background: var(--base090);
      color: var(--base);

      & .menu-icon {
        color: var(--base);
      }
    }

    & .menu-icon {
      color: var(--base130);
      margin: 0 0.5rem;
    }
  }
}

@media screen and (min-width: 550px) {
  & .edit-team {
    padding: 0.5rem 0;

    & svg {
      transform: scale(1.3);
      margin: 0 0.3rem;
    }

    & .unit-name-icon {
      display: inline;
    }
  }
  & .edit-team:last-child {
    padding: 0.1rem 0 0.5rem;
  
    & button > span {
      margin-right: 10px;
    }
  }

  & .edit-team .edit-icon {
    margin-left: -34px;
  }

  .list-edit-menu {
    & > button {
      padding: 1rem;
      transition: background-color 0.2s ease-in-out;
    }

    & > button:hover {
      background: var(--base); 
      border-color: var(--base110);
    }
  }
}
`;
