// @flow
import React from 'react';
import StatsStyles from './StatsFormStyles';
import { H } from '../../../../styles';
import type { StatsType, FactionUnitType } from '../../../../types';

const Input = ({
  type, stats, onChange, disabled,
}: {
  type: string,
  stats: StatsType,
  onChange: Function,
  disabled: boolean
}) => (
  <label htmlFor="true">
    <p>{type.toUpperCase()}</p>
    <input
      type="text"
      onFocus={(e) => e.target.select()}
      name={type}
      value={stats ? stats[type] : ''}
      onChange={(e) => onChange(e, type)}
      disabled={disabled}
    />
  </label>
);

const StatsForm = ({ formValues, setFormValues, disabled }: {
  formValues: FactionUnitType,
  setFormValues: Function,
  disabled: boolean
}) => {
  const { stats } = formValues;

  const inputOnChange = (e, type) => {
    setFormValues({ ...formValues, stats: { ...stats, [type]: e.currentTarget.value } });
  };

  return (
    <StatsStyles>
      <H as="h4" className="stats-title">Stats*</H>
      <div className="form-elements">
        <Input type="m" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="ws" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="bs" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="s" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="t" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="w" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="a" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="ld" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="sv" stats={stats} onChange={inputOnChange} disabled={disabled} />
        <Input type="max" stats={stats} onChange={inputOnChange} disabled={disabled} />
      </div>
    </StatsStyles>
  );
};

export default StatsForm;
