import styled from 'styled-components';

export default styled.article`
padding: 0.3rem 0;
border-top: 1px solid var(--red);

ul {
  padding-top: 0.5rem;
  list-style-type: none;
  font-size: 0.8rem;

  & > li {
    padding: 0.2rem;
  }
}

p {
  padding: 0 0 0.2rem;
}
`;
