// @flow
import * as React from 'react';
import { SPECIALISTS } from '../../../constants';
import H from '../../../styles/Headings';
import SpecialistMenuStyles from './SpecialistMenuStyles';

const createSpecialistMenuItems = (specialists?: Array<string>, specialistLimit: number) => {
  const limit = specialistLimit === -1 ? 4 : specialistLimit;
  const specialistCount = specialists ? specialists.length : 0;
  const emptySlots = limit - specialistCount;
  const menuItems = [];
  if (specialists) {
    specialists.forEach((specialist) => {
      menuItems.push(specialist);
    });
  }

  for (let i = 0; i < emptySlots; i += 1) {
    menuItems.push('Empty');
  }

  if (menuItems.find((item) => item === SPECIALISTS.LEADER)) {
    const filteredItems = menuItems.filter((item) => item !== SPECIALISTS.LEADER);
    filteredItems.unshift(SPECIALISTS.LEADER);
    return filteredItems;
  }

  return menuItems;
};

const SpecialistMenu = ({
  specialists,
  specialistLimit,
}: { specialists?: Array<string>, specialistLimit: number }) => {
  const specialistMenuItems = createSpecialistMenuItems(specialists, specialistLimit);
  const specialistList = specialists && specialistMenuItems.map((specialist, i) => {
    let className = '';
    if (specialist === SPECIALISTS.LEADER) {
      className += ' leader';
    } else if (specialist === 'Empty') {
      className += ' empty';
    }
    return <span key={i.toString()} className={className}>{specialist}</span>;
  });
  return (
    <SpecialistMenuStyles>
      <H as="h5" mb>Specialists</H>
      <div className="specialist-menu">
        {specialistList}
      </div>
    </SpecialistMenuStyles>
  );
};

SpecialistMenu.defaultProps = {
  specialists: [],
};

export default SpecialistMenu;
