export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const MY_TEAMS = '/my-teams';
export const ACCOUNT = '/account';
export const ABOUT = '/about';
export const ADMIN = '/admin';
export const ENCYCLOPEDIA = '/encyclopedia';
export const PASSWORD_FORGET = '/pw-forget';

export default {
  LANDING,
  SIGN_UP,
  SIGN_IN,
  MY_TEAMS,
  ACCOUNT,
  ABOUT,
  ADMIN,
  ENCYCLOPEDIA,
  PASSWORD_FORGET,
};
