// @flow
import React, { useState } from 'react';
import AddWargearForm from './AddWargearForm';
import WargearList from './WargearList';
import { Grid, H } from '../../../styles';
import type { WargearListType } from '../../../types';

const Faction = ({ firebase, send, wargear }: {
  firebase: any,
  send: Function,
  wargear: WargearListType
}) => {
  const [selectedWargear, setSelectedWargear] = useState(null);

  const onSubmit = (_wargear, formType) => {
    if (formType === 'Add') {
      firebase.addByNamespace('wargear', _wargear, (data) => {
        send('ADD_WARGEAR', { data });
      });
    } else if (formType === 'Edit') {
      firebase.updateByNamespace(`wargear/${_wargear.id}`, _wargear)
        .then((data) => {
          send('UPDATE_WARGEAR', { data });
          return data;
        });
    }
  };

  const onEditWargear = (id) => {
    setSelectedWargear(id);
  };

  return (
    <div>
      <H as="h3">Wargear</H>
      <Grid columns="1fr 200px">
        <AddWargearForm
          onSubmit={onSubmit}
          selectedWargear={selectedWargear
        && wargear[selectedWargear]}
          wargear={wargear}
        />
        <WargearList wargear={wargear} onClick={onEditWargear} />
      </Grid>
    </div>
  );
};

export default Faction;
