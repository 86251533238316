// @flow
import React, { useState, useEffect, useRef } from 'react';
import {
  FiEdit as Edit, FiList as DisplayTeamIcon, FiPrinter as Print, FiMoreVertical as Options,
} from 'react-icons/fi';
import { AiOutlineUserAdd as AddUnitIcon } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Icon } from '../utils/getFactionIcon';
import TeamHeaderStyles from './EditTeamHeader.styles';
import H from '../../../styles/Headings';
import PrintRoster from '../PrintRoster';
import EditMenu from '../../EditMenu';
import type { TeamType, UnitListType, PrinterFriendlyTeam } from '../../../types';

const EditTeamHeader = ({
  updateTeam, setEditMode, team, units, addUnitDisabled, setPreviewMode, printerFriendlyTeam,
}: {
  updateTeam: Function,
  setEditMode: Function,
  setPreviewMode: Function,
  team: TeamType,
  units: UnitListType,
  addUnitDisabled: boolean,
  printerFriendlyTeam: PrinterFriendlyTeam
}) => {
  const [editMenu, setEditMenu] = useState('');
  const [name, setName] = useState<string>('');
  const unitCountMarkup = team.units === 1 ? ` ${team.units} member ` : ` ${team.units} members `;
  const componentRef = useRef();
  console.log(printerFriendlyTeam);
  useEffect(() => {
    if (team) {
      setName(team.name);
    }
  }, [team]);

  const updateTeamName = (e) => {
    const teamName = e.target.value;

    if (team.name === teamName) return;

    const updatedTeam = {
      ...team,
      name: teamName,
    };
    updateTeam(updatedTeam);
  };

  const setTeamName = (e) => {
    const teamName = e.target.value;
    setName(teamName);
    if (e.which === 13 || e.keyCode === 13) {
      updateTeamName(e);
    }
  };

  return (
    <TeamHeaderStyles>
      <section className="edit-team">
        <Icon name={team.faction.name} className="unit-name-icon" />
        <label htmlFor="true">
          Team Name
          <input
            type="text"
            onFocus={(event) => event.target.select()}
            defaultValue={name}
            onKeyPress={setTeamName}
            onBlur={updateTeamName}
          />
          <span className="edit-icon">
            <Edit color="#24C6A2" size="20" />
          </span>
        </label>
        <button
          type="button"
          className="team-edit-btn"
          onClick={() => { setEditMode(true); setEditMenu(false); }}
          aria-label="Add Unit"
          disabled={addUnitDisabled}
        >
          <AddUnitIcon size="30" className="" />
          {/* <H as="h6">Add Unit</H> */}
        </button>
        <button type="button" className="team-edit-btn" onClick={() => setEditMenu(true)}>
          <Options size="30" />
        </button>
      </section>
      <section className="edit-team">
        <H as="h5">
          {`${team.faction.name} • `}
          <span>
            {`${unitCountMarkup} • `}
          </span>
          <span className="red">{` ${team.cost} points`}</span>
        </H>
        {editMenu && (
          <EditMenu onClick={() => setEditMenu(null)}>
            <div className="list-edit-menu">
              <button
                type="button"
                onClick={() => { setEditMode(true); setEditMenu(false); }}
                aria-label="Add Unit"
                disabled={addUnitDisabled}
              >
                <AddUnitIcon size="30" className="menu-icon" />
                <H as="h6">Add Unit</H>
              </button>
              <ReactToPrint
                trigger={() => (
                  <button
                    type="button"
                    onClick={() => setEditMenu(false)}
                    aria-label="Print Team"
                    disabled={!team.units}
                  >
                    <Print size="30" className="menu-icon" />
                    <H as="h6">Print Roster</H>
                  </button>
                )}
                content={() => componentRef.current}
              />
              <div style={{ display: 'none' }}>
                {units && <PrintRoster ref={componentRef} printerFriendlyTeam={printerFriendlyTeam} />}
              </div>
              <button
                type="button"
                onClick={() => { setPreviewMode(true); setEditMenu(false); }}
                aria-label="Display Team"
                disabled={!team.units}
              >
                <DisplayTeamIcon size="30" className="menu-icon" />
                <H as="h6">Display Team</H>
              </button>
            </div>
          </EditMenu>
        )}
      </section>
    </TeamHeaderStyles>
  );
};

export default EditTeamHeader;
