import styled from 'styled-components';

export default styled.article`
  display: block;
  border-top: 1px solid var(--red);
  margin-top: 0.5rem;
  padding: 0.5rem 0.5rem 9px;

  & > label {
    display: block;
    flex: none;
  }
`;
