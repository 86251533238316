// @flow
import React from 'react';
import EditMenuStyles from './EditMenuStyles';

const Modal = ({
  children,
  onClick,
  isVisible,
}: {
  children: Object,
  onClick: Function,
  isVisible?: boolean,
}) => {
  const onClose = () => {
    onClick();
  };

  return (
    <EditMenuStyles isVisible={isVisible}>
      <div
        className="menu-background"
        onClick={onClose}
        onKeyPress={onClose}
        aria-label="button"
        role="button"
        tabIndex={0}
        title="background"
      />
      <div className="menu">
        <div className="menu-inner">
          {children}
        </div>
      </div>
    </EditMenuStyles>
  );
};

Modal.defaultProps = {
  isVisible: false,
};

export default Modal;
