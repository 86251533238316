// @flow
import React from 'react';
import { AiOutlineStepBackward as Back } from 'react-icons/ai';
import StyledButton from './BackButtonStyles';

const BackButton = ({ onClick }: { onClick: Function }) => (
  <StyledButton type="button" onClick={onClick}>
    <Back className="icon" />
    Go back
  </StyledButton>
);

export default BackButton;
