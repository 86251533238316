// @flow
import React, { useState } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { FiEdit as Edit } from 'react-icons/fi';
import { ListEditHeaderStyles } from '../ListEdit/ListEdit.styles';
import { H } from '../../../../styles';
import { TEAM_LIST_TYPES } from '../../../../constants';
import AddListStyles from './AddListStyles';
import EditSection from './EditSection';
import DisplaySection from './DisplaySection';
import teamListTypes from './teamListTypes';

const AddList = ({ onFormSubmit }: { onFormSubmit: Function }) => {
  const [list, setList] = useState(teamListTypes[TEAM_LIST_TYPES.BATTLE_FORGED]);
  const submitList = () => {
    if (!list.name) return;

    onFormSubmit(list);
  };

  return (
    <AddListStyles>
      <H as="h4">Add List</H>
      <ListEditHeaderStyles>
        <section className="edit-team">
          <AiOutlineFileText name="list-name" className="unit-name-icon" />
          <label htmlFor="true">
            List Name
            <input
              type="text"
              onFocus={(event) => event.target.select()}
              onChange={(e) => setList({ ...list, name: e.target.value })}
              placeholder="List name here..."
            />
            <span className="edit-icon">
              <Edit color="#24C6A2" size="20" />
            </span>
          </label>
        </section>
        <section className="edit-team">
          <H as="h5" pb>List Type</H>
          <select
            onChange={(e) => setList({ ...list, ...teamListTypes[e.target.value] })}
            defaultValue={TEAM_LIST_TYPES.BATTLE_FORGED}
          >
            <option value={TEAM_LIST_TYPES.BATTLE_FORGED}>{TEAM_LIST_TYPES.BATTLE_FORGED}</option>
            <option value={TEAM_LIST_TYPES.UNLIMITED}>{TEAM_LIST_TYPES.UNLIMITED}</option>
            <option value={TEAM_LIST_TYPES.CUSTOM}>{TEAM_LIST_TYPES.CUSTOM}</option>
            <option value={TEAM_LIST_TYPES.OPEN_PLAY}>{TEAM_LIST_TYPES.OPEN_PLAY}</option>
          </select>
        </section>
        {list.type === TEAM_LIST_TYPES.CUSTOM ? (
          <EditSection
            list={list}
            setList={setList}
          />
) : <DisplaySection list={list} />}
        <section className="edit-team">
          <button
            type="button"
            className="add-list-button"
            onClick={submitList}
            disabled={!list.name}
          >
            Add List
          </button>
        </section>
      </ListEditHeaderStyles>
    </AddListStyles>
  );
};

export default AddList;
