// @flow

// $FlowFixMe
const getLocalState = (key: string) => JSON.parse(localStorage.getItem(key));
const setLocalState = (key: string, data: any) => localStorage.setItem(key, JSON.stringify(data));
const deleteLocalState = (key: string) => localStorage.removeItem(key);

export {
  getLocalState,
  setLocalState,
  deleteLocalState,
};
