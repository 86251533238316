import styled from 'styled-components';

export default styled.label`
flex: 1;
margin-bottom: 5px;

& > input {
    margin: 0.2rem 0 0;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    transition: 300ms;
  }

  & .edit-icon {
    opacity: 0.5;
    margin-left: -24px;
    transition: opacity 0.3s ease-in-out;
  }

  & input:focus ~ .edit-icon {
    opacity: 0.1;
  }

  & input:focus {
    background-color: var(--base080);
  }
}
`;
