import { Machine } from 'xstate';
import {
  STATES, ACTIONS, SERVICES, GUARDS,
} from '../../../constants';

export default Machine({
  id: 'manager',
  initial: STATES.GETTING_LOCAL_FACTIONS,
  states: {
    [STATES.IDLE]: {},
    [STATES.SELECTED]: {},
    [STATES.GETTING_LOCAL_FACTIONS]: {
      id: STATES.GETTING_LOCAL_FACTIONS,
      invoke: {
        src: SERVICES.GET_LOCAL_STORAGE_FACTIONS,
        onDone: [{
          target: STATES.GETTING_ABILITIES,
          actions: ACTIONS.SET_FACTIONS,
          cond: GUARDS.HAS_LOCAL_FACTIONS,
        }, {
          target: STATES.GETTING_FACTIONS,
        }],
        onError: STATES.GETTING_FACTIONS,
      },
    },
    [STATES.GETTING_FACTIONS]: {
      invoke: {
        src: SERVICES.GET_FACTIONS,
        onDone: {
          target: STATES.GETTING_ABILITIES,
          actions: ACTIONS.SET_FACTIONS,
        },
      },
    },
    [STATES.GETTING_ABILITIES]: {
      invoke: {
        src: SERVICES.GET_ABILITITES,
        onDone: {
          target: STATES.GETTING_WARGEAR,
          actions: ACTIONS.SET_ABILITIES,
        },
      },
    },
    [STATES.GETTING_WARGEAR]: {
      invoke: {
        src: SERVICES.GET_WARGEAR,
        onDone: {
          target: STATES.GETTING_SPECIALISTS,
          actions: ACTIONS.SET_WARGEAR,
        },
      },
    },
    [STATES.GETTING_SPECIALISTS]: {
      invoke: {
        src: SERVICES.GET_SPECIALISTS,
        onDone: {
          target: STATES.GETTING_TEAMS,
          actions: ACTIONS.SET_SPECIALISTS,
        },
      },
    },
    [STATES.GETTING_TEAMS]: {
      invoke: {
        src: SERVICES.GET_TEAMS,
        onDone: {
          target: STATES.SUCCESS,
          actions: ACTIONS.SET_TEAMS,
        },
      },
    },
    [STATES.SUCCESS]: {
      initial: 'INITIAL',
      states: {
        INITIAL: {},
        [STATES.TEAM_OPTIONS_MENU]: {},
      },
      on: {
        [ACTIONS.OPEN_TEAM_OPTIONS_MENU]: { target: `.${STATES.TEAM_OPTIONS_MENU}` },
        [ACTIONS.CLOSE_TEAM_OPTIONS_MENU]: { target: '.INITIAL' },
      },
    },
  },
  on: {
    [ACTIONS.ADD_TEAM]: {
      target: `.${STATES.SELECTED}`,
      actions: [ACTIONS.ADD_TEAM, ACTIONS.AUTO_SELECT],
    },
    [ACTIONS.DELETE_TEAM]: { actions: ACTIONS.DELETE_TEAM },
    [ACTIONS.COPY_TEAM]: { actions: ACTIONS.COPY_TEAM },
    [ACTIONS.UPDATE_TEAM]: { actions: ACTIONS.UPDATE_TEAM },
    [ACTIONS.SELECT]: {
      target: `.${STATES.SELECTED}`,
      actions: ACTIONS.SELECT,
    },
    [ACTIONS.BACK_TO_DEFAULT]: { target: STATES.SUCCESS },
    [ACTIONS.UPDATE_TEAM]: { actions: ACTIONS.UPDATE_TEAM },
  },
});
