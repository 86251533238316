// @flow
import React from 'react';
import AbilitiesListStyle from './AbilitiesList.styles';
import { H } from '../../../styles';
import type { FactionListType } from '../../../types';

const AbilitiesList = ({ abilities, onClick }: {
  abilities: FactionListType,
  onClick: Function
}) => {
  if (abilities) {
    return (
      <AbilitiesListStyle>
        <H as="h5">Click to Edit</H>
        <ul className="scroller">
          {Object.keys(abilities).map((key) => (
            <li key={key}>
              <button
                type="button"
                onClick={() => onClick(key)}
              >
                {abilities[key].name}
              </button>
            </li>
))}
        </ul>
        {`Total: ${Object.keys(abilities).length} abilities`}
      </AbilitiesListStyle>
    );
  }

  return (<p>No Abilities</p>);
};

export default AbilitiesList;
