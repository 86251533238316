// @flow

import type { FactionUnitType } from '../../types';

export const WARGEAR_GROUP = {
  RANGED: 'Ranged',
  MELEE: 'Melee',
  OTHER: 'Other',
  MIXED: 'Mixed',
};

export const WARGEAR_TYPE = {
  OTHER: 'Other',
  ASSAULT_1: 'Assault 1',
  ASSAULT_2: 'Assault 2',
  ASSAULT_3: 'Assault 3',
  ASSAULT_4: 'Assault 4',
  ASSAULT_D3: 'Assault D3',
  ASSAULT_D6: 'Assault D6',
  ASSAULT_2D6: 'Assault 2D6',
  PISTOL_1: 'Pistol 1',
  PISTOL_2: 'Pistol 2',
  PISTOL_3: 'Pistol 3',
  PISTOL_4: 'Pistol 4',
  PISTOL_5: 'Pistol 5',
  PISTOL_D3: 'Pistol D3',
  PISTOL_D6: 'Pistol D6',
  PISTOL_STAR: 'Pistol *',
  RAPID_FIRE_1: 'Rapid fire 1',
  RAPID_FIRE_2: 'Rapid fire 2',
  RAPID_FIRE_3: 'Rapid fire 3',
  SUPERCHARGE: 'Supercharge',
  STANDARD: 'Standard',
  HEAVY_1: 'Heavy 1',
  HEAVY_2: 'Heavy 2',
  HEAVY_3: 'Heavy 3',
  HEAVY_4: 'Heavy 4',
  HEAVY_6: 'Heavy 6',
  HEAVY_D3: 'Heavy D3',
  HEAVY_D6: 'Heavy D6',
  GRENADE_D3: 'Grenade D3',
  GRENADE_D6: 'Grenade D6',
  GRENADE_1: 'Grenade 1',
  CRYPTCLEARER_ROUND: 'Cryptclearer round',
  XENOPURGE_SLUG: 'Xenopurge slug',
  WYRMSBREATH_SHELL: 'Wyrmsbreath shell',
  FRAG_ROUND: 'Frag round',
  SHELL: 'Shell',
  SHORT_RANGE: 'Short range',
  MEDIUM_RANGE: 'Medium range',
  LONG_RANGE: 'Long range',
  LONG_WAVE: 'Long-wave',
  SHORT_WAVE: 'Short-wave',
};

export const ORIGIN = {
  CORE: 'Core',
  ELITES: 'Elites',
  COMMANDERS: 'Commanders',
};

export const defaultFormValues: FactionUnitType = {
  specialists: [],
  abilities: [],
  keywords: [],
  origin: ORIGIN.CORE,
  type: '',
  group: '',
  cost: 0,
  stats: {
    m: 0,
    ws: 0,
    bs: 0,
    s: 0,
    t: 0,
    a: 0,
    w: 0,
    ld: 0,
    sv: 0,
    max: 0,
  },
  totalCost: 0,
};
