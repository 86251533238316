// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path d="M957.62,437c-15.18,21.92-40.49,36.29-69.17,36.29c-46.44,0-84.09-37.65-84.09-84.09s37.65-84.09,84.09-84.09
		c11.64,0,22.73,2.37,32.81,6.64c-45.63-100.76-126.36-182.23-226.6-228.8c3.89,10.34,6.03,21.54,6.03,33.24
		c0,52.17-42.29,94.46-94.46,94.46c-52.17,0-94.46-42.29-94.46-94.46c0-29.8,13.81-56.36,35.37-73.67
		c-15.42-1.55-31.07-1.82-46.89-2.35c-215.18-7.3-444.63,213.1-444.63,338.66l39.04,15.14l124.53,66.65
		c0.98,0.45,1.96,0.9,2.95,1.35c-4.82-9.18-7.56-19.63-7.56-30.72c0-36.58,29.65-66.23,66.23-66.23s66.23,29.65,66.23,66.23
		c0,26.47-15.53,49.3-37.97,59.91c2.99,0.6,6.05,1.18,9.17,1.72c47.18,8.22,95.41,9.17,125.73,6.11l-0.18-1.5
		c-0.14-6.52-1.48-47.05,29.95-77.18c36.32-34.82,89.42-26.22,94.46-25.34C500.18,429.77,460.38,501.26,468,573.51
		c7.82,74.13,58.75,122.1,87.55,133.21c24.18,13.09,44.92,30.1,44.92,58.01c0,33.08-26.82,59.9-59.9,59.9
		c-31.06,0-56.59-23.64-59.6-53.9c-0.62,0.41-1.23,0.81-1.85,1.21c0.13,1.05,0.26,2.1,0.4,3.16
		c-34.93,5.82-121.3,15.44-218.86-25.34c-98.5-41.18-152.54-110.38-172.78-139.38C74.05,764.72,245.15,964,500.26,964
		c131.68,0,250.49-55.1,334.64-143.51c-35.45-4.78-62.79-35.14-62.79-71.89c0-40.08,32.49-72.57,72.57-72.57
		c28.45,0,53.06,16.38,64.95,40.21c33.55-64.01,52.54-136.86,52.54-214.15C962.17,480,960.62,458.27,957.62,437z M227.26,249.82
		V233.7l55.29-57.59l20.73,36.86L227.26,249.82z M648.85,658.75c-55.35,0-100.22-44.87-100.22-100.22s44.87-100.22,100.22-100.22
		s100.22,44.87,100.22,100.22S704.2,658.75,648.85,658.75z"
      />
      <circle cx="648.85" cy="558.53" r="43.77" />
    </g>
  </svg>
);
