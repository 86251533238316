// @flow
import React from 'react';
import SelectFactions from './SelectFactions';
import type {
  TeamType, TeamCollectionType, FactionListType,
} from '../../../types';
import Alert from '../../Alert';
import { ALERTS, ACTIONS } from '../../../constants';
import { H } from '../../../styles';
import StyledAddTeam from './AddTeamFormStyles';
import { useFirebase } from '../../Firebase';
import { useAuth } from '../../Session';

export const getTeamFactionsCount = (teams: TeamCollectionType | null,
  factionId: string): number => (teams ? Object
  .keys(teams)
  .reduce((total, key) => {
    let count = 0;
    if (teams[key].faction.id === factionId) {
      count = 1;
    }
    return total + count;
  }, 0) : 0);

type Props = {|
  +factions: FactionListType,
  +teams: TeamCollectionType,
  +send: Function,
|}

const AddTeamForm = ({ factions, teams, send }: Props) => {
  const firebase = useFirebase();
  const { uid } = useAuth();

  const onCreateTeam = (faction) => {
    const factionCount: number = getTeamFactionsCount(teams, faction.id);
    const name = factionCount > 0 ? `${factions[faction.id].name}-${factionCount + 1}` : factions[faction.id].name;

    const team: TeamType = {
      id: '',
      name,
      faction: { ...factions[faction.id], id: faction.id },
      cost: 0,
      units: 0,
      created: Date.now(),
    };

    firebase.addTeam(uid, team, (data) => {
      send({ type: ACTIONS.ADD_TEAM, data });
    });
  };

  if (!factions) {
    return <Alert text="No factions in database..." type={ALERTS.ERROR} />;
  }

  return (
    <StyledAddTeam>
      <H as="h5">Add Team</H>
      <SelectFactions factions={factions} onSelect={onCreateTeam} />
    </StyledAddTeam>
  );
};

export default AddTeamForm;
