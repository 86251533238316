// @flow
import React from 'react';
import type { UnitType } from '../../../../types';
import DisplayTeam from './DisplayTeam';
import { useRosterContext } from '../../EditTeam/context';

const Index = ({ units }: { units: Array<UnitType>}) => {
  const [current] = useRosterContext();
  const {
    context: {
      team, wargear, specialists, abilities,
    },
  } = current;

  return (
    <DisplayTeam
      team={team}
      units={units}
      wargear={wargear}
      specialists={specialists}
      abilities={abilities}
    />
  );
};

export default Index;
