// @flow
import React from 'react';
import type { WargearType } from '../../../types';

const SelectBox = ({
  name, data, selectedData, onSelect, disabled,
}: {
  name: string,
  data: Array<any>,
  selectedData: string,
  onSelect: Function,
  disabled?: boolean
}) => {
  const options = data
    .map((dataEntry, i) => <option key={i.toString()} value={dataEntry}>{dataEntry}</option>);
  return (
    <label htmlFor="true">
      <span>{name}</span>
      <select
        name={name}
        value={selectedData}
        onChange={(e) => onSelect(e)}
        disabled={disabled}
      >
        <option value="">- Select -</option>
        {options}
      </select>
    </label>
  );
};

SelectBox.defaultProps = {
  disabled: false,
};

export const SelectBoxProfile = ({
  name, data, selectedData, onSelect,
}: {
  name: string,
  data: Array<WargearType>,
  selectedData?: WargearType | null,
  onSelect: Function,
}) => {
  const options = data
    .map((dataEntry, i) => (
      <option key={i.toString()} value={dataEntry.name}>
        {dataEntry.name}
      </option>
    ));
  return (
    <label htmlFor="true">
      <span>{name}</span>
      <select name={name} value={selectedData && selectedData.name} onChange={(e) => onSelect(e)}>
        <option value="">- Select -</option>
        {options}
      </select>
    </label>
  );
};

SelectBoxProfile.defaultProps = {
  selectedData: null,
};

export default SelectBox;
