import React, { Component } from 'react';
import { withFirebase } from '../../Firebase';
import { Content, FormStyles } from '../../../styles';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;
    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';
    return (
      <FormStyles onSubmit={this.onSubmit}>
        <label htmlFor="true">
          <span>New password</span>
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
          />
        </label>
        <label htmlFor="true">
          <span>Confirm password</span>
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
          />
        </label>
        <div>
          <button disabled={isInvalid} type="submit">
            Change Password
          </button>
        </div>
        {error && <p className="error">{error.message}</p>}
      </FormStyles>
    );
  }
}

export default withFirebase(PasswordChangeForm);
