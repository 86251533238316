// @flow
import React from 'react';
import { isLeader, isSpecialist } from '../EditTeam/EditTeam.utils';
import { ListUl, ListLi } from '../../../styles/ListStyles';
import H from '../../../styles/Headings';
import type { UnitType } from '../../../types';

const UnitItem = ({
  unit, onSelectUnit, isSelected,
}: {
  unit: UnitType, onSelectUnit: Function, isSelected: boolean,
}) => {
  const isLeaderCssClass = isLeader(unit) ? ' is-leader' : '';
  const isSpecialistCssClass = isSpecialist(unit) ? ' is-specialist' : '';
  const isSelectedCssClass = isSelected ? ' selected-unit' : '';

  return (
    <ListLi className={`border-left${isLeaderCssClass}${isSpecialistCssClass}${isSelectedCssClass}`} onClick={() => onSelectUnit(unit)}>
      <div>
        <H as="h5">
          {unit.name}
        </H>
        <p>
          {unit.type}
          {unit.specialist && (
            <strong>
              {' '}
              {unit.specialist.name}
            </strong>
          )}
        </p>
      </div>
    </ListLi>
  );
};

const UnitList = ({ units, onSelectUnit, selectedUnit }: {
  units: Array<UnitType>,
  onSelectUnit: Function,
  selectedUnit: UnitType,
}) => {
  const unitsMarkup = units
    .map((unit) => {
      const isSelected = selectedUnit.id === unit.id;
      return (
        <UnitItem
          key={unit.id}
          unit={unit}
          onSelectUnit={onSelectUnit}
          isSelected={isSelected}
        />
      );
    });
  return (
    <ListUl>
      {unitsMarkup}
    </ListUl>
  );
};


export default UnitList;
