// @flow
import React from 'react';
import { isLeader, isSpecialist } from '../EditTeam/EditTeam.utils';
import { ListUl, ListLi } from '../../../styles/ListStyles';
import H from '../../../styles/Headings';
import type { UnitType } from '../../../types';

const UnitItem = ({
  unit, onSelectUnit, id, isSelected,
}: {
  unit: UnitType, onSelectUnit: Function, id: string, isSelected: boolean,
}) => {
  const isLeaderCssClass = isLeader(unit) ? ' is-leader' : '';
  const isSpecialistCssClass = isSpecialist(unit) ? ' is-specialist' : '';
  const isSelectedCssClass = isSelected ? ' selected-unit' : '';

  return (
    <ListLi className={`border-left${isLeaderCssClass}${isSpecialistCssClass}${isSelectedCssClass}`} onClick={() => onSelectUnit(id)}>
      <div>
        <H as="h5">
          {unit.name}
        </H>
        <p>
          {unit.type}
          {unit.specialist && (
            <strong>
              {' '}
              {unit.specialist.name}
            </strong>
          )}
        </p>
      </div>
    </ListLi>
  );
};

const UnitList = ({ units, onSelectUnit, selectedUnit }: {
  units: { [string]: UnitType },
  onSelectUnit: Function,
  selectedUnit?: string,
}) => {
  const unitsMarkup = Object.keys(units)
    .map((key) => {
      const isSelected = selectedUnit === key;
      return (
        <UnitItem
          key={key}
          id={key}
          unit={units[key]}
          onSelectUnit={onSelectUnit}
          isSelected={isSelected}
        />
      );
    });
  return (
    <ListUl>
      {unitsMarkup}
    </ListUl>
  );
};

UnitList.defaultProps = {
  selectedUnit: '',
};

export default UnitList;
