// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_4"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <polygon points="757,369 757,796 241.6,796 241.6,369 216.6,369 216.6,816 782.6,816 782.6,369 	" />
      <path d="M530.1,828h-57.8l0,64.3c-0.3,13.5,4.8,45.2,10.9,67.1c5.5,19.2,11.3,23,17.6,32.8v1.6c0.2-0.2,0.3-0.6,0.6-0.8
		c0.1,0.2,0.2,0.3,0.3,0.6v-1c6.5-9.9,12.3-13.6,17.6-32.8c6.1-21.8,11.3-53.6,10.9-67.1L530.1,828z"
      />
      <path d="M472.1,441h57l-0.4-144.3c34.2,0.4,68.5,1.5,102.7,3.3v-35c-39.2,6.3-78.3,9.8-117.5,10.4c-0.3-61.1,0.1-220.1,2.3-237
		l8.9,0.1c1.3,0,2.6-1.1,2.6-2.5V24.5c0-1.3-1-2.3-2.2-2.5c-2.8-0.2-5.6-0.4-8.4-0.7c-0.1-1.3-1-7-6.1-11.3
		c-3.3-2.8-6.9-3.5-8.9-3.7V6c-1.6,0.1-5.8,0.6-9.7,3.8c-5.1,4.1-6,9.9-6.1,11.3c-2.8,0.2-5.6,0.4-8.4,0.7c-1.2,0.1-2.2,1.2-2.2,2.5
		v11.3c0,1.3,1.1,2.5,2.6,2.5l8.9-0.1c2.3,17,2.7,176.2,2.4,237.4c-39.6-0.6-79.1-4-118.7-10.4v35c33.7-1.8,67.4-2.9,101.1-3.3
		L472.1,441z"
      />
      <path d="M492.1,449h-30v-97.1c-74.1,4.6-140.1,2.3-203-2.9v430c77.6,3.4,155.3,5.2,233,5.3V449z" />
      <path d="M537.1,449h-30v335.3c77.6-0.1,155.3-1.9,233-5.3V349c-58.1,7.8-127.7,7.3-203,2.6V449z" />
    </g>
  </svg>
);
