// @flow
import type {
  PrinterFriendlyTeam, PrinterFriendlyUnit, WargearListType, WargearType, AbilitiesListType,
  AbilitiesType, UnitType, UnitListType, TeamType,
} from '../../../types';
import { SPECIALISTS } from '../../../constants';

export const getActiveWargear = (wargear: WargearListType, name: string) => {
  const id = Object.keys(wargear).find((key) => wargear[key].name === name);
  return id ? wargear[id] : null;
};

export const getActiveAbilities = (abilities: AbilitiesListType, name: string) => {
  const id = Object.keys(abilities).find((key) => abilities[key].name === name);
  return id ? abilities[id] : null;
};

export const createPrinterFriendlyUnitWargear = (wargear: WargearListType,
  unitWargear: Array<string>): Array<WargearType> => unitWargear
  .map((name) => getActiveWargear(wargear, name) || {});

export const createPrinterFriendlyUnitAbilities = (abilities: AbilitiesListType,
  unitAbilities: Array<string>): Array<AbilitiesType> => unitAbilities
  .map((name) => getActiveAbilities(abilities, name) || {});

export const createPrinterFriendlyUnit = (wargear: WargearListType,
  abilities: AbilitiesListType, unit: UnitType): PrinterFriendlyUnit => {
  const {
    activeOptions, primaryWeapons, secondaryWeapons, abilities: unitAbilities,
    specialists, keywords, options, ...rest
  } = unit;
  const parsedSecondaryWeapons = secondaryWeapons
    ? createPrinterFriendlyUnitWargear(wargear, secondaryWeapons) : null;
  const parsedActiveOptions = activeOptions || null;
  const parsedAbilities = unitAbilities
    ? createPrinterFriendlyUnitAbilities(abilities, unitAbilities) : null;
  return {
    ...rest,
    secondaryWeapons: parsedSecondaryWeapons,
    activeOptions: parsedActiveOptions,
    abilities: parsedAbilities,
  };
};

export const createPrinterFriendlyTeam = (wargear: WargearListType,
  abilities: AbilitiesListType, teamUnits: UnitListType, team: TeamType): PrinterFriendlyTeam => {
  if (teamUnits) {
    return {
      ...team,
      units: Object.keys(teamUnits)
        .map((key) => createPrinterFriendlyUnit(wargear, abilities, teamUnits[key])),
    };
  }

  return {
    ...team,
    units: null,
  };
};

const isLeader = (unit: UnitType) => {
  if (!unit.specialist) return false;

  return unit.specialist.name === SPECIALISTS.LEADER;
};

const isSpecialist = (unit: UnitType) => {
  if (unit.specialist && unit.specialist.name !== SPECIALISTS.LEADER) return true;

  return false;
};

export {
  isLeader,
  isSpecialist,
};
