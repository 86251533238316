import styled from 'styled-components';

export default styled.div`
& {
  flex-direction: column !important;
}

& .stats-title {
  align-self: flex-start;
  flex: 0 0 100%;
}

& .form-elements {
  display: flex;
}

& input[type="text"] {
  padding: 0.3rem 0.1rem;
  width: 30px;
}

& label {
  flex: 1;

  & span {
    min-width: none;
  }
}
`;
