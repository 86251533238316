import styled from 'styled-components';

export const DisplayUnitStyles = styled.section`
width: 100%;
padding: 1rem;
background: var(--white);
height: 100vh;
`;

export const UnitListStyles = styled.div`
width: 100%;
height: calc(100vh - 100px);
overflow: scroll;

.selected-unit {
  background: var(--base110);
  color: var(--base080);
}

& li {
  transition: all 0.2s ease-in-out;
}

& li:hover {
  opacity: 1;
  background: var(--base);
  cursor: pointer;
}
`;
