// @flow
import React from 'react';
import {
  FiTrash2 as Delete, FiCopy as Copy,
} from 'react-icons/fi';
import { useFirebase } from '../Firebase';
import { useAuth } from '../Session';
import { ACTIONS } from '../../constants';
import { H } from '../../styles';
import type { TeamType } from '../../types';

const TeamOptions = ({ team, send }: {
  team: TeamType,
  send: Function
}) => {
  const firebase = useFirebase();
  const { uid } = useAuth();
  const onDeleteTeam = () => {
    firebase.deleteTeam(uid, team.id);
    send({ type: ACTIONS.DELETE_TEAM, data: team });
  };
  const onCopyTeam = () => {
    firebase.copyTeam(uid, team)
      .then((data) => {
        send({ type: ACTIONS.COPY_TEAM, data });
      });

    send(ACTIONS.CLOSE_TEAM_OPTIONS_MENU);
  };

  return (
    <div className="list-edit-menu">
      <button
        type="button"
        onClick={onCopyTeam}
        aria-label="Copy Team"
      >
        <Copy size="30" className="menu-icon" />
        <H as="h6">Copy</H>
      </button>
      <button
        type="button"
        onClick={() => {
      if (window.confirm('Are you sure you want to delete this team?')) {
        onDeleteTeam();
        send(ACTIONS.CLOSE_TEAM_OPTIONS_MENU);
      }
    }}
        aria-label="Delete Team"
      >
        <Delete size="30" className="menu-icon" />
        <H as="h6">Delete</H>
      </button>
    </div>
  );
};

export default TeamOptions;
