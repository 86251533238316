// @flow
import React from 'react';
import ListStyles from './List.styles';
import { H } from '../../../styles';

type ItemType = {|
  name: string,
  type: string,
|}

type ListType = {|
  [key: string]: ItemType
|}

const List = ({ items, onClick }: {
  items: ListType,
  onClick: Function
}) => {
  if (items) {
    return (
      <ListStyles>
        <H as="h5">Click to Edit</H>
        <ul className="scroller">
          {Object.keys(items).map((key) => (
            <li key={key}>
              <button
                type="button"
                onClick={() => onClick(key)}
              >
                {items[key].name || items[key].type}
              </button>
            </li>
))}
        </ul>
        {`Total: ${Object.keys(items).length} items`}
      </ListStyles>
    );
  }

  return (<p>No Items</p>);
};

export default List;
