// @flow
import React from 'react';
import {
  HashRouter as Router,
  Route, Switch,
} from 'react-router-dom';
import { ROUTES } from '../../constants';
import { withAuthentication } from '../Session';
import Navigation, { ScrollToTop } from '../Navigation';
import Footer from '../Footer';
import LandingPage from '../Landing';
import SignUpPage from '../Account/SignUp';
import SignInPage from '../Account/SignIn';
import PasswordForgetPage from '../Account/PasswordForget';
import AccountPage from '../Account';
import AboutPage from '../About';
import Encyclopedia from '../Encyclopedia';
import AdminPage from '../Admin';
import TeamManager from '../TeamManager';

const NotFound = () => (
  <p style={{ padding: '1rem' }}>
    <strong>For the Emperor!</strong>
    {' '}
    Page not found.
  </p>
);

const App = () => (
  <Router>
    <ScrollToTop />
    <Navigation />
    <Switch>
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.MY_TEAMS} component={TeamManager} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ABOUT} component={AboutPage} />
      <Route path={ROUTES.ENCYCLOPEDIA} component={Encyclopedia} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      <Route component={NotFound} />
    </Switch>
    <Footer />
  </Router>
);

export default withAuthentication(App);
