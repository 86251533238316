// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <circle cx="499.8" cy="387.4" r="86.8" />
      <path d="M618.2,18H381.4L192,324.3L354.6,981h290.5l162.6-656.7L618.2,18z M697,562.8l-36.8,36.8L560.6,500
		c-11,5.9-22.9,10.3-35.5,12.8v390.9H473V512.4c-12.1-2.6-23.6-6.9-34.2-12.7L339,599.6l-36.8-36.8l96.8-96.8
		c-12-15.3-20.5-33.5-24.5-53.3H274.1v-52.1h100.7c10.5-49.1,49.1-87.7,98.2-98.2v-42c-57.7-12.3-101-63.6-101-125h41
		c0,48,38.9,86.8,86.8,86.8s86.8-38.9,86.8-86.8h41c0,62-44.1,113.6-102.6,125.4V262c49.8,10,89.2,48.9,99.8,98.5h105.5v52.1H625.2
		c-4,19.9-12.6,38.2-24.7,53.6L697,562.8z"
      />
    </g>
  </svg>
);
