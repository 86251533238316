// @flow
import React from 'react';
import DisplayUnit from '../../../common/DisplayUnit';
import type { UnitType } from '../../../../../types';

type Props = {
  units: Array<UnitType>,
};

class PrintList extends React.Component<Props> {
  renderUnits() {
    const { units } = this.props;
    // $FlowFixMe
    return units.map((unit) => <DisplayUnit key={unit.id} unit={unit} />);
  }

  render() {
    return (
      <ul>
        {this.renderUnits()}
      </ul>
    );
  }
}
export default PrintList;
