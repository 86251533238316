// @flow
import React from 'react';
import { ALERTS } from '../../constants';

import './alert.css';

const AlertWithPosition = ({ children }: { children: Object }) => (
  <section className="kt-alert-wrapper">
    {children}
  </section>
);

const Alert = ({
  text, type, leftAlign, hide,
}: {
  text: string, type?: string, leftAlign?: boolean, hide?: boolean,
}) => {
  let cssType = '';
  switch (type) {
  case ALERTS.ERROR:
    cssType = ' kt-alert--error';
    break;

  case ALERTS.WARNING:
    cssType = ' kt-alert--warning';
    break;
  case ALERTS.SUCCESS:
    cssType = ' kt-alert--success';
    break;
  default:
  case ALERTS.INFO:
    cssType = ' kt-alert--info';
    break;
  }
  const showCssClass = hide ? '' : ' kt-alert--show';
  const leftAlignCss = leftAlign ? ' kt-alert-text-left' : '';

  return (
    <div className={`kt-alert${cssType}${leftAlignCss}${showCssClass}`}>
      {text}
    </div>
  );
};

Alert.defaultProps = {
  type: ALERTS.INFO,
  leftAlign: false,
  hide: false,
};

export default Alert;
export {
  AlertWithPosition,
};
