// @flow
import * as React from 'react';
import { ListUl } from '../../../styles/ListStyles';
import Unit from '../common/Unit';
import { isLeader, isSpecialist } from './EditTeam.utils';
import type { UnitListType, UnitType, UnitPropsType } from '../../../types';

type Props = {|
  units: UnitListType,
  action: Function,
|}

const createUnitProps = (unit: UnitType): UnitPropsType => ({
  isLeader: isLeader(unit),
  isSpecialist: isSpecialist(unit),
});

const ListUnits = ({
  units, action,
}: Props) => {
  const unitsMarkup = Object.keys(units).map((key) => {
    const unit = units[key];
    return (
      <Unit
        key={key}
        unit={unit}
        action={action}
        isEditable
        unitOnclick={(selectedUnit) => action('edit-unit', selectedUnit)}
        unitProps={createUnitProps(unit)}
      />
    );
  });

  return (
    <ListUl>
      {unitsMarkup}
    </ListUl>
  );
};

export default ListUnits;
