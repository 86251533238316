// @flow
import React from 'react';
import { FiCheckCircle as Close, FiXSquare as Abort } from 'react-icons/fi';
import ModalStyles from './ModalStyles';
import { MODAL_ICON } from '../../constants';

const Modal = ({
  children, onClick, iconType, wide,
}: {
  children: Object, onClick?: Function, iconType?: string, wide?: boolean,
}) => {
  let onClickMarkup = '';

  if (onClick) {
    const icon = iconType === MODAL_ICON.CLOSE ? <Close size="35" color="#99C624" /> : <Abort size="35" color="#c65124" />;
    onClickMarkup = (
      <button type="button" className="modal--button" onClick={() => onClick()}>
        {icon}
      </button>
    );
  }

  return (
    <ModalStyles wide={wide ? 'true' : 'false'}>
      <div
        className="modal--background"
        onClick={() => onClick && onClick()}
        onKeyPress={() => onClick && onClick()}
        aria-label="button"
        role="button"
        tabIndex={0}
        title="background"
      />
      <div className="modal">
        <div className="modal--inner">
          {children}
        </div>
        {onClickMarkup}
      </div>
    </ModalStyles>
  );
};

Modal.defaultProps = {
  onClick: null,
  iconType: '',
  wide: false,
};

export default Modal;
