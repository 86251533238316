import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink } from '../SignUp';
import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import { Content, FormStyles } from '../../../styles';

const SignInPage = () => (
  <Content title="Sign In" pt>
    <SignInForm />
    <SignUpLink />
  </Content>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        const ref = this.props.firebase.db.ref().child(`users/${authUser.user.uid}`);
        ref.update({ lastLoggedIn: Date.now() });

        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.MY_TEAMS);
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <FormStyles onSubmit={this.onSubmit}>
        <label htmlFor="true">
          <span>Email address</span>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </label>
        <label htmlFor="true">
          <span>Password</span>
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
        </label>
        <div>
          <button disabled={isInvalid} type="submit">
            Sign In
          </button>
          <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
        </div>
        {error && <p className="error">{error.message}</p>}
      </FormStyles>
    );
  }
}
const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInLink = () => (
  <p className="paragraph">
    Have an account?
    {' '}
    <Link to={ROUTES.SIGN_IN}>Sign in</Link>
  </p>
);


export default SignInPage;
export { SignInForm, SignInLink };
