// @flow
import React from 'react';
import FormStyles from './FormStyles';
import { updateUnitProperties } from './Forms.utils';
import { H } from '../../../../styles';
import type { FactionUnitType } from '../../../../types';

const Form = ({
  specialists, formValues, setFormValues, disabled,
}: {
  specialists: Array<string>,
  formValues: FactionUnitType,
  setFormValues: Function,
  disabled: boolean,
}) => (
  <FormStyles>
    <H as="h4" className="stats-title">Specialists</H>
    {specialists.map((specialist) => (
      <label key={specialist} htmlFor={specialist}>
        <input
          id={specialist}
          name={specialist}
          type="checkbox"
          checked={formValues && formValues.specialists ? formValues.specialists.find((s) => s === specialist) : ''}
          onChange={(e) => setFormValues({
 ...formValues,
            specialists: updateUnitProperties(e, formValues, specialist, 'specialists'),
})}
          disabled={disabled}
        />
        <span>{specialist}</span>
      </label>
  ))}
  </FormStyles>
);

export default Form;
