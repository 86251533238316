// @flow
import React from 'react';
import AbilitiesListStyles from './AbilitiesProfile.styles';

const updateProfile = (array, id, newValue) => {
  const copy = [...array];
  copy[id] = { ...copy[id], ...newValue };
  return copy;
};

const AbilitiesProfile = ({ setRules, rules, id }: {
  setRules: Function,
  rules: Array<any>,
  id: number
}) => (
  <AbilitiesListStyles>
    <label htmlFor="true">
      <span>Rule Id*</span>
      <input
        name="name"
        value={rules && rules[id] && rules[id].id}
        onChange={(e) => setRules(updateProfile(rules, id, { id: e.currentTarget.value }))}
        type="text"
        placeholder="5+"
      />
    </label>
    <label htmlFor="true">
      <span>Rule Name*</span>
      <input
        name="name"
        value={rules && rules[id] && rules[id].name}
        onChange={(e) => setRules(updateProfile(rules, id, { name: e.currentTarget.value }))}
        type="text"
        placeholder="Adrenalight"
      />
    </label>
    <label htmlFor="true">
      <span>Description</span>
      <textarea
        name="descr"
        value={rules && rules[id] && rules[id].descr}
        onChange={(e) => setRules(updateProfile(rules, id, { descr: e.currentTarget.value }))}
        type="text"
        placeholder="+1 to Attacks characteristic"
      />
    </label>
  </AbilitiesListStyles>
);

export default AbilitiesProfile;
