// @flow
import React from 'react';

import H from '../../../../styles/Headings';
import UnitStats from '../../DisplayTeam/UnitStats';
import Wargear from '../../DisplayTeam/Wargear';
import Abilites from '../../DisplayTeam/Abilities';
import type {
  UnitType, WargearListType, WargearType, SpecialistListType, AbilitiesListType,
} from '../../../../types';
import DisplayUnitStyles from './DisplayUnit.styles';

const getUnitWargear = (unitWargear: WargearType, wargear: WargearListType): WargearListType => Object
  .keys(wargear).reduce((wargearList, key) => {
    const match = unitWargear.find((weapon) => weapon === wargear[key].name);
    if (match) {
      return { ...wargearList, [wargear[key].id]: wargear[key] };
    }
    return wargearList;
  }, {});

const getUnitAbilities = (unitAbilities: Array<string>, abilities: AbilitiesListType): AbilitiesListType => Object
  .keys(abilities).reduce((abilitiesList, key) => {
    const match = unitAbilities.find((ability) => ability === abilities[key].name);
    if (match) {
      return { ...abilitiesList, [abilities[key].id]: abilities[key] };
    }
    return abilitiesList;
  }, {});

export default ({
  unit, wargear, specialists, abilities,
}: {
  unit: UnitType,
  wargear: WargearListType,
  specialists: SpecialistListType,
  abilities: AbilitiesListType
 }) => {
  const secondaryWeapons = unit.secondaryWeapons && wargear
    ? getUnitWargear(unit.secondaryWeapons, wargear) : null;
  const activeOptions = unit.activeOptions ? unit.activeOptions : null;
  const unitAbilites = unit.abilities && abilities
    ? getUnitAbilities(unit.abilities, abilities) : null;

  return (
    <DisplayUnitStyles>
      <H as="h3" bottomBorder>{unit.name}</H>
      <H as="h4">
        {unit.type}
        {' '}
        {unit.specialist && <strong>{unit.specialist.name}</strong>}
      </H>
      {unit.specialist
        && (
          <div style={{ padding: '0.5rem 0' }}>
            <H as="h6">{unit.specialist.levels.lvl1.abilities[0].name}</H>
            <p>{unit.specialist.levels.lvl1.abilities[0].descr}</p>
          </div>
        )}
      <UnitStats stats={unit.stats} />
      <Wargear wargear={{
 [unit.activeWeapon.id]: unit.activeWeapon,
        ...secondaryWeapons,
...activeOptions,
}}
      />
      {unitAbilites && <Abilites abilities={unitAbilites} />}
    </DisplayUnitStyles>
  );
};
