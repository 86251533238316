// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 988.6 335.6"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path d="M581,97.6c-15.8-46-62.3-53.1-82.4-54.1c0,0-1.7-0.1-4.6-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-3,0-4.6,0.1-4.6,0.1
		c-20.1,0.9-66.6,8.1-82.4,54.1c-13.4,39.2,12,83.5,6.8,89.5c-20.7,23.9,7.4,61.9,11.3,65c6.9,5.7,28.9-5.8,25.4,0.1
		c-2.6,4.5-5.1,38.4-5.1,38.4h6.8l3.3-11.8l5.8-0.1l1.1,11.9h11.9l1.1-11.3l4-0.6l0.6,11.9h9.7l0.4-12.2h7.8l0.4,12.2h9.7l0.6-11.9
		l4,0.6l1.1,11.3h11.9l1.1-11.9l5.8,0.1l3.3,11.8h6.8c0,0-2.4-33.9-5.1-38.4c-3.5-5.9,18.5,5.5,25.4-0.1c3.9-3.1,32-41.1,11.3-65
		C569,181.1,594.4,136.8,581,97.6z M481.1,211.8c-2.7,3-6.6,6.6-11.9,9.6c-12.7,7.1-25.3,6.1-30.3,5.4c-0.3,0-0.7-0.1-0.9-0.3
		c-11.1-7-15.3-18-12-24.8c0.5-1,2.4-4.5,8.2-6.6c0.3-0.1,0.7-0.1,1-0.1c7,0.6,15.8,2,25.4,5.1c7.8,2.4,14.5,5.4,19.9,8.2
		C481.8,208.9,482.1,210.7,481.1,211.8z M507.6,253.3c0,0.8-1,1.1-1.5,0.5c-1.2-1.5-2.8-3.3-5.1-4.9c-2.4-1.7-4.6-2.8-6.4-3.4
		c-1.7,0.6-4,1.7-6.4,3.4c-2.2,1.6-3.9,3.4-5.1,4.9c-0.5,0.6-1.5,0.3-1.5-0.5c-0.4-5.8-0.1-17.7,8.6-26.3c1.1-1.1,2.6-2.1,4.3-3
		c1.7,0.9,3.2,1.9,4.3,3C507.8,235.7,508,247.6,507.6,253.3z M550.2,226.5c-0.3,0.2-0.6,0.3-0.9,0.3c-5.1,0.7-17.6,1.7-30.3-5.4
		c-5.3-3-9.2-6.5-11.9-9.6c-1-1.2-0.7-3,0.7-3.7c5.5-2.8,12.1-5.8,19.9-8.2c9.7-3,18.4-4.4,25.4-5.1c0.3,0,0.7,0,1,0.1
		c5.8,2.1,7.7,5.6,8.2,6.6C565.5,208.5,561.3,219.5,550.2,226.5z"
      />
      <path d="M396.5,126.7c-8.2-2-16-7.7-25.1-11.5c-4.2-3.3-15.4-12-21.2-31.8c-6-20.5,12.3-43,14.4-47c8.9-2.4,11.5-1.9,20.4-4.4l0-32
		H0c7.8,16.1,15.8,31.4,23.9,46.1l237.2-1c0.4,2.2,0.9,4.5,1.4,6.9L37.2,69.4C52.5,95.3,68,118.7,83.4,140l182-56.5
		c1,3.5,2,7,3.2,10.6l-162.1,76.4c14.7,18.5,29,35,42.7,49.8l126.3-91.7c1.1,2.6,2.2,5.1,3.3,7.7L165.1,237.1
		c14.6,14.9,28.1,27.6,40.7,38.4l87.5-110.2c2.2,3.6,4.5,7.1,6.9,10.5l-78.4,112.8c21.2,16.6,39.2,27.3,54.2,34.2
		c13.3-39.6,28.4-86.9,41.6-125.6c3.1,2.9,4,4.4,7,6.6L286,327c25.6,9.8,41,7.7,47.7,6.4c2.1-0.4,4.2-0.9,6.2-1.4
		c5.5-37.2,9.6-78.5,15.2-115.5c2.7,0.3,5.2,0.4,7.5,0.4l-3.8,104.4c8.1-3.3,17.7-8.5,27-17c2.2-2,4.2-4,6-6c0-34.4,0-68.7,0-103.1
		c0.2-1.5,2.8,1.1,7.9-3.1c3-2.5,6.3-13.6,6.3-19.9c-4.3-12.5-7.5-14.9-8.9-32.3C397,140,396.5,131.1,396.5,126.7z"
      />
      <path d="M964.8,47.1c8.1-14.6,16.1-30,23.9-46.1h-385l0,32c8.9,2.4,11.5,1.9,20.4,4.4c2.2,4,20.4,26.5,14.4,47
		c-5.8,19.8-17,28.6-21.2,31.8c-9.1,3.9-16.9,9.5-25.1,11.5c0,4.4-0.5,13.3-0.5,13.3c-1.4,17.4-4.6,19.7-8.9,32.3
		c0,6.3,3.3,17.4,6.3,19.9c5.1,4.2,7.6,1.6,7.9,3.1c0,34.4,0,68.7,0,103.1c1.8,2,3.8,4,6,6c9.2,8.5,18.9,13.7,27,17L626,218
		c2.3,0,4.8-0.1,7.5-0.4c5.6,36.9,9.7,78.3,15.2,115.5c2,0.5,4.1,1,6.2,1.4c6.7,1.2,22.1,3.3,47.7-6.4l-38.5-123.2
		c2.9-2.2,3.9-3.7,7-6.6c13.2,38.8,28.2,86,41.6,125.6c15-6.9,33.1-17.7,54.2-34.2l-78.4-112.8c2.4-3.4,4.7-6.9,6.9-10.5l87.5,110.2
		c12.6-10.8,26.1-23.5,40.7-38.4L709.8,137.3c1.1-2.5,2.2-5.1,3.3-7.7l126.3,91.7c13.7-14.8,28-31.4,42.7-49.8L720,95.1
		c1.1-3.6,2.2-7.1,3.2-10.6l182,56.5c15.4-21.3,31-44.7,46.2-70.6L726.2,53c0.5-2.4,1-4.7,1.4-6.9L964.8,47.1z"
      />
    </g>
  </svg>
);
