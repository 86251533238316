// @flow
import React from 'react';
import EditUnit from './EditUnit';
import { useFirebase } from '../../Firebase';
import { useAuth } from '../../Session';
import { useRosterContext } from '../EditTeam/context';
import { ACTIONS } from '../../../constants';
import { totalUnitCost } from '../../../utils';
import type {
  UnitType,
} from '../../../types';

const Index = ({
  unit,
}: {
unit: UnitType,
}) => {
  const firebase = useFirebase();
  const { uid } = useAuth();
  const [current, send] = useRosterContext();
  const { team, specialists, wargear } = current.context;

  const onUpdateUnit = (newValues) => {
    const newUnit = { ...unit, ...newValues, totalCost: totalUnitCost({ ...unit, ...newValues }) };
    const unitCost = unit.totalCost;
    const costDiff = unitCost - newUnit.totalCost;
    const teamValues = {
      ...team,
      cost: team.cost - costDiff,
    };

    firebase.updateUnit(uid, team.id, newUnit);
    firebase.updateTeam(uid, team.id, teamValues);

    send({ type: ACTIONS.UPDATE_TEAM_UNIT, data: { ...newUnit } });
    send({ type: ACTIONS.UPDATE_PARENT_TEAM, data: { ...teamValues } });
    send({ type: ACTIONS.UPDATE_TEAM, data: { ...teamValues } });
  };

  return (
    <EditUnit
      onUpdateUnit={onUpdateUnit}
      specialists={specialists}
      wargear={wargear}
      unit={unit}
    />
  );
};

export default Index;
