// @flow
import type {
  UnitListType, FactionUnitType,
} from '../../../../types';

export const getUnitDependencies = (
  units: UnitListType, factionUnit: FactionUnitType,
): Array<string> => {
  if (!units && factionUnit.dependency) return factionUnit.dependency;
  if (!units || !factionUnit.dependency) return [];

  return factionUnit.dependency.reduce((remainingDependencies, dependency) => {
    const isValid = Object
      .keys(units)
      .find((uKey) => units[uKey].type === dependency || units[uKey].group === dependency);

    if (isValid) {
      return [];
    }

    return [...remainingDependencies, dependency];
  }, []);
};

export default getUnitDependencies;
