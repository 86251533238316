import styled from 'styled-components';

export const NavStyles = styled.header`
  border-bottom: 5px solid var(--red);
  background-color: var(--base120);
  color: var(--base080);
  width: 100%;
  
  nav {
    z-index: 100;
    position: relative;
  }

  a {
    text-decoration: none;
  }

  .hidden-nav {
    display: none;
  }

  #menu-toggle, .hamburger {
    display: none;
  }

  & .logo-icon {
      margin-top: 6px;
      width: 60px;
      fill: var(--base080);
    }

@media screen and (max-width: 550px) {
  h1 {
    font-size: 2em;
  }

  & > nav {
    padding: .5rem;
    position: fixed;
    flex: 1;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }

  .hidden-nav {
    display: flex;
    align-items: center;
    padding-left: .5rem;

    & .logo-icon {
      margin-top: 5px;
      width: 40px;
      fill: var(--base080);
    }
  }

  .hamburger {
    display: flex;
  }

  #menu-toggle:checked ~ ul {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-top: -5px;
  }
}
`;

export const NavUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.06);
  margin: 0;
  background: var(--base120);

  @media (max-width: 550px) {
    display: none;
  }
`;

export const NavLi = styled.li`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-columns: ${({ wide }) => wide && '2fr 1fr'};
  align-items: start;
  align-content: center;
  ${({ end }) => end && 'margin-left: auto;'};
  text-align: center;
  min-width: 105px;
  max-height: 55px;
  overflow: hidden;

  a {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    transition: background 1s;
  }

  a .menu-icon {
    width: 30px;
    transition: all .2s ease-in-out;
  }

  a:hover {
    background: var(--red);

    & > h6 {
      display: none;
    }

    & .menu-icon {
      margin-top: 6px;
      transform: scale(1.5);
    }
  }

  .active {
    background: var(--red);
  }

  @media (max-width: 550px) {
    margin: 0;
    min-width: 150px;
    min-height: auto;
    text-align: left;
    border-bottom: 1px solid var(--base110);
    ${({ mobileHidden }) => mobileHidden && 'display: none'};

    .menu-icon {
      display: none
    }

    & > a {
      padding: 0.5rem 0.5rem 0.6rem;
    }

    a > h1 {
      font-size: 1em;
    }
    
    a > h6 {
      display: inline-block;
      font-size: 0.8em;
    }
    a .menu-icon {
      transform: scale(0.5);
    }

    a:hover {
      & > .menu-icon {
      transform: scale(1);
      }

      & > h6 {
        display: inline-block;
      }
    }
  }
`;
