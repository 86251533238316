import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import adminMachine from './stateChart';
import {
  ACTIONS, SERVICES,
} from '../../../constants';

export default (firebase) => useMachine(adminMachine, {
  actions: {
    [ACTIONS.SET_FACTIONS]: assign({
      factions: (_, event) => event.data,
    }),
    addFaction: assign((ctx, event) => {
      const faction = event.data;
      return {
        factions: {
          ...ctx.factions,
          [faction.id]: faction,
        },
      };
    }),
    updateFaction: assign((ctx, event) => {
      const faction = event.data;
      return {
        factions: {
          ...ctx.factions,
          [faction.id]: faction,
        },
      };
    }),
    [ACTIONS.SET_WARGEAR]: assign({
      wargear: (_, event) => event.data,
    }),
    addWargear: assign((ctx, event) => {
      const wargear = event.data;
      return {
        wargear: {
          ...ctx.wargear,
          [wargear.id]: wargear,
        },
      };
    }),
    updateWargear: assign((ctx, event) => {
      const wargear = event.data;
      return {
        wargear: {
          ...ctx.wargear,
          [wargear.id]: wargear,
        },
      };
    }),
    [ACTIONS.SET_ABILITIES]: assign({
      abilities: (_, event) => event.data,
    }),
    addAbility: assign((ctx, event) => {
      const ability = event.data;
      return {
        abilities: {
          ...ctx.abilities,
          [ability.id]: ability,
        },
      };
    }),
    updateAbility: assign((ctx, event) => {
      const ability = event.data;
      return {
        abilities: {
          ...ctx.abilities,
          [ability.id]: ability,
        },
      };
    }),
    [ACTIONS.SET_SPECIALISTS]: assign({
      specialists: (_, event) => event.data,
    }),
    addSpecialist: assign((ctx, event) => {
      const specialist = event.data;
      return {
        specialists: {
          ...ctx.specialists,
          [specialist.id]: specialist,
        },
      };
    }),
    updateSpecialist: assign((ctx, event) => {
      const specialist = event.data;
      return {
        specialists: {
          ...ctx.specialists,
          [specialist.id]: specialist,
        },
      };
    }),
    [ACTIONS.SET_FACTION_UNITS]: assign({
      factionUnits: (_ctx, event) => event.data,
    }),
    setFaction: assign({
      faction: (_ctx, event) => event.data,
    }),
    addFactionUnit: assign((ctx, event) => {
      const unit = event.data;
      return {
        factionUnits: {
          ...ctx.factionUnits,
          [unit.id]: unit,
        },
      };
    }),
    updateFactionUnit: assign((ctx, event) => {
      const unit = event.data;
      return {
        factionUnits: {
          ...ctx.factionUnits,
          [unit.id]: unit,
        },
      };
    }),
  },
  services: {
    [SERVICES.GET_FACTIONS]: () => firebase.getFactions().orderByChild('name').once('value')
      .then((snapshot) => {
        const factions = snapshot.val();
        return factions;
      }),
    [SERVICES.GET_WARGEAR]: () => firebase.getByNamespace('wargear').orderByChild('name').once('value')
      .then((snapshot) => {
        let data = {};
        snapshot.forEach((childSnapshot) => {
          const { key } = childSnapshot;
          data = { ...data, [key]: childSnapshot.val() };
        });

        return data;
      }),
    [SERVICES.GET_ABILITITES]: () => firebase.getByNamespace('abilities').orderByChild('name').once('value')
      .then((snapshot) => {
        let data = {};
        snapshot.forEach((childSnapshot) => {
          const { key } = childSnapshot;
          data = { ...data, [key]: childSnapshot.val() };
        });

        return data;
      }),
    [SERVICES.GET_SPECIALISTS]: () => firebase.getByNamespace('specialists').orderByChild('name').once('value')
      .then((snapshot) => {
        let data = {};
        snapshot.forEach((childSnapshot) => {
          const { key } = childSnapshot;
          data = { ...data, [key]: childSnapshot.val() };
        });

        return data;
      }),
    [SERVICES.GET_FACTION_UNITS]: (context) => firebase.getFactionUnits(context.faction.id).once('value')
      .then((snapshot) => snapshot.val()),
  },
  guards: {
  },
});
