// @flow
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import './spinner.css';

type Props = {|
  label: string,
  color?: string,
|}
const Spinner = ({ label, color }: Props) => (
  <div className="kt-spinner">
    <ClipLoader color={color} />
    {label && <span>{label}</span>}
  </div>
);

Spinner.defaultProps = {
  color: '#c65124',
};

export default Spinner;
