// @flow
import React, { useState } from 'react';
import { FiInfo as Info } from 'react-icons/fi';
import H from '../../../styles/Headings';
import SimpleProfile from './SimpleProfile';
import type { WargearType, WargearListType } from '../../../types';
import WargearStyles, { OptionsProfileStyles } from './WargearOptionsStyles';
import { getWargear } from './EditUnit.utils';

const wargearCost = (wargear: WargearType) => {
  if ('cost' in wargear) return wargear.cost;

  return wargear.profile ? Object.keys(wargear.profile)
    .reduce((total, key) => total + wargear.profile[key].cost, 0) : 0;
};

const OptionsProfile = ({ profile }: { profile: WargearType }) => (
  <OptionsProfileStyles>
    {profile.abilities}
  </OptionsProfileStyles>
);

const WargearOptions = ({
  wargear,
  activeOptions,
  options, updateUnit,
}: {
  wargear: WargearListType,
  activeOptions?: Array<WargearType>,
  options: Array<string>,
  updateUnit: Function,
}) => {
  const [showProfile, setShowProfile] = useState(null);
  const toggleProfile = (id) => {
    const profileId = showProfile === id ? null : id;
    setShowProfile(profileId);
  };
  const optionsMarkup = options.map((option) => {
    const wg = getWargear(option, wargear);
    const optionCost = wg && 'cost' in wg ? wg.cost : wargearCost(wg);
    // const isChecked = wargearOptions.find((opt) => opt === option);
    const isChecked = activeOptions && activeOptions.find((op) => op.name === option);
    const isUnique = wg && wg.max === 1 && 'Only one per Kill Team';
    const displayProfile = wg && wg.group === 'Other' ? <OptionsProfile profile={wg} /> : <SimpleProfile profile={wg} />;
    return (
      <li key={option} className="wargear-item">
        <div className="wargear-item-row--name">
          <label htmlFor={option}>
            <input
              id={option}
              name={option}
              type="checkbox"
              defaultChecked={isChecked}
              onChange={(e) => updateUnit([e, option], 'options')}
            />
            {option}
          </label>
          <strong>{` ${optionCost}pts`}</strong>
          <span className="error">
            {isUnique}
          </span>
        </div>
        <div>
          <button
            type="button"
            className="kt-btn-icon wargear-view-info-btn"
            onClick={() => toggleProfile(option)}
          >
            <Info color="#2499C6" size="20" />
          </button>
        </div>
        {showProfile === option
          && (
            <div className="wargear-item-row--wide">
              {displayProfile}
            </div>
          )}
      </li>
    );
  });

  return (
    <WargearStyles>
      <H as="h5" pb>Optional Wargear</H>
      <ul>
        {optionsMarkup}
      </ul>
    </WargearStyles>
  );
};

WargearOptions.defaultProps = {
  activeOptions: null,
};


export default WargearOptions;
