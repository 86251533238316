// @flow
import React from 'react';
import { SelectBox } from '../../common';
import { updateWargear } from '../FactionUnits.utils';
import type { FactionUnitType } from '../../../../types';
import FormStyles from './FormStyles';

const Form = ({
  wargearList,
  formValues,
  setFormValues,
  secondaryWeaponsCount,
  disabled,
}: {
  wargearList: Array<string>,
  formValues: FactionUnitType,
  setFormValues: Function,
  secondaryWeaponsCount: number,
  disabled: boolean,
}) => (
  <FormStyles>
    {formValues && formValues.secondaryWeapons
      && formValues.secondaryWeapons.map((secondaryWeapon, i) => (
        <SelectBox
          key={secondaryWeapon}
          name={`Secondary Weapon ${i}`}
          data={wargearList}
          onSelect={(e) => setFormValues({
 ...formValues,
            secondaryWeapons: updateWargear(formValues,
              'secondaryWeapons', e.target[e.target.selectedIndex].value, i),
})}
          selectedData={formValues && formValues.secondaryWeapons ? formValues.secondaryWeapons[i] : ''}
          disabled={disabled}
        />
))}
    <SelectBox
      name={`Secondary Weapon ${secondaryWeaponsCount}`}
      data={wargearList}
      onSelect={(e) => setFormValues({
 ...formValues,
          secondaryWeapons: updateWargear(formValues,
            'secondaryWeapons', e.target[e.target.selectedIndex].value, secondaryWeaponsCount),
})}
      selectedData=""
      disabled={disabled}
    />
  </FormStyles>
);

export default Form;
