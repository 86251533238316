// @flow
import groupUnits from './groupUnits';
import type {
  WargearType, WargearListType, UnitType, UnitListType,
} from '../types';

const getActiveWeaponCost = (weapon: WargearType) => {
  if ('cost' in weapon) return weapon.cost;

  return weapon.profile ? Object.keys(weapon.profile).reduce((total, key) => {
    const profile = weapon.profile ? weapon.profile : null;
    if (profile && profile[key]) {
      return total + profile[key].cost;
    }

    return total;
  }, 0)
    : 0;
};

const getActiveOptionsCost = (options: Array<WargearType>) => options
  .reduce((total, option) => {
    if ('cost' in option) return total + option.cost;
    if (option.profile) {
      return Object.keys(option.profile).reduce((subTotal, key) => {
        const profile = option.profile ? option.profile : null;
        if (profile && profile[key]) {
          return subTotal + profile[key].cost;
        }

        return subTotal;
      }, 0);
    }

    return total;
  }, 0);

const totalUnitCost = (unit: UnitType) => {
  const unitCost = unit.cost;
  const activeWeaponCost = unit.activeWeapon
    ? getActiveWeaponCost(unit.activeWeapon) : 0;
  const activeOptionsCost = unit.activeOptions
  && unit.activeOptions.length
    ? getActiveOptionsCost(unit.activeOptions) : 0;
  return Number(unitCost) + Number(activeWeaponCost) + Number(activeOptionsCost);
};

const totalTeamCost = (units: UnitListType) => (units
  ? Object.keys(units).reduce((totalCost, u) => totalCost + totalUnitCost(units[u]), 0) : 0);

const sortWargear = (wargear: WargearListType) => {
  const sortingMap = Object.keys(wargear).sort((a, b) => {
    if (wargear[a].name < wargear[b].name || wargear[b].passive) {
      return -1;
    }
    if (wargear[a].name > wargear[b].name || wargear[a].passive) {
      return 1;
    }
    return 0;
  });

  return sortingMap.reduce((sortedObject, key) => ({
    ...sortedObject,
    [key]: wargear[key],
  }), {});
};

const hasValidDependency = (units: UnitListType, unit: UnitType) => {
  if (!unit.dependency) return true;
  return !!unit.dependency.find((dependency) => units
    && Object.keys(units).find((key) => dependency === units[key].type
      || dependency === units[key].group));
};

const getUnitTypeCount = (units: { [string]: UnitType }, unit: UnitType) => (units
  ? Object.keys(units)
    .reduce((total, u) => {
      let newTotal = total;
      if (units[u].type === unit.type && units[u].group === unit.group) {
        newTotal += 1;
      }
      return newTotal;
    }, 0) : 0);

const isUnitAvailable = (units: { [string]: UnitType }, unit: UnitType) => {
  if (unit.stats.max === -1) return true;

  const unitTypeTotal = getUnitTypeCount(units, unit);

  return unitTypeTotal < unit.stats.max;
};

export {
  groupUnits,
  totalUnitCost,
  totalTeamCost,
  sortWargear,
  hasValidDependency,
  isUnitAvailable,
  getUnitTypeCount,
};
