// @flow

import type { FactionUnitType, WargearListType } from '../../../types';

export const createWargearList = (wargear: WargearListType): Array<string> => Object
  .keys(wargear).reduce((array, key) => [...array, wargear[key].name], []);

export const getActiveWargear = (wargear: WargearListType, name: string) => {
  const id = Object.keys(wargear).find((key) => wargear[key].name === name);
  return id ? wargear[id] : null;
};

export const updateWargear = (formValues: FactionUnitType,
  type: string,
  selectedWargear: string,
  i: number): Array<string> => {
  const newFormValues = { ...formValues };

  // Remove weapon
  if (selectedWargear === '') {
    const deletedWargear = formValues[type][i];
    return newFormValues[type].filter((s) => s !== deletedWargear);
  }
  // PrimaryWeapon set earlier
  if (newFormValues[type] && newFormValues[type][i]) {
    newFormValues[type][i] = selectedWargear;
    return newFormValues[type];
  }

  // Add new weapon
  if (newFormValues[type]) return [...newFormValues[type], selectedWargear];

  return [selectedWargear];
};
