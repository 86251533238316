// @flow
import * as React from 'react';
import {
  FiEdit as Edit, FiMoreVertical as Options,
} from 'react-icons/fi';
import UnitWargear from './UnitWargear';
import { Skull } from '../../../assets/icons';
import { ListLi } from '../../../styles/ListStyles';
import UnitOptionsMenu from '../EditTeam/UnitOptionsMenu';
import type { UnitType } from '../../../types';

const Unit = ({
  unit, action, unitOnclick, isEditable = false, unitProps,
}: {
  unit: UnitType,
  action?: Function,
  unitOnclick: Function,
  isEditable?: boolean,
  unitProps: any,
}) => {
  const {
    isLeader, isSpecialist, isSelected, isDisabled, unitLimit,
  } = unitProps;
  const [displayUnitOptions, setDisplayUnitOptions] = React.useState(false);
  const isLeaderCssClass = isLeader ? ' is-leader' : '';
  const isSpecialistCssClass = isSpecialist ? ' is-specialist' : '';
  const isSelectedCssClass = isSelected ? ' is-active' : '';
  const isDisabledCssClass = isDisabled ? ' disabled' : '';
  console.log(unit);
  return (
    <ListLi className={`border-left${isLeaderCssClass}${isSpecialistCssClass}${isSelectedCssClass}${isDisabledCssClass}`}>
      <div className="list-name" title="Edit Unit">
        <button
          type="button"
          onClick={() => (isDisabled ? null : unitOnclick(unit))}
          aria-label="Edit Unit"
          className="edit-list-btn"
        >
          <Skull className="faction-icon" />
          <Edit className="icon" />
          <div className="list-info">
            <h5>{unit.name}</h5>
            <p className="strong">
              <span>{`${unit.type} `}</span>
              {unit.specialist && (
              <span>
                •
                <span className="highlight">
                  {` ${unit.specialist.name} `}
                </span>
              </span>
          )}
              {`• ${unit.totalCost} points`}
              {unitLimit && ` • ${unitLimit}`}
            </p>
            <p>
              <UnitWargear unit={unit} />
            </p>
          </div>
        </button>
      </div>
      {isEditable
      && (
      <div className="list-edit" title="Options Menu">
        <button
          type="button"
          className="list-edit-btn"
          onClick={() => setDisplayUnitOptions(!displayUnitOptions)}
        >
          <Options size="25" />
        </button>
      </div>
      )}
      {isEditable && action
      && (
      <UnitOptionsMenu
        isVisible={displayUnitOptions}
        setIsVisible={setDisplayUnitOptions}
        unit={unit}
        action={action}
      />
      )}
    </ListLi>
  );
};

Unit.defaultProps = {
  action: null,
  isEditable: false,
};

export default Unit;
