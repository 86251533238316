// @flow
import React from 'react';
import type { FactionListType } from '../../../types';

const FactionList = ({ factions, onClick }: { factions: FactionListType, onClick: Function}) => {
  if (factions) {
    return (
      <div>
        <ul>
          {Object.keys(factions).map((key) => (
            <li key={key}>
              <button
                type="button"
                onClick={() => onClick(key)}
              >
                {factions[key].name}
              </button>
            </li>
))}
        </ul>
        {`Total: ${Object.keys(factions).length} factions`}
      </div>
    );
  }

  return (<p>No Factions</p>);
};

export default FactionList;
