export const IDLE = 'idle';
export const GETTING_FACTIONS = 'gettingFactions';
export const GETTING_LOCAL_FACTIONS = 'gettingLocalFactions';
export const SELECTED = 'selected';
export const GETTING_TEAMS = 'gettingTeams';
export const SUCCESS = 'success';
export const GETTING_FACTION_UNITS = 'gettingFactionUnits';
export const GETTING_TEAM_UNITS = 'gettingTeamUnits';
export const FAILURE = 'failure';
export const TEAM_OPTIONS_MENU = 'teamOptionsMenu';
export const UNIT_OPTIONS_MENU = 'unitOptionsMenu';
export const GETTING_TEAM_LISTS = 'gettingTeamLists';
export const GETTING_SPECIALISTS = 'gettingSpecialists';
export const GETTING_ABILITIES = 'gettingAbilities';
export const GETTING_WARGEAR = 'gettingWargear';
