// @flow
import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import Spinner from '../Spinner';
import WargearList from './WargearList';

const Encyclopedia = ({ firebase }: { firebase: Object }) => {
  const [wargear, setWargear] = useState();

  useEffect(() => {
    const content = firebase.getWargear()
      .then((snapshot) => {
        setWargear(snapshot.val());
      });

    return () => content;
  }, []);

  if (!wargear) return <Spinner label="Loading Wargear" />;

  return (
    <section className="content">
      <h1 className="sub-title red">Encyclopedia</h1>
      {/* <hr className="divider" />
      <div
        className="content-inner"
        style={{
          fontSize: '0.8rem',
        }}
      >
        {wargear && <WargearList wargear={wargear} />}
      </div> */}
    </section>
  );
};

export default withFirebase(Encyclopedia);
