import styled from 'styled-components';

export default styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--red);
  
  color: var(--base080);
  font-family: 'Oxanium';

  & > div:last-child {
    padding: 0.5rem;
  }

  .footer-bottom {
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;

    & > a {
      color: var(--base130);
      font-size: 0.7em;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 0.5rem;
    }
  }

  .logo {
    width: 75px;
    height: 75px;
  }
`;
