import React from 'react';
import { Content } from '../../styles';

const About = () => (
  <Content title="About" pt>
    <p>
      This is just a fun project to test some new techs.
    </p>
    <br />
    <p>
      Made by a simple web developer with too much interest in 40k...
    </p>
    <br />
    <p>
      Big thank you to
      {' '}
      <a href="http://bakadesign.dk/warhammer-40-000-icons/">BakaDesign</a>
      {' '}
      for providing awesome 40k icons!
    </p>
    <br />
    <p>
      Big thank you to
      {' '}
      <a href="https://react-icons.github.io/react-icons/">React Icons</a>
      {' '}
      for providing awesome icons!
    </p>
  </Content>
);
export default About;
