import React, { useContext } from 'react';

const FirebaseContext = React.createContext(null);

export default FirebaseContext;

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} /> /* eslint-disable-line */ }
  </FirebaseContext.Consumer>
);

export const useFirebase = () => useContext(FirebaseContext);
