// @flow
import React from 'react';
import { SelectBox } from '../../common';
import { getActiveWargear, updateWargear } from '../FactionUnits.utils';
import type { FactionUnitType, WargearListType } from '../../../../types';
import FormStyles from './FormStyles';

const Form = ({
  wargear,
  wargearList,
  formValues,
  setFormValues,
  primaryWeaponsCount,
  disabled,
}: {
  wargear: WargearListType,
  wargearList: Array<string>,
  formValues: FactionUnitType,
  setFormValues: Function,
  primaryWeaponsCount: number,
  disabled: boolean,
}) => (
  <FormStyles>
    <SelectBox
      name="Active Weapon"
      data={wargearList}
      onSelect={(e) => setFormValues({
...formValues,
    activeWeapon: getActiveWargear(wargear, e.target[e.target.selectedIndex].value),
})}
      selectedData={formValues && formValues.activeWeapon ? formValues.activeWeapon.name : ''}
      disabled={disabled}
    />
    <p className="error">Please add active weapon to primary weapons as well</p>
    {formValues && formValues.primaryWeapons
&& formValues.primaryWeapons.map((primaryWeapon, i) => (
  <SelectBox
    key={primaryWeapon}
    name={`Primary Weapon ${i}`}
    data={wargearList}
    onSelect={(e) => setFormValues({
...formValues,
      primaryWeapons: updateWargear(formValues,
        'primaryWeapons', e.target[e.target.selectedIndex].value, i),
})}
    selectedData={formValues && formValues.primaryWeapons ? formValues.primaryWeapons[i] : ''}
    disabled={disabled}
  />
))}
    <SelectBox
      name={`Primary Weapon ${primaryWeaponsCount}`}
      data={wargearList}
      onSelect={(e) => setFormValues({
...formValues,
    primaryWeapons: updateWargear(formValues,
      'primaryWeapons', e.target[e.target.selectedIndex].value, primaryWeaponsCount),
})}
      selectedData=""
      disabled={disabled}
    />
  </FormStyles>
);

export default Form;
