// @flow
import type { TeamListCollectionType } from '../../../../types';
import { TEAM_LIST_TYPES } from '../../../../constants';

export default ({
  [TEAM_LIST_TYPES.BATTLE_FORGED]: {
    type: TEAM_LIST_TYPES.BATTLE_FORGED,
    descr: '',
    maxPoints: 100,
    leaderLimit: 1,
    specialistLimit: 3,
    totalCost: 0,
  },
  [TEAM_LIST_TYPES.UNLIMITED]: {
    type: TEAM_LIST_TYPES.UNLIMITED,
    descr: '',
    maxPoints: -1,
    leaderLimit: -1,
    specialistLimit: -1,
    totalCost: 0,
  },
  [TEAM_LIST_TYPES.OPEN_PLAY]: {
    type: TEAM_LIST_TYPES.OPEN_PLAY,
    descr: 'Simply choose a kill team from the models available to you, choose one of those models to be the kill team’s Leader',
    maxPoints: -1,
    leaderLimit: 1,
    specialistLimit: -1,
    totalCost: 0,
  },
  [TEAM_LIST_TYPES.CUSTOM]: {
    type: TEAM_LIST_TYPES.CUSTOM,
    descr: '',
    maxPoints: 100,
    leaderLimit: 1,
    specialistLimit: 3,
    totalCost: 0,
  },
}: TeamListCollectionType);
