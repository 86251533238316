import { Machine } from 'xstate';
import {
  STATES, ACTIONS, SERVICES,
} from '../../../constants';

export default Machine({
  id: 'admin',
  context: {
    factions: undefined,
    wargear: undefined,
    abilities: undefined,
    specialists: undefined,
    faction: null,
  },
  initial: STATES.GETTING_FACTIONS,
  states: {
    initial: {

    },
    [STATES.GETTING_FACTIONS]: {
      invoke: {
        src: SERVICES.GET_FACTIONS,
        onDone: {
          target: STATES.GETTING_WARGEAR,
          actions: ACTIONS.SET_FACTIONS,
        },
      },
    },
    [STATES.GETTING_WARGEAR]: {
      invoke: {
        src: SERVICES.GET_WARGEAR,
        onDone: {
          target: STATES.GETTING_ABILITIES,
          actions: ACTIONS.SET_WARGEAR,
        },
      },
    },
    [STATES.GETTING_ABILITIES]: {
      invoke: {
        src: SERVICES.GET_ABILITITES,
        onDone: {
          target: STATES.GETTING_SPECIALISTS,
          actions: ACTIONS.SET_ABILITIES,
        },
      },
    },
    [STATES.GETTING_SPECIALISTS]: {
      invoke: {
        src: SERVICES.GET_SPECIALISTS,
        onDone: {
          target: 'factionUnits',
          actions: ACTIONS.SET_SPECIALISTS,
        },
      },
    },
    faction: {
      initial: 'addFactionForm',
      states: {
        addFactionForm: {
          on: {
            ADD_FACTION: { actions: 'addFaction' },
            UPDATE_FACTION: { actions: 'updateFaction' },
          },
        },
      },
    },
    wargear: {
      initial: 'addWargearForm',
      states: {
        addWargearForm: {
          on: {
            ADD_WARGEAR: { actions: 'addWargear' },
            UPDATE_WARGEAR: { actions: 'updateWargear' },
          },
        },
      },
    },
    abilities: {
      initial: 'addAbilityForm',
      states: {
        addAbilityForm: {
          on: {
            ADD_ABILITY: { actions: 'addAbility' },
            UPDATE_ABILITY: { actions: 'updateAbility' },
          },
        },
      },
    },
    specialists: {
      initial: 'addSpecialistForm',
      states: {
        addSpecialistForm: {
          on: {
            ADD_SPECIALIST: { actions: 'addSpecialist' },
            UPDATE_SPECIALIST: { actions: 'updateSpecialist' },
          },
        },
      },
    },
    factionUnits: {
      initial: 'addFactionUnitForm',
      states: {
        addFactionUnitForm: {
          on: {
            ADD_FACTION_UNIT: { actions: 'addFactionUnit' },
            UPDATE_FACTION_UNIT: { actions: 'updateFactionUnit' },
            SELECT_FACTION: { target: STATES.GETTING_FACTION_UNITS, actions: 'setFaction' },
          },
        },
        [STATES.GETTING_FACTION_UNITS]: {
          invoke: {
            id: SERVICES.GET_FACTION_UNITS,
            src: SERVICES.GET_FACTION_UNITS,
            onDone: {
              target: 'addFactionUnitForm',
              actions: ACTIONS.SET_FACTION_UNITS,
            },
          },
        },
      },
    },
  },
  on: {
    ADD_FACTION: { target: 'faction' },
    ADD_WARGEAR: { target: 'wargear' },
    ADD_ABILITY: { target: 'abilities' },
    ADD_SPECIALIST: { target: 'specialists' },
    ADD_FACTION_UNIT: { target: 'factionUnits' },
  },
});
