import styled from 'styled-components';

export default styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  margin: 0 auto;
  font-family: 'Oxanium';
  font-weight: bold;

  & label {
    flex: 0 0 100%;
  }

  & textarea {
    width: 100%;
    height: 100px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }

  & input[type="text"], & input[type="password"] {
    margin: 0.2rem 0 0.5rem;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;

    &:disabled {
      background: var(--base110);
    }
  }

  & button {
    width: 100%;
    background-color: var(--red);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  & button:disabled {
    background-color: var(--base);
  }

  & > div {
    flex-direction: column;
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
  }

  & a {
    font-size: 0.9em;
    padding: 0.5rem;
  }

  .error {
    color: var(--error);
  }

  @media screen and (min-width: 550px) {
    max-width: 575px;

    & > div {
      flex-direction: row;

      & button {
        flex: 1;
        max-width: 250px;
      }

      & a {
        flex: 1;
      }
    }

    & label {
      display: flex;
      align-items: center;

      & > span {
        min-width: 150px;
      } 
    }
     & input[type="text"], & input[type="password"] {
      flex: 1;
    }
  }
`;
