// @flow
import React from 'react';
import type { UnitType } from '../../../types';

const UnitWargear = ({ unit }: { unit: UnitType}) => {
  const activeWeapon = unit.activeWeapon ? unit.activeWeapon.name : null;
  const secondaryWeapons = unit.secondaryWeapons ? unit.secondaryWeapons : [];
  const activeOptions = unit.activeOptions ? unit.activeOptions.map((option) => option.name) : [];

  return (<span>{[activeWeapon, ...secondaryWeapons, ...activeOptions].filter(Boolean).join(', ')}</span>);
};

export default UnitWargear;
