const ERROR = 'error';
const INFO = 'info';
const WARNING = 'warning';
const SUCCESS = 'success';

export default {
  ERROR,
  INFO,
  WARNING,
  SUCCESS,
};
