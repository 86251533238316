// @flow
import React from 'react';
import FormStyles from './FormStyles';
import { updateUnitProperties } from './Forms.utils';
import { H } from '../../../../styles';
import type { FactionUnitType } from '../../../../types';

const Form = ({
  abilities, formValues, setFormValues, disabled,
}: {
  abilities: Array<string>,
  formValues: FactionUnitType,
  setFormValues: Function,
  disabled: boolean,
}) => (
  <FormStyles>
    <H as="h4" className="stats-title">Abilities</H>
    {abilities.map((ability) => (
      <label key={ability} htmlFor={ability}>
        <input
          id={ability}
          name={ability}
          type="checkbox"
          checked={formValues && formValues.abilities ? formValues.abilities.find((a) => a === ability) : ''}
          onChange={(e) => setFormValues({
 ...formValues,
            abilities: updateUnitProperties(e, formValues, ability, 'abilities'),
})}
          disabled={disabled}
        />
        <span>{ability}</span>
      </label>
))}
  </FormStyles>
);

export default Form;
