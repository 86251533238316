import styled from 'styled-components';

export default styled.table`
  margin-top: 5px;
  width: 100%;
  font-size: 0.8em;
  border-collapse:collapse;
  font-size: 1.1em;

  thead tr th {
    border-bottom: 1px solid var(--red);
  }

  tbody tr td:first-child {
    width: 130px;
  }
  tbody tr td {
    vertical-align: top;
    text-align: center;
  }

  tbody tr td:last-child {
    max-width: 100px;
  }

  tbody tr:nth-child(even) {
    background: var(--base080);
  }

  tbody tr td.left {
    text-align: left;
  }

  tbody tr td.limited {
    width: 75px;
  }

  tbody tr td.small {
    width: 40px;
  }
`;
