// @flow
import React, { useState } from 'react';
import { useService } from '@xstate/react';
import { createPrinterFriendlyTeam } from './EditTeam.utils';
import { useFirebase } from '../../Firebase';
import { useAuth } from '../../Session';
import AddUnit from './AddUnit';
import ListUnits from './ListUnits';
import EditTeamHeader from './EditTeamHeader';
import DisplayTeam from '../DisplayTeam';
import Spinner from '../../Spinner';
import Modal from '../../Modal';
import { ACTIONS, STATES, MODAL_ICON } from '../../../constants';
import EditUnit from '../EditUnit';
import { ListMenu, ListEdit, AddList } from '../RosterList';
import type {
  UnitType, UnitListType,
} from '../../../types';
import RosterContext from './context';

export const getUnitTypeCount = (units: UnitListType, typeId: string): number => (units ? Object
  .keys(units)
  .reduce((total, key) => {
    let count = 0;
    if (units[key].type === typeId) {
      count = 1;
    }
    return total + count;
  }, 0) : 0);

const Index = ({ service }: { service: any }) => {
  const [current, send] = useService(service);
  const {
    team, units, factionUnits, lists, wargear, abilities,
  } = current.context;
  const firebase = useFirebase();
  const { uid } = useAuth();
  const [editUnit, setEditUnit] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [teamListEdit, setTeamListEdit] = useState(false);
  const [teamListAdd, setTeamListAdd] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const addUnitDisabled = !factionUnits;
  const printerFriendlyTeam = createPrinterFriendlyTeam(wargear, abilities, units, team);

  const onCopyUnit = (unit) => {
    firebase.copyUnit(uid, team.id, unit, (newUnit) => {
      send({ type: ACTIONS.COPY_TEAM_UNIT, data: newUnit });

      const teamValues = {
        ...team,
        cost: team.cost + unit.totalCost,
        units: team.units + 1,
      };

      firebase.updateTeam(uid, team.id, teamValues);

      send({ type: ACTIONS.UPDATE_PARENT_TEAM, data: { ...teamValues, id: team.id } });
      send({ type: ACTIONS.UPDATE_TEAM, data: { ...teamValues } });
    });
  };

  const onDeleteUnit = (unit) => {
    firebase.deleteUnit(uid, team.id, unit.id);

    const teamValues = {
      ...team,
      cost: team.cost - unit.totalCost,
      units: team.units - 1,
    };

    firebase.updateTeam(uid, team.id, teamValues);

    send({ type: ACTIONS.DELETE_TEAM_UNIT, data: unit });
    send({ type: ACTIONS.UPDATE_PARENT_TEAM, data: { ...teamValues } });
    send({ type: ACTIONS.UPDATE_TEAM, data: { ...teamValues } });
  };

  const addUnit = (factionUnit) => {
    const unitTypeCount = getUnitTypeCount(units, factionUnit.type);
    const name = unitTypeCount > 0 ? `${factionUnit.type}-${unitTypeCount + 1}` : factionUnit.type;
    const unit = {
      ...factionUnit,
      name,
      totalCost: factionUnit.cost,
    };
    const newUnit = firebase.addUserTeamUnit(uid, team.id, unit);
    setEditMode(false);
    setEditUnit(newUnit);

    const teamValues = {
      ...team,
      cost: team.cost + factionUnit.cost,
      units: team.units + 1,
    };

    firebase.updateTeam(uid, team.id, teamValues);

    send({ type: ACTIONS.ADD_TEAM_UNIT, data: { ...newUnit } });
    send({ type: ACTIONS.UPDATE_PARENT_TEAM, data: teamValues });
    send({ type: ACTIONS.UPDATE_TEAM, data: { ...teamValues } });
  };

  const updateUserTeam = (teamValues) => {
    firebase.updateTeam(uid, team.id, teamValues);
    send({ type: ACTIONS.UPDATE_PARENT_TEAM, data: teamValues });
    send({ type: ACTIONS.UPDATE_TEAM, data: teamValues });
  };

  const action = (actionType: string, unit: UnitType) => {
    switch (actionType) {
    case 'edit-unit':
      setEditUnit(unit);
      break;
    case 'copy':
      onCopyUnit(unit);
      break;
    case 'delete':
      onDeleteUnit(unit);
      break;
    default:
      console.warn('Not invoked action', actionType, unit); // eslint-disable-line
    }
  };

  if (current.matches('failure')) {
    return (<div>Something went terrible wrong :o.</div>);
  }

  return (
    <section className="content">
      <EditTeamHeader
        updateTeam={updateUserTeam}
        setEditMode={setEditMode}
        printerFriendlyTeam={printerFriendlyTeam}
        team={team}
        units={units}
        addUnitDisabled={addUnitDisabled}
        setPreviewMode={setPreviewMode}
      />
      {units && <ListMenu lists={lists} onListEdit={setTeamListEdit} onListAdd={setTeamListAdd} />}
      {teamListAdd && (
      <Modal onClick={setTeamListAdd} iconType={MODAL_ICON.ABORT}>
        <AddList teamId={team.id} send={send} setTeamListAdd={setTeamListAdd} />
      </Modal>
      )}
      {teamListEdit && (
      <Modal onClick={setTeamListEdit} iconType={MODAL_ICON.CLOSE}>
        <ListEdit
          list={lists[teamListEdit]}
          units={units}
          team={team}
          setTeamListEdit={setTeamListEdit}
          send={send}
        />
      </Modal>
      )}
      <div className="content-inner">
        <section className="edit-team">
          {previewMode && (
            <Modal onClick={setPreviewMode} wide>
              <DisplayTeam
                team={team}
                units={units}
              />
            </Modal>
          )}
          {editMode && (
            <Modal onClick={setEditMode}>
              <AddUnit
                units={units}
                factionUnits={factionUnits}
                onAddUnit={addUnit}
              />
            </Modal>
          )}
        </section>
        {current.matches(STATES.GETTING_FACTION_UNITS) && <Spinner label="Loading Faction Units..." />}
        {current.matches(STATES.GETTING_TEAM_UNITS) && <Spinner label="Loading Units..." />}
        {current.matches(STATES.GETTING_TEAM_LISTS) && <Spinner label="Loading Lists..." />}
        {units
          && (
            <ListUnits
              units={units}
              action={action}
            />
          )}
        {current.matches({ [STATES.SUCCESS]: STATES.UNIT_OPTIONS_MENU }) && (
          <Modal onClick={() => send(ACTIONS.CLOSE_UNIT_OPTIONS_MENU)} iconType="CLOSE">
            Edit!
          </Modal>
        )}
        {!units && current.matches('success') && <p style={{ padding: '1rem' }}>No units yet.</p>}
      </div>
      {editUnit && (
      <Modal onClick={setEditUnit} iconType="CLOSE">
        <EditUnit
          unit={units[editUnit.id]}
          setIsEditMode={setEditUnit}
        />
      </Modal>
               )}
    </section>
  );
};

const Context = ({ service }: { service: any }) => (
  <RosterContext.Provider value={useService(service)}>
    <Index service={service} />
  </RosterContext.Provider>
);

export default Context;
