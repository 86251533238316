import styled from 'styled-components';

export default styled.section`
padding: 0.5rem;
text-transform: capitalize;

& h4 {
  padding: 0.5rem;
  border-bottom: 1px solid var(--base130);
}

`;
