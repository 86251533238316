// @flow
import React from 'react';
import { compose } from 'recompose';
import { withAuthorization, AuthUserContext } from '../Session';
import AdminStart from './AdminStart';

const Admin = () => (
  <AuthUserContext.Consumer>
    {() => (<AdminStart />)}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
)(Admin);
