// @flow
import React from 'react';
import type { WargearType } from '../../types';

const Cell = ({ name, cell, head }: {
  name?: string | number,
  cell?: boolean,
  head?: boolean
}) => <td className={(cell && 'cell') || (head && 'name')} key={name}>{name}</td>;
Cell.defaultProps = {
  name: '',
  cell: false,
  head: false,
};

const WargearRow = ({ id, wg }: { id: string, wg: WargearType }) => (
  <tr key={id}>
    <Cell head name={wg.name} />
    <Cell cell name={wg.range} />
    <Cell name={wg.type} />
    <Cell cell name={wg.s} />
    <Cell cell name={wg.ap} />
    <Cell cell name={wg.d} />
    <Cell cell name={wg.cost} />
  </tr>
);


const WargearAlternateRow = ({ id, wg }: { id: string, wg: WargearType }) => (
  <>
    <tr key={id}>
      <td colSpan="6" className="name">
        {wg.name}
      </td>
      <td className="cell">{wg.cost}</td>
    </tr>
    {wg.profile && Object.keys(wg.profile).map((key) => {
      const profile = wg.profile[key];
      return (
        <tr>
          <td className="name">
            -
            {key}
          </td>
          <td className="cell">{profile[key].range}</td>
          <td>{profile[key].type}</td>
          <td className="cell">{profile[key].s}</td>
          <td className="cell">{profile[key].ap}</td>
          <td className="cell">{profile[key].d}</td>
        </tr>
      );
    })}
  </>
);

const WargearList = ({ wargear }: { wargear: Object }) => {
  const wargearTable = Object.keys(wargear).map((wg, i) => (wargear[wg].profile
    ? <WargearAlternateRow id={`${wg}-${i}`} wg={wargear[wg]} />
    : <WargearRow id={`${wg}-${i}`} wg={wargear[wg]} />));

  return (
    <table>
      <thead>
        <tr>
          <th>Weapon</th>
          <th>Range</th>
          <th>Type</th>
          <th>S</th>
          <th>AP</th>
          <th>D</th>
          <th>Cost</th>
          {/* <th>Abilities</th> */}
        </tr>
      </thead>
      <tbody>
        {wargearTable}
      </tbody>
    </table>
  );
};

export default WargearList;
