import React from 'react';
import styled from 'styled-components';

const headingSizes = {
  h1: 3.2,
  h2: 2,
  h3: 1.8,
  h4: 1.5,
  h5: 1,
  h6: 0.8,
  span: 1.2,
};

const HStyles = styled.h1`
  /* Default h1 */
  font-size: ${headingSizes.h1}rem;
  /* Default allow to change */
  font-size: ${({ as }) => as && `${headingSizes[as]}rem`};
  /* Visually override if need different font size vs the semantic element */
  ${({ as }) => as || 'font-family: \'Odibee Sans\', \'Oxanium\''};
  ${({ as }) => as === 'span' && 'font-family: \'Oxanium\''};
  ${({ red }) => red && 'color: var(--red)'};
  ${({ bottomBorder }) => bottomBorder && 'border-bottom: 1px solid var(--red)'};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase'};
  font-weight: bold;
  ${({ pb }) => pb && 'padding-bottom: 5px'};
  ${({ mt }) => mt && 'margin-top: 5px'};
  ${({ mb }) => mb && 'margin-bottom: 3px'};

  .red {
    color: var(--red);
  }

  @media (max-width: 550px) {
    ${({ mobileHidden }) => mobileHidden && 'display: none'};
  }
`;

export default function H(props) {
  return (
    <HStyles {...props}>
      {props.children}
    </HStyles>
  );
}
