import React from 'react';
import { withAuthorization, AuthUserContext } from '../Session';
import TeamManager from './TeamManager';

const Index = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <TeamManager authUser={authUser} />
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Index);
