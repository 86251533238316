// @flow
import React, { useEffect, useState } from 'react';
import { withFirebase } from '../Firebase';
import { ROLES } from '../../constants';
import { AuthUserContext } from '../Session';
import { NavigationNonAuth, NavigationAuth } from './Navigation';
import ScrollToTop from './ScrollToTop';
import StickyNav from './StickyNav';

const NavigationWithAuth = ({ firebase, authUser }: {
  firebase: Object, authUser: Object
}) => {
  const [user, setUser] = useState(null);
  const isAdmin = !!user && user.role === ROLES.ADMIN;

  useEffect(() => {
    firebase.getUser(authUser.uid)
      .then((snapShot) => {
        setUser(snapShot.val());
      });
    return () => {
    };
  }, [authUser]);

  return <StickyNav><NavigationAuth isAdmin={isAdmin} /></StickyNav>;
};

const Navigation = ({ firebase }: Object) => (
  <AuthUserContext.Consumer>
    {(authUser) => (authUser
      ? <NavigationWithAuth firebase={firebase} authUser={authUser} /> : <StickyNav><NavigationNonAuth /></StickyNav>)}
  </AuthUserContext.Consumer>
);

export default withFirebase(Navigation);
export { ScrollToTop };
