// @flow
import React, { useState } from 'react';
import Form from './AddFactionUnitForm';
import List from './List';
import SelectFactions from '../../TeamManager/AddTeam/SelectFactions';
import { H, Grid } from '../../../styles';
import type { FactionListType, WargearListType } from '../../../types';
import { totalUnitCost } from '../../../utils';

const Index = ({
  factions,
  specialists,
  abilities,
  wargear,
  current,
  send,
  firebase,
}: {
  factions: FactionListType,
  specialists: Array<string>,
  abilities: Array<string>,
  wargear: WargearListType,
  current: any,
  send: Function,
  firebase: any
}) => {
  const { factionUnits, faction: selectedFaction } = current.context;
  const [selectedFactionUnit, setSelectedFactionUnit] = useState(null);

  const onSelectFaction = (faction) => {
    send('SELECT_FACTION', { data: faction });
  };

  const onSelectFactionUnit = (id) => {
    // Add id if none existing
    setSelectedFactionUnit({ ...factionUnits[id], id });
  };

  const onSubmit = (unit, formType) => {
    // calculate total unit cost
    const totalCost = totalUnitCost(unit);
    const unitWithTotal = { ...unit, totalCost };

    if (formType === 'Add') {
      firebase.addByNamespace(`faction-units/${selectedFaction.id}`, unitWithTotal, (data) => {
        send('ADD_FACTION_UNIT', { data });
      });
    } else if (formType === 'Edit') {
      firebase.updateByNamespace(`faction-units/${selectedFaction.id}/${unitWithTotal.id}`, unitWithTotal)
        .then((data) => {
          send('UPDATE_FACTION_UNIT', { data });
          return data;
        });
    }
  };

  let listItems = <List items={factionUnits} onClick={onSelectFactionUnit} />;

  if (current.matches('gettingFactionUnits')) listItems = <p>Loading Faction Units...</p>;

  return (
    <div>
      <H as="h3">Faction Units</H>
      <Grid columns="1fr 200px">
        <div>
          <SelectFactions
            factions={factions}
            onSelect={onSelectFaction}
            selected={selectedFaction && selectedFaction.id}
          />
          <Form
            onSubmit={onSubmit}
            selectedFactionUnit={selectedFactionUnit}
            specialists={specialists}
            abilities={abilities}
            wargear={wargear}
            disabled={!!selectedFaction}
          />
        </div>
        {listItems}
      </Grid>
    </div>
  );
};

export default Index;
