const LEADER = 'Leader';
const COMMS = 'Comms';
const DEMOLITION = 'Demolition';
const SCOUT = 'Scout';
const SNIPER = 'Sniper';
const HEAVY = 'Heavy';
const VETERAN = 'Veteran';
const MEDIC = 'Medic';
const ZEALOT = 'Zealot';
const COMBAT = 'Combat';

export default {
  LEADER,
  COMMS,
  DEMOLITION,
  SCOUT,
  SNIPER,
  HEAVY,
  VETERAN,
  MEDIC,
  ZEALOT,
  COMBAT,
};
