import styled from 'styled-components';

export default styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) => props.columns};
  grid-template-rows: ${(props) => props.rows};
  justify-content: center;
  align-content: flex-start;
  justify-items: start;
  > * {
    align-self: flex-start;
  }
`;
