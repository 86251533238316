import styled from 'styled-components';

export default styled.table`
width: 100%;
font-size: 1.2em;
border-collapse:collapse;

thead tr th {
  border-bottom: 1px solid var(--red);
}

& td {
  text-align: center;
}
`;
