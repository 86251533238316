// @flow
import React from 'react';
import { FiEdit as Edit } from 'react-icons/fi';
import UnitNameStyles from './UnitNameFieldStyles';

const UnitNameField = ({ label, name, updateUnit }: {
  label: string, name: string, updateUnit: Function
}) => (
  <UnitNameStyles htmlFor="true">
    {label}
    <input
      onFocus={(event) => event.target.select()}
      type="text"
      defaultValue={name}
      onBlur={(e) => updateUnit(e, 'name')}
      onKeyDown={(e) => { if (e.keyCode === 13) e.preventDefault(); }}
      placeholder="Unit Name..."
    />
    <span className="edit-icon">
      <Edit color="#24C6A2" size="20" />
    </span>
  </UnitNameStyles>
);

export default UnitNameField;
