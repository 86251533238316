import styled from 'styled-components';

export default styled.div`
& .icon {
  fill: var(--base080);
  width: 30px;
  height: 30px;
}

& .menu-button {
  padding: 0;
}

& .menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 2.5rem;
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  z-index: 1;
  height: 100%;
  background: var(--white);
  color: var(--base130);
  transition: all 0.5s ease-in-out;

  /* temp styling */
  & ul {
    list-style-type: none;

    & li {
      padding: 0.3rem 0;
      border: none;
      & a {
        padding: 0;
      }
    }
  } 

  & a > h6 {
    font-size: 1.5em;
    color: var(--base130);
  }

  /* // temp styling */

  &.is-open {
    left: 0;
    animation: moveIn 0.5s ease-in-out;
  }
}

.menu-background {
  z-index: 1;
  position: fixed;
  display: block;
  visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &.menu-background--is-open {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes moveIn {
  from {
    transform: translateX(-300px);
  }
  to {
    transform: translateX(0rem);
  }
}

@keyframes moveOut {
  from {
    transform: translateX(0rem);
  }
  to {
    transform: translateX(-300px);
  }
}

@media (min-width: 550px) {
  ${({ desktopHidden }) => desktopHidden && 'display: none'};
}
`;
