// @flow
import React from 'react';
import AddListForm from './AddList';
import { useFirebase } from '../../../Firebase';
import { useAuth } from '../../../Session';
import { ACTIONS } from '../../../../constants';

const AddList = ({ send, teamId, setTeamListAdd }: {
  send: Function,
  teamId: string,
  setTeamListAdd: Function
}) => {
  const firebase = useFirebase();
  const { uid } = useAuth();
  const onClick = (list) => {
    firebase.addTeamList(uid, teamId, list, (teamList) => {
      send({ type: ACTIONS.ADD_TEAM_LIST, data: teamList });
      setTeamListAdd(false);
    });
  };

  return (<AddListForm onFormSubmit={onClick} />);
};

export default AddList;
