// @flow
import React from 'react';
import type { TeamListType } from '../../../../types';

export default ({ list }: { list: TeamListType}) => (
  <section className="edit-team" style={{ display: 'block' }}>
    {list.descr && <p>{list.descr}</p>}
    <p>
      <strong>Max Points:</strong>
      {' '}
      {list.maxPoints === -1 ? 'unlimited' : list.maxPoints}
    </p>
    <p>
      <strong>Leader Limit:</strong>
      {' '}
      {list.leaderLimit === -1 ? 'unlimited' : list.leaderLimit}
    </p>
    <p>
      <strong>Specialist Limit:</strong>
      {' '}
      {list.specialistLimit === -1 ? 'unlimited' : list.specialistLimit}
    </p>
  </section>
);
