import styled from 'styled-components';

export default styled.div`
position: relative;
font-size: 1em;
padding: 0.5rem 0.5rem 0; 
border-bottom: 1px solid var(--red);

& header {
  display: flex;
  align-items: center;

  & button {
    display: flex;
    align-items: center;
  }
}

.wrapper {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.wrapper--inner {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.item {
  font-size: 0.8em;
  padding: 0.6rem 0.3rem 0.5rem; 
  height: 100%;
}

.btn {
  position: absolute;
  z-index: 5;
  background: blue;
  top: 0;
  bottom: 0;

  .icon {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }

  &.btn-hidden {
    display: none;
  }
}

.btn-left {
  left: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

  &:hover .icon {
  opacity: 1;
  }
}

.btn-right {
  right: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
  background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

  &:hover .icon {
    opacity: 1;
  }
}

& .icon {
  width: 30px;
  height: 30px;
}

@media screen and (min-width: 550px) {
  display: flex;
  & .wrapper {
    flex: 1;
  }
}
`;
