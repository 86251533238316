// @flow
import React from 'react';
import type { StatsType } from '../../../types';
import StatsStyles from './UnitStatsStyles';

const UnitStats = ({ stats }: { stats: StatsType }) => (
  <StatsStyles>
    <thead>
      <tr>
        <th>M</th>
        <th>WS</th>
        <th>BS</th>
        <th>S</th>
        <th>T</th>
        <th>W</th>
        <th>A</th>
        <th>Ld</th>
        <th>Sv</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{`${stats.m}"`}</td>
        <td>{`${stats.ws}+`}</td>
        <td>{`${stats.bs}+`}</td>
        <td>{stats.s}</td>
        <td>{stats.t}</td>
        <td>{stats.w}</td>
        <td>{stats.a}</td>
        <td>{stats.ld}</td>
        <td>{stats.sv}</td>
      </tr>
    </tbody>
  </StatsStyles>
);

export default UnitStats;
