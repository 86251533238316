// @flow
import React from 'react';
import {
  FiEdit as Edit, FiMoreVertical as Options,
} from 'react-icons/fi';
import { Icon } from './utils/getFactionIcon';
import { ListLi } from '../../styles';

import type { TeamType } from '../../types';

const Team = ({
  team,
  onOptionsClick,
  selectTeam,
}: {
  team: TeamType,
  onOptionsClick: Function,
  selectTeam: Function,
}) => (
  <ListLi>
    <div className="list-name" title="Edit Team">
      <button
        type="button"
        onClick={() => selectTeam({ ...team })}
        className="edit-list-btn"
      >
        <Icon name={team.faction.name} className="faction-icon" />
        <Edit className="icon" />
        <div className="list-info">
          <h5>{team.name}</h5>
          <p className="strong">
            <span className="highlight">{team.faction.name}</span>
          </p>
          <p>
            {team.units === 1 ? ` ${team.units} member ` : ` ${team.units} members `}
            {`• ${team.cost} points`}
          </p>
        </div>
      </button>
    </div>
    <div className="list-edit" title="Options Menu">
      <button type="button" className="list-edit-btn" onClick={() => onOptionsClick(team.id)}>
        <Options size="25" />
      </button>
    </div>
  </ListLi>
);

export default Team;
