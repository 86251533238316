// @flow
import React from 'react';

type Props = {|
  color: string,
  width: string,
  className: string,
  height: string,
|}

export default ({
  color = '#000',
  width = '100%',
  className = '',
  height = '100%',
}: Props) => (
  <svg
    version="1.1"
    id="Layer_3"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    fill={color}
    width={width}
    height={height}
    className={className}
  >
    <g>
      <path d="M855.21,753.22L782.29,450.5c-0.54-2.26-3.7-2.43-4.48-0.24l-75.49,211.37c-0.64,1.8-3.06,2.11-4.14,0.53L626.9,557.32
		c-1.22-1.79-4.01-1.11-4.26,1.04l-21.18,180.05c-0.27,2.26-3.27,2.85-4.37,0.86L495.94,556.34c-0.94-1.71-3.44-1.58-4.2,0.22
		l-67.01,157.79c-0.73,1.71-3.06,1.93-4.09,0.39l-66.03-97.98c-0.98-1.45-3.14-1.36-3.99,0.17l-29.47,53.04
		c-1.02,1.84-3.77,1.5-4.31-0.54l-70.58-267.79c-1.39-5.28-8.92-5.19-10.18,0.12L154.8,744.05c-0.23,0.96,0.17,1.95,0.99,2.5
		l370.63,244.07c0.81,0.53,1.87,0.51,2.66-0.06l325.23-234.89C855.08,755.11,855.43,754.14,855.21,753.22z"
      />
      <path d="M327.43,577.18l34.15-34.15l56.91,109.27l61.47-157.08l95.61,138.87l25.04-122.93l59.19,27.32l15.94-195.78l167.3-4.35
		c4-0.1,6.78-4.01,5.58-7.83l-54.5-172.22l-104.72,2.28l-34.15-86.51L352.47,5.78l-31.87,97.89l-154.8,36.42l-18.21,122.93
		l173.01,116.1L327.43,577.18z M569.97,274.1l89.83-61.16c22.88,15.77,36.54,44.6,40.98,77.4l-127.21-4.55
		C567.27,285.79,564.76,277.65,569.97,274.1z M557.36,399.61l-75.12,9.11l22.76-77.4L557.36,399.61z M416.22,176.52
		c42.75,0,77.4,34.65,77.4,77.4c0,42.75-34.65,77.4-77.4,77.4s-77.4-34.65-77.4-77.4C338.82,211.17,373.47,176.52,416.22,176.52z"
      />
    </g>
  </svg>
);
