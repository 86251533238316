// @flow
import * as React from 'react';
import StyledNavigation from './StickyStyles';

const Navigation = ({ children }: { children: React.Node}) => {
  const [isSticky, setSticky] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if (ref !== null && ref.current) {
        setSticky(window.scrollY > ref.current.getBoundingClientRect().bottom);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <StyledNavigation ref={ref} sticky={isSticky}>
      {children}
    </StyledNavigation>
  );
};

export default Navigation;
