import styled from 'styled-components';

export default styled.article`
margin-bottom: 5px;

span {
  padding: 2px 2px 1px;
  font-size: 0.7rem;
  color: var(--red);
}

p {
  padding: 0.3rem 0;
  font-size: 0.7rem;
}
`;
